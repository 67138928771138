import {Modal, ModalBody} from "reactstrap";
import React, { useState } from "react";
import {connect} from 'react-redux';
import {getCurrentBinFills, getDumpedLoads} from "../selector/wetside";
import bindContainerActions from "../../bindContainerActions";
import GrowerColumnWrapper from "./GrowerColumnWrapper";
import {DateTime} from "luxon";
import {InlineSpinner} from "./Spinner";
import {AirDoorButton} from "../../bin/component/air-door";
import {getBinLotsByBin, getBinsById} from "../selector/bin";
import {getFieldsById, getGrowersById, getVarietiesById} from "../selector/common";
import Slider from 'rc-slider';
import {Colors} from "./color";
import classNames from 'classnames';
import {get} from 'lodash';
import {binIRI} from "../model/iri";

function EmptyBinInfoSection() {
  return <GrowerColumnWrapper>
    <div className="flex-grow-1">
      <div><span className="text-muted">Grower</span></div>
      <div className="d-flex justify-content-between">
        <div><span className="text-muted">Field</span></div>
        <div><span className="text-muted">Variety</span></div>
      </div>
    </div>
  </GrowerColumnWrapper>
}

function FilledBinInfoSection({grower, field, variety}) {
  return <GrowerColumnWrapper color={grower.color}>
    <div className="flex-grow-1">
      <div><b>{grower.name}</b></div>
      <div className="d-flex justify-content-between">
        <div><span>{field.name}</span></div>
        <div><span>{variety.name}</span></div>
      </div>
    </div>
  </GrowerColumnWrapper>
}

function ListViewBinFill({activeBinFillId, currentBinFills, binFill, removeBinFill, editBinFillPercent, setEditBinFillId, updateSliderValue}) {
  let currentPercent = 0;
  currentBinFills && currentBinFills.map(currentBinFill => currentPercent += currentBinFill.percentFull);
  let maxPercent = 100 - currentPercent + binFill.percentFull;
  return <div>
    <GrowerColumnWrapper color={ get(binFill, "incomingLoadDump.incomingLoad.grower.color")}>
      <div className="flex-grow-1">
        <div className="d-flex justify-content-between">
          <div>
            <div><b>{get(binFill, "incomingLoadDump.incomingLoad.grower.name")}</b></div>
            <div><b>WC: # {get(binFill, "incomingLoadDump.incomingLoad.weightCert.num")}</b></div>
            <div>Percent Filled: <b>{get(binFill, "percentFull")}%</b></div>
            <div>Created At: <b>{DateTime.fromISO(get(binFill, "createdAt")).toFormat("yyyy-MM-dd HH:mm")}</b></div>
          </div>
          <div className="pl-2 flex-grow-1 text-right">
            <BinLotFullChart percentFull={get(binFill, "percentFull")} height={20}/>
            <button
              type="button"
              onClick={() => setEditBinFillId(binFill.id)}
              className="btn btn-link btn-link-primary pr-0"
              style={{padding: 0}}
            >
              Edit
            </button>
            <button
              type="button"
              onClick={removeBinFill.bind(null, get(binFill, "id"))}
              className="btn btn-link btn-link-danger pr-0"
              style={{
                margin: '0 0 0 1rem',
                padding: 0
              }}
            >
              Remove
            </button>
          </div>
        </div>
        {activeBinFillId === binFill.id && <div>
          <div className="my-4">
            <WrappedSlider updateSliderValue={updateSliderValue} maxPercent={maxPercent} editPercent={binFill.percentFull}/>
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div className="text-right">
              <button type="button" className="btn btn-success" onClick={() => editBinFillPercent(binFill.id)}>Edit</button>
            </div>
            <div className="text-right" style={{ marginLeft: '1rem' }}>
              <button type="button" className="btn btn-danger" onClick={() => setEditBinFillId(null)}>Close</button>
            </div>
          </div>
        </div>}
      </div>
    </GrowerColumnWrapper>
  </div>
}

function ListViewBinFillList({binFills, removeBinFill, setEditBinFillId, activeBinFillId, updateSliderValue, currentBinFills, editBinFillPercent}) {
  return <div>
    <b>Bin Fills</b>
    <div>
      {binFills.map(bf => <ListViewBinFill key={bf.id} binFill={bf} removeBinFill={removeBinFill} setEditBinFillId={setEditBinFillId} activeBinFillId={activeBinFillId} updateSliderValue={updateSliderValue} currentBinFills={currentBinFills} editBinFillPercent={editBinFillPercent}/>)}
    </div>
  </div>
}

function BinLotFullChart({percentFull, height = 30, className = ''}) {
  return <div style={{height: height, overflow: "hidden"}} className={"border border-dark rounded " + className}>
    <div className="bg-success" style={{width: percentFull + "%", height: "100%"}}></div>
  </div>
}

function BinPercentFullSection({binLot}) {
  return <div className="mb-2">
    <b>Bin Percent Full ({binLot.percentFull + "%"})</b>
    <BinLotFullChart percentFull={binLot.percentFull}/>
  </div>
}

function ActionsSection({bin, binLot, isAddingBinFill, openAirDoor, closeAirDoor, startAddingBinFill, cancelAddingBinFill}) {
  return <div className="d-flex justify-content-end mb-2">
    <AirDoorButton.FromBin bin={bin} openAirDoor={openAirDoor} closeAirDoor={closeAirDoor} className="mr-2"/>
    <button type="button" onClick={isAddingBinFill ? cancelAddingBinFill : startAddingBinFill} className={classNames("btn", isAddingBinFill ? "btn-danger" : "btn-success")} disabled={binLot && binLot.full}>{isAddingBinFill ? "Cancel" : "Add Bin Fill"}</button>
  </div>
}

function ViewBinFillsBottomSection({isEmpty, isFetchingBinFills, binFills, removeBinFill, setEditBinFillId, activeBinFillId, updateSliderValue, currentBinFills, editBinFillPercent}) {
  if (isEmpty) {
    return <div></div>
  }

  return <div>
    <InlineSpinner enabled={isFetchingBinFills}/>
    <ListViewBinFillList binFills={binFills} removeBinFill={removeBinFill} setEditBinFillId={setEditBinFillId} activeBinFillId={activeBinFillId} updateSliderValue={updateSliderValue} currentBinFills={currentBinFills} editBinFillPercent={editBinFillPercent}/>
  </div>
}

function DumpedLoadListItem({dumpedLoad, selectDumpedLoad, isSelected}) {
  return <div className={classNames(isSelected && "border border-dark rounded px-2")} onClick={selectDumpedLoad.bind(null, dumpedLoad.id)}>
    <GrowerColumnWrapper color={dumpedLoad.incomingLoad.grower.color}>
      <div className="flex-grow-1">
        <div className="d-flex justify-content-between">
          <div><b>{dumpedLoad.incomingLoad.grower.name}</b></div>
          <div><b>#{dumpedLoad.incomingLoad.weightCert.num}</b></div>
        </div>
        <div className="d-flex justify-content-between">
          <div>{dumpedLoad.incomingLoad.field.name}</div>
          <div>#{dumpedLoad.incomingLoad.variety.name}</div>
        </div>
        <div className="d-flex justify-content-between">
          <div>{DateTime.fromISO(dumpedLoad.createdAt).toFormat('yyyy-MM-dd HH:mm')}</div>
        </div>
      </div>
    </GrowerColumnWrapper>
  </div>
}

function DumpedLoadList({title, dumpedLoads, selectedLoadId, selectDumpedLoad, className}) {
  return <div className={className}>
    <b>{title}</b>
    <div style={{overflow: "auto", maxHeight: 265}}>
      {dumpedLoads.map(dl => <DumpedLoadListItem key={dl.id} dumpedLoad={dl} selectDumpedLoad={selectDumpedLoad} isSelected={dl.id === selectedLoadId}/>)}
    </div>
  </div>
}

class WrappedSlider extends React.Component {
  constructor(props) {
    super(props);
    this.state = { position: props.editPercent ? props.editPercent : props.maxPercent };
    this.onSliderChange = this.onSliderChange.bind(this);
  }
  onSliderChange(value) {
    this.setState({position: value});
    this.props.updateSliderValue && this.props.updateSliderValue(value);
  }
  render() {
    const {maxPercent, editPercent} = this.props;
    return <div className="text-center">
      <b>{this.state.position}%</b>
      <Slider defaultValue={editPercent ? editPercent : maxPercent} min={0} max={maxPercent} onChange={this.onSliderChange} step={10} trackStyle={{backgroundColor: Colors.primary, height: 12}} railStyle={{height: 12}} handleStyle={{width: 24, height: 24, marginTop: -6, marginLeft: -11, borderColor: Colors.primary}}/>
    </div>
  }
}

function AddBinFillBottomSection({currentBinFills, dumpedLoads, processedLoads, isFetchingProcessedLoads, selectedLoadId, selectDumpedLoad, updateSliderValue, addBinFill}) {
  let currentPercent = 0;
  currentBinFills && currentBinFills.map(binFill => currentPercent += binFill.percentFull);
  let maxPercent = 100 - currentPercent;

  const [isAddingBinFill, setIsAddingBinFill] = useState(false);
  function saveClickHandler() {
    setIsAddingBinFill(true);
    return addBinFill().then(() => {
      setIsAddingBinFill(false);
    });
  }

  return <div>
    <div>Select a dumped load from either list:</div>
    <div className="row">
      <div className="col"><DumpedLoadList title="New" dumpedLoads={dumpedLoads} selectDumpedLoad={selectDumpedLoad} selectedLoadId={selectedLoadId} className="pr-1"/></div>
      <div className="col">
        <InlineSpinner enabled={isFetchingProcessedLoads}>
          <DumpedLoadList title="Processed" dumpedLoads={processedLoads} selectDumpedLoad={selectDumpedLoad} selectedLoadId={selectedLoadId} className="pl-1"/>
        </InlineSpinner>
      </div>
    </div>
    <div className="my-4">
      <WrappedSlider updateSliderValue={updateSliderValue} maxPercent={maxPercent}/>
    </div>
    <div className="text-right">
      <button type="button" className="btn btn-success" onClick={saveClickHandler} disabled={isAddingBinFill}>Save</button>
    </div>
  </div>
}

const AddBinFillBottomSectionContainer = connect((state) => {
  const isNewFill = state.wetsideDryerBinModal.currentBinFills.length === 0;
  const newState = {
    dumpedLoads: state.wetsideDryerBinModal.dumpedLoads,
    processedLoads: state.wetsideDryerBinModal.processedLoads,
    isFetchingProcessedLoads: state.wetsideDryerBinModal.isFetchingProcessedLoads,
    selectedLoadId: state.wetsideDryerBinModal.selectedLoadId,
  };

  return isNewFill ? newState : {...newState, currentBinFills: state.wetsideDryerBinModal.currentBinFills};
}, bindContainerActions('wetsideDryerBinModalActions'))(AddBinFillBottomSection);

function DryerBinModalBody({ activeBinFillId, bin, binLot, isEmpty, grower, field, variety, isFetchingBinFills, binFills, isAddingBinFill, openAirDoor, closeAirDoor, startAddingBinFill, cancelAddingBinFill, removeBinFill, setEditBinFillId, updateSliderValue, currentBinFills, editBinFillPercent}) {
  const BinInfoSection = binLot ? FilledBinInfoSection : EmptyBinInfoSection;
  const BottomSection = isAddingBinFill ? AddBinFillBottomSectionContainer : ViewBinFillsBottomSection;
  return <div>
    <BinInfoSection grower={grower} field={field} variety={variety}/>
    {!isEmpty && <BinPercentFullSection binLot={binLot}/>}
    <ActionsSection bin={bin} binLot={binLot} isAddingBinFill={isAddingBinFill} openAirDoor={openAirDoor} closeAirDoor={closeAirDoor} startAddingBinFill={startAddingBinFill} cancelAddingBinFill={cancelAddingBinFill} />
    <BottomSection isEmpty={isEmpty} binFills={binFills} isFetchingBinFills={isFetchingBinFills} removeBinFill={removeBinFill} setEditBinFillId={setEditBinFillId} activeBinFillId={activeBinFillId} updateSliderValue={updateSliderValue} currentBinFills={currentBinFills} editBinFillPercent={editBinFillPercent}/>
  </div>
}

function DryerBinModal(props) {
  const closeModal = props.bin ? props.lockBin.bind(null, props.bin.id) : null;
  return <Modal isOpen={props.isOpen} size="lg" toggle={closeModal} className="full-width">
    <div className="modal-header d-flex justify-content-between px-4">
      <h4>Bin {props.bin && props.bin.id}</h4>
      <button type="button" data-dismiss="modal" aria-hidden="true" className="close"><span className="s7-close" onClick={closeModal}/></button>
    </div>
    <ModalBody className="pt-1 px-4">
      {props.isOpen && <DryerBinModalBody {...props}/>}
    </ModalBody>
  </Modal>
}

export default connect((state) => {
  const selectedBinId = state.wetside.lockedBinId;
  if (!selectedBinId) {
    return {
      isOpen: false
    }
  }

  const bin = getBinsById(state)[binIRI(selectedBinId)];
  const binLot = getBinLotsByBin(state)[binIRI(selectedBinId)];
  const isAddingBinFill = state.wetsideDryerBinModal.isAddingBinFill;
  const isFetchingBinFills = state.wetsideDryerBinModal.isFetchingBinFills;
  const binFills = getCurrentBinFills(state);
  const activeBinFillId = state.wetsideDryerBinModal.binFillEditId;
  const currentBinFills = state.wetsideDryerBinModal.currentBinFills;

  if (!binLot) {
    return {bin, isOpen: true, isEmpty: true, binFills, isFetchingBinFills, isAddingBinFill};
  }

  return {
    isOpen: true,
    isEmpty: false,
    bin,
    binLot,
    grower: getGrowersById(state)[binLot.grower],
    field: getFieldsById(state)[binLot.field],
    variety: getVarietiesById(state)[binLot.variety],
    binFills,
    isFetchingBinFills,
    isAddingBinFill,
    activeBinFillId,
    currentBinFills
  };
}, bindContainerActions('commonComponentActions@DryerBin'))(DryerBinModal);
