import * as loginSelectors from "../login/selector/login";

export function listenOnceLoggedIn(container, emitter, handler) {
  emitter.addListener('login.init', (isLoggedIn, dispatch, getState) => {
    if (isLoggedIn) {
      handler(dispatch, getState);
    }
  });
  emitter.addListener('login.success', (dispatch, getState) => {
    handler(dispatch, getState);
  })
}
