import ApiClient from "./model/ApiClient";
import Pusher from 'pusher-js';
import {commonActions, commonSubscribers} from "./redux";

export default function registerServices(bottle, initialState) {
  bottle.factory('apiClient', (c) => {
    const apiClient = ApiClient.createFromBaseUri(process.env.REACT_APP_API_BASE_URI);
    const apiToken = localStorage.getItem('ghmfApiToken');
    if (apiToken) {
      apiClient.setApiToken(apiToken);
    }
    return apiClient;
  });
  bottle.factory('pusher', (c) => {
    return new Pusher('key', {
      wsHost: process.env.REACT_APP_PUSHER_HOST,
      wsPort: parseInt(process.env.REACT_APP_PUSHER_PORT),
      enabledTransports: ["ws", "flash"],
      disabledTransports: ["flash"]
    })
  });
  bottle.decorator("emitter", commonSubscribers.bind(null, bottle.container));
  bottle.serviceFactory('commonActions', commonActions, 'apiClient', 'emitter');
  bottle.factory('commonActions@setGrowerForStats', (c) => {
    return {setGrowerForStats: c.commonActions.setGrowerForStats};
  });
  bottle.factory('commonComponentActions@DryerBin', (c) => {
    return {
      openAirDoor: c.wetsideActions.openAirDoor,
      closeAirDoor: c.wetsideActions.closeAirDoor,
      lockBin: c.wetsideActions.lockBin,
      selectBin: c.wetsideActions.selectBin,
      removeSelectedBin: c.wetsideActions.removeSelectedBin,
      fetchBinFills: c.wetsideActions.fetchBinFills,
      clearBinFills: c.wetsideActions.clearBinFills,
      startAddingBinFill: c.wetsideDryerBinModalActions.startAddingBinFill,
      cancelAddingBinFill: c.wetsideDryerBinModalActions.cancelAddingBinFill,
      removeBinFill: c.wetsideDryerBinModalActions.removeBinFill,
      setEditBinFillId: c.wetsideDryerBinModalActions.setEditBinFillId,
      updateSliderValue: c.wetsideDryerBinModalActions.updateSliderValue,
      editBinFillPercent: c.wetsideDryerBinModalActions.editBinFillPercent,
      filteredBinListClickHandler: c.fillordersActions.filteredBinListClickHandler
    };
  });
  return bottle;
}
