import React, {useEffect} from 'react';
import compose from 'lodash/fp/compose';
import {connect} from 'react-redux';
import bindContainerActions from "../../bindContainerActions";
import {Modal, Button, ModalBody, ModalHeader, ModalFooter} from 'reactstrap';
import {withRouter} from 'react-router-dom'
import GrowerColumnWrapper from "../../common/component/GrowerColumnWrapper";
import {GrowerHeader, GrowerSubHeader, GrowerItems, mapStateToFillOrderSectionProps} from "./ShowFillOrderSection";
import {mapLbsReq} from "./FillOrderCollapse";
import {get} from 'lodash';
import {FillOrderPage} from "../redux";
import { InlineSpinner } from '../../common/component/Spinner';

const LinkButton = withRouter(({history, to, onClick, children, ...props}) => {
  return <Button color="dark" onClick={() => history.push(to)} {...props}>{children}</Button>
});

class GrowerInfoModal extends React.Component {
  constructor(props) {
    super(props);
		this.toggle = this.toggle.bind(this);
		this.state={modal: true};
  }

  toggle() {
    this.setState(state => ({modal: !state.modal}))
  }

  render() {
    return (
			<div>
      	<Modal isOpen={this.state.modal}>
					<ModalHeader className="modal-header d-flex justify-content-between px-4">Fill Order {this.props.currentFillOrderInfo.id}</ModalHeader>
        	<GrowerModalBody {...this.props}/>
					<ModalFooter>
						<LinkButton to={"/fill-orders"}>Close</LinkButton>
					</ModalFooter>
      	</Modal>
			</div>
    )
  }
}

// TODO: this should be apart of the ShowFillOrderSection code, it's the same exact concern of displaying the fill order.
// we are duplicating a lot of code by keeping it in this file.
function GrowerModalBody({history, currentFillOrder, currentFillOrderInfo, moveAllFillOrderItems, loadCurrentFillOrder, initFillOrderPage}){
  const fillOrder = currentFillOrderInfo;

  // reload the fill order on render
  useEffect(() => {
    loadCurrentFillOrder(fillOrder.id);
    initFillOrderPage(FillOrderPage.Show);
  }, [fillOrder.id]);

  let lbs = mapLbsReq(get(currentFillOrder, "items"));
  return (
    <ModalBody>
      <InlineSpinner enabled={!currentFillOrder}>{() => (
        <React.Fragment>
          <GrowerColumnWrapper color={fillOrder.color}>
            <GrowerHeader grower={fillOrder.grower} id={fillOrder.id} variety={fillOrder.variety}/>
          </GrowerColumnWrapper>
          <GrowerSubHeader fillOrderInfo={fillOrder} lbsreq={lbs.poundsRequested} lbsrem={lbs.poundsRemaining}/>
          <GrowerItems items={fillOrder.items} currentFillOrder={currentFillOrder} moveAllFillOrderItems={moveAllFillOrderItems} history={history} />
        </React.Fragment>
      )}</InlineSpinner>
		</ModalBody>
  )
}

export default compose(
  withRouter,
  connect(mapStateToFillOrderSectionProps, bindContainerActions('fillordersActions'))
)(GrowerInfoModal);
