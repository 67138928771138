import withSizes from "react-sizes";
import compose from 'lodash/fp/compose';
import React from 'react';
import {MobileContext} from "../context";

export const withMobileSize = compose(
    withSizes(({width}) => ({isMobile: width < 480, isNavMobile: width < 768 })),
    function(Decorated) {
        return (props) => {
          return <MobileContext.Consumer>{({forceMobile}) => {
            return <Decorated {...props} isMobile={forceMobile ? forceMobile : props.isMobile} />
          }}</MobileContext.Consumer>
        };
    }
);
