import React from 'react';
import DetailedLoadsModal from './DetailedLoadsModal';
import { Panel, PanelBody, PanelHeader } from "../../common/component/panel";
import StorageBins from '../../common/component/StorageBins';
import {connect} from "react-redux";
import DryerBankSection from '../../common/component/DryerBanks';
import {MoistureChartSection} from "./MoistureChart";
import bindContainerActions from "../../bindContainerActions";
import SelectedBinSection from "./SelectedBinSection";
import LoadsInYardStats from "./LoadsInYardStats";
import LoadsInYardSection from "./LoadsInYardSection";
import PitsSection from "./PitsSection";
import compose from 'lodash/fp/compose';
import {withMobileSize} from "../../common/component/size";
import BinEventsSection from "./BinEvents";
import {AbsoluteFlexStretchedWrapper} from "../../common/component/util";
import Layout from "../../layout/component/Layout";

export function Wetside({showDetailedLoads, openDetailedLoadsModal, closeDetailedLoadsModal, isMobile}) {
  return <Layout tightHeader>
    <div className={"WetsideContainer"}>
      {!isMobile && <div className="row panel-row">
        <div className="col-12 d-none d-md-block col-md-1 position-relative mb-2">
          <LoadsInYardStats tightLayout />
        </div>
        <div className="col-12 col-md mb-2">
          <StorageBins tightLayout />
        </div>
        <div className="col-12 col-md-3 mb-2">
          <SelectedBinSection tightLayout />
        </div>
      </div>}
      <div className="row panel-row">
        {!isMobile && <div className="col col-md-1">
          <AbsoluteFlexStretchedWrapper>
            <LoadsInYardSection openDetailedLoads={openDetailedLoadsModal}/>
          </AbsoluteFlexStretchedWrapper>
        </div>}
        <div className="col-12 col-md">
          <DryerBankSection tightLayout />
        </div>
        <div className="col col-md-3" style={{maxWidth: '100%'}} >
          <MoistureChartSection />
          <BinEventsSection />
          <PitsSection/>
        </div>
      </div>
      <DetailedLoadsModal closeModal={closeDetailedLoadsModal} isOpen={showDetailedLoads}/>
    </div>
  </Layout>
}

export default compose(
  connect((state) => {
    return {showDetailedLoads: state.wetside.showDetailedLoads};
  }, bindContainerActions("wetsideActions")),
  withMobileSize
)(Wetside);
