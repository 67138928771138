import React from 'react';
import classNames from 'classnames';

export function PanelActionsHeader({title, subtitle, children}) {
  return <div className="panel-heading d-flex justify-content-between mx-3">
    <div className="justify-content-front">
      <span className="title" style={{fontSize: "1.785rem"}}>{title}</span>
      {subtitle && <span className="subtitle mx-5">{subtitle}</span>}
    </div>
    <div className="actions">
      {children}
    </div>
  </div>
}

export function PanelHeader({title, subtitle, divider = false, children}) {
  return <div className={"panel-heading mx-3 " + (divider ? "panel-heading-divider" : "")}>
    <span className="title">{title}</span>
    {subtitle && <span className="panel-subtitle">{subtitle}</span>}
    <div className="tools">
      {children}
    </div>
  </div>
}

export function PanelBody({children, className, withPadding = true, ...props}) {
  return <div className={classNames("panel-body", withPadding ? ["px-3", "py-2"] : [], className)} {...props}>
    {children}
  </div>
}

export function TablePanelBody({children, ...props}) {
  return <PanelBody children={children} withPadding={false} {...props}/>
}

export function FlexPanelBody({children, className, ...props}) {
  return <PanelBody children={children} className={classNames("d-flex flex-column", className)} {...props}/>
}

export function Panel({children, className = "panel panel-default", ...props}) {
  return <div className={className} {...props}>
    {children}
  </div>
}

export function TablePanel({children, className = "panel paenl-default panel-table", ...props}) {
  return <div className={className} {...props}>{children}</div>
}

export function FullPanel({children, className, ...props}) {
  return <Panel children={children} className={classNames("panel panel-default full-panel", className)} {...props}/>
}

export function FlexFullPanel({children, ...props}) {
  return <FullPanel children={children} {...props} className="d-flex flex-column"/>
}
