import React from 'react';
import classNames from 'classnames';

// Forces a flex column to be absolute and outside of the calculated heights for stretch
export function AbsoluteFlexStretchedWrapper({enabled = true, className, children}) {
  if (!enabled) {
    return children;
  }

  return <div className="position-relative" style={{height: "100%"}}>
    <div className={classNames("position-absolute", className)} style={{height: "100%", width: "100%"}}>{children}</div>
  </div>
}
