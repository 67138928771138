import React from 'react';
import {Panel, PanelBody, PanelHeader} from "../../common/component/panel";
import {Link, withRouter} from 'react-router-dom';

const LinkButton = withRouter(({history, to, onClick, children, ...props}) => {
  return <button type="button" onClick={() => history.push(to)} {...props}>{children}</button>
});

export default function FillOrderPanelSection({title, children, backUrl, ...props}) {
  let currentWindow = window.location.pathname;
  return <Panel style={{overflowY: "scroll"}} className="panel panel-default flex-grow-1" {...props}>
    <PanelHeader title={title} divider>
      { backUrl && ( currentWindow.includes("edit") ?
        <LinkButton to={backUrl} className="btn btn-info p-1">Back</LinkButton> :
        <LinkButton to={backUrl} className="btn btn-info p-1">New</LinkButton>)
      }
    </PanelHeader>
    <PanelBody>
      {children}
    </PanelBody>
  </Panel>
}
