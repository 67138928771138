export const numberChangeHandler = e => {
  if (!/^\d+$/.test(e.target.value)) {
    e.target.value = e.target.value.replace(/\D/g, '');
  }
}

export const numberLengthHandler = e => {
  if(e.target.value.length > 5){
    e.target.value = e.target.value.substring(0, 5);
  }
}
