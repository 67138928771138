import {combineReducers} from 'redux';
import common from './common/redux';
import incomingLoads from './incoming-loads/redux';
import fillorders from './fillorders/redux';
import outgoingLoads from './outgoing-loads/redux';
import wetside from './wetside/redux/wetside';
import wetsideDryerBinModal from './wetside/redux/dryer-bin-modal';
import createDryTruckPanel from "./outgoing-loads/redux/createDryTruckPanel";
import login from './login/redux';
import homePage from "./home/redux/homePage";
import unfilledDryTruckListPanel from './dry-trucks/redux/unfilledDryTruckListPanel';
import recentlyFilledDryTruckListPanel from './dry-trucks/redux/recentlyFilledDryTruckListPanel';
import dryTruckViewPage from "./dry-trucks/redux/dryTruckViewPage";

export default combineReducers({
  incomingLoads,
  outgoingLoads,
  fillorders,
  common,
  wetside,
  wetsideDryerBinModal,
  createDryTruckPanel,
  login,
  homePage,
  unfilledDryTruckListPanel,
  recentlyFilledDryTruckListPanel,
  dryTruckViewPage
});
