import React, { useEffect } from 'react';
import {withRouter} from 'react-router-dom'
import compose from 'lodash/fp/compose';
import FillOrderPanelSection from "./FillOrderPanelSection";
import {connect} from 'react-redux';
import {getIsFillOrderSaving, getNewFillOrder} from "../selector/fillorders";
import {getBinLotsById, getBinsByBank, getBinsById} from "../../common/selector/bin";
import {
  getFieldsById,
  getGrowersById,
  getReceivers,
  getStorageBins,
  getVarietiesById
} from "../../common/selector/common";
import GrowerColumnWrapper from "../../common/component/GrowerColumnWrapper";
import Select from 'react-select';
import {get, isEmpty} from 'lodash'
import map from 'lodash/fp/map';
import {getBinMoisture, percentToPounds, poundsToPercent} from "../../bin/model/bin";
import bindContainerActions from "../../bindContainerActions";
import Weight from "../../common/component/Weight";
import {binLotIRI, receiverIRI, storageBinIRI} from "../../common/model/iri";
import {FillOrderPage} from "../redux";

function visit(history, path) {
  return () => {
    history.push(path);
  }
}

function EmptyGrowerInfo() {
  return <div className="d-flex justify-content-between">
    <div className="text-muted">Grower</div>
    <div className="text-muted">Field</div>
    <div className="text-muted">Variety</div>
  </div>
}

function GrowerInfo({grower, field, variety}) {
  return <div className="d-flex justify-content-between">
    <div><b>{grower.name}</b></div>
    <div>{field.name}</div>
    <div>{variety.name}</div>
  </div>
}

function mapStorageBinToOption(storageBin) {
  return {value: storageBin.id, label: storageBin.name};
}

function mapReceiverToOption(receiver) {
  return {value: receiver.id, label: receiver.name};
}

function storageBinSelectionValue(storageBins, currentStorageBin){
  return storageBins.filter(sb => {
    return storageBinIRI(sb.id) === currentStorageBin
  }).map(sb => {
    return {value: sb.id, label: sb.name}
  }).pop() || null;
}

function StorageBinSelection({selectedStorageBin, storageBins, currentStorageBin}) {
  let defaultValue = storageBinSelectionValue(storageBins, currentStorageBin)
  return <div>
    <div><b>Storage Bin</b></div>
    <Select classNamePrefix="react-select" value={defaultValue} options={storageBins.map(mapStorageBinToOption)} placeholder="N/A" onChange={selectedStorageBin}/>
  </div>
}

function findDesignatedReceiver(receivers, currentReceiver) {
  return receivers.filter(r => {
    return receiverIRI(r.id) === currentReceiver
  }).map(r => ({value: r.id, label: r.name})).pop() || null;
}

function ReceiverSelection({selectedReceiver, receivers, currentReceiver}) {
  const changeSelectedReceiver = (value) => selectedReceiver(value, currentReceiver);
  let defaultValue = findDesignatedReceiver(receivers, currentReceiver);
  return <div>
    <div><b>Receiver</b></div>
    <Select classNamePrefix="react-select" value={defaultValue} options={receivers.map(mapReceiverToOption)} placeholder={"N/A"} onChange={changeSelectedReceiver}/>
  </div>
}

function FillOrderListItem({bin, idx, binLot, field, percentRequested, removeBinLotFromNewFillOrder, stepNewFillOrderItemPercentRequested, setNewFillOrderField}) {
  const percentRequestedOnChange = (e) => {
    setNewFillOrderField(['items', idx, 'percentRequested'], poundsToPercent(parseInt(e.currentTarget.value)));
  };
  const percentRequestedOnKeyUp = (e) => {
    if (e.keyCode === 38) {
      stepNewFillOrderItemPercentRequested('up', idx);
    } else if (e.keyCode === 40) {
      stepNewFillOrderItemPercentRequested('down', idx);
    }
  };
  return <div className="row mb-1 align-items-center">
    <div className="col flex-grow-0"><div style={{width: 26}}>#{bin.id}</div></div>
    <div className="col">{field.name}</div>
    <Weight pounds={binLot.poundsFull} units line/>
    <div className="col">
      <input type="text" tabIndex={1} className="form-control p-1 text-center" value={percentToPounds(percentRequested)} onChange={percentRequestedOnChange} onKeyUp={percentRequestedOnKeyUp} style={{height: 'auto'}}/>
    </div>
    <div className="col">{getBinMoisture(bin)}</div>
    <div>
      <button type="button" className="btn btn-link btn-link-danger py-1 px-2" onClick={removeBinLotFromNewFillOrder.bind(null, binLot.id)}>Remove</button>
    </div>
  </div>
}

const FillOrderListItemContainer = connect((state, {binLotId}) => {
  const binLot = getBinLotsById(state)[binLotIRI(binLotId)];
  const bin = getBinsById(state)[binLot.bin];
  const field = getFieldsById(state)[binLot.field];
  return {bin, binLot, field};
}, bindContainerActions('fillordersActions'))(FillOrderListItem);

function FillOrderItemsSection({fillOrderItems}) {
  if (isEmpty(fillOrderItems)) {
    return <p className="mt-2">Select a bin from the Filtered Bin List to add new fill order items.</p>
  }

  return <div>
    {fillOrderItems.map((i, idx) => <FillOrderListItemContainer key={i.binLotId} binLotId={i.binLotId} percentRequested={i.percentRequested} idx={idx}/>)}
  </div>
}

function CreateFillOrderSection({history, storageBins, receivers, newFillOrder, grower, field, variety, createNewFillOrder, setStorageBin, setReceiver, setGrowerForStats, initFillOrderPage, isFillOrderSaving}) {
  const sendFillOrder = () =>{
    createNewFillOrder(newFillOrder, history);
  }
  function onMouseEnter() {
    if (grower) { setGrowerForStats(grower.id) }
  }
  function onMouseLeave() {
    if (grower) { setGrowerForStats(null) }
  }


  useEffect(() => {
    initFillOrderPage(FillOrderPage.Create);
  }, []);

  return <FillOrderPanelSection title="Create New Fill Order" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
    <GrowerColumnWrapper color={grower && grower.color}>
      <div className="flex-grow-1">
        {grower ? <GrowerInfo grower={grower} field={field} variety={variety}/> : <EmptyGrowerInfo/>}
      </div>
    </GrowerColumnWrapper>
    <div className="row mb-2">
      <div className="col">
        <b>Lbs Requested</b>
        <Weight pounds={newFillOrder.poundsRequested} units line/>
      </div>
      <div className="col">
        <b>Lbs Remaining</b>
        <Weight pounds={newFillOrder.poundsRemaining} units line/>
      </div>
    </div>
    <div className="row mb-2">
      <div className="col col-md-6">
        <StorageBinSelection selectedStorageBin={setStorageBin} storageBins={storageBins} currentStorageBin={newFillOrder.storageBin} />
      </div>
      <div className="col col-md-6">
        <ReceiverSelection selectedReceiver={setReceiver} receivers={receivers} currentReceiver={newFillOrder.receiver}/>
      </div>
    </div>
    <FillOrderItemsSection fillOrderItems={newFillOrder.items}/>
    {grower && <div className="text-right">
      <button type="button" className="btn btn-success" onClick={sendFillOrder} disabled={isFillOrderSaving}>Create Fill Order</button>
    </div>}
  </FillOrderPanelSection>
}
export default compose(
  withRouter,
  connect((state) => {
    const newFillOrder = getNewFillOrder(state);
    const storageBins = getStorageBins(state);
    const receivers = getReceivers(state);
    const firstBinLotId = get(newFillOrder, 'items[0].binLotId');
    if (!firstBinLotId) {
      return {newFillOrder, storageBins, receivers};
    }
    const firstBinLot = getBinLotsById(state)[binLotIRI(firstBinLotId)];
    const isFillOrderSaving = getIsFillOrderSaving(state);
    return {
      newFillOrder,
      storageBins,
      receivers,
      grower: getGrowersById(state)[firstBinLot.grower],
      field: getFieldsById(state)[firstBinLot.field],
      variety: getVarietiesById(state)[firstBinLot.variety],
      isFillOrderSaving,
    };
  }, bindContainerActions('fillordersActions@FillOrders'))
)(CreateFillOrderSection);
