import React from 'react';
import PropTypes from 'prop-types';
import {FlexFullPanel, FlexPanelBody, PanelBody, PanelHeader} from "../../common/component/panel";
import FillOrderCollapse from "./FillOrderCollapse";
import compose from 'lodash/fp/compose';
import {withMobileSize} from "../../common/component/size";

class Fillorder extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    const fillOrdersList = this.props.fillOrdersList || [];

    return <FlexFullPanel className="d-flex flex-column" >
      <PanelHeader title="Fillorders" divider={true}/>
      {!this.props.isMobile &&<FlexPanelBody style={{overflowY: 'auto'}}>
        {fillOrdersList.map((item, index) => {
          return <FillOrderCollapse key={index} grower={item} />
        })}
      </FlexPanelBody>}
      {this.props.isMobile && <div className="d-flex flex-column" style={{fontSize: '16px'}}>
        {fillOrdersList.map((item, index) => {
          return <FillOrderCollapse className="col" key={index} grower={item} />
        })}
      </div>}
    </FlexFullPanel>;
  }
}

Fillorder.propTypes = {
  growers: PropTypes.array.isRequired
};

Fillorder.defaultProps = {
  growers: []
};

export default compose(
  withMobileSize
)(Fillorder);
