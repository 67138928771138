import React from 'react';
import GrowerColorColumn from "./GrowerColorColumn";
import classNames from 'classnames';

export default function GrowerColumnWrapper({color, width = 4, children, className = "py-2", grow = false, ...props}) {
  return <div className={classNames("d-flex", className)} {...props}>
    <GrowerColorColumn color={color} width={width}/>
    {!grow && children}
    {grow && <div className="flex-grow-1">{children}</div>}
  </div>
}

