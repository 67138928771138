import {createSelector} from "reselect";
import keyBy from 'lodash/fp/keyBy';
import {fieldIRI, growerIRI, receiverIRI, storageBinIRI, storageLotIRI, varietyIRI} from "../model/iri";

export const getGrowersById = createSelector(
  state => state.common.growers || [],
  growers => growers.reduce((acc, grower) => {
    acc[growerIRI(grower.id)] = grower;
    return acc;
  }, {})
);

export const getGrowerStatsById = createSelector(
  state => state.common.growerStats || [],
  growers => growers.reduce((acc, stat) => {
    acc[growerIRI(stat.growerId)] = stat;
    return acc;
  }, {})
);

export const getFieldsById = createSelector(
  state => state.common.fields || [],
  fields => fields.reduce((acc, field) => {
    acc[fieldIRI(field.id)] = field;
    return acc;
  }, {})
);

export const getVarietiesById = createSelector(
  state => state.common.varieties || [],
  varieties => varieties.reduce((acc, variety) => {
    acc[varietyIRI(variety.id)] = variety;
    return acc;
  }, {})
);

export const getStorageBinsById = createSelector(
  state => state.common.storageBins || [],
  keyBy((sb) => storageBinIRI(sb.id))
);

export const getReceiversById = createSelector(
  state => state.common.receivers || [],
  keyBy(r => receiverIRI(r.id))
);

export const getStorageLotsById = createSelector(
  state => state.common.storageLots || [],
  storageLots => storageLots.reduce((acc, storageLot) => {
    acc[storageLotIRI(storageLot.id)] = storageLot;
    return acc;
  }, {})
);

export const getCommonDataIsLoaded = createSelector(
  state => state.common.varieties,
  state => state.common.fields,
  state => state.common.growers,
  (varieties, fields, growers) => varieties !== null && fields !== null && growers !== null
);

export const getPits = state => state.common.pits || [];
export const getTrucks = state => state.common.trucks || [];
export const getDrivers = state => state.common.drivers || [];
export const getTrailerSets = state => state.common.trailerSets || [];
export const getStorageBins = state => state.common.storageBins || [];
export const getReceivers = state => state.common.receivers || [];

export function canReloadByName(name) {
  return (state) => {
    return !state.common.deferredReload[name];
  }
}

export const canReloadIncomingLoads = canReloadByName('incoming-loads');
