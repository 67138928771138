import {initSubscribers, loginActions} from "./redux";

export default function registerServices(bottle, initialState) {
  bottle.decorator("emitter", initSubscribers.bind(null, bottle.container));
  bottle.serviceFactory('loginActions', loginActions, 'apiClient', 'emitter');
  bottle.serviceFactory('login@appActions', ({logout}, {toggleMobileView}) => {
    return {logout, toggleMobileView};
  }, 'loginActions', 'commonActions');
  return bottle;
}
