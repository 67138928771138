import React from 'react';
import {connect} from 'react-redux';
import { FullPanel, PanelBody, PanelHeader } from "./panel";
import bindContainerActions from "../../bindContainerActions";
import {getFieldsById, getGrowersById, getVarietiesById} from "../selector/common";
import {getBanksById, getBinLotsByBin, getBins, getBinsByBank, getSelectedBinId} from "../selector/bin";
import compose from 'lodash/fp/compose';
import {withMobileSize} from "./size";
import GrowerColumnWrapper from "./GrowerColumnWrapper";
import {InlineSpinner} from "./Spinner";
import {getCurrentBinFills} from "../selector/wetside";
import {DateTime} from 'luxon';
import DryerBinModal from "./DryerBinModal";
import classNames from 'classnames';
import {getBinMoisture} from "../../bin/model/bin";
import {get} from "lodash";
import {withRouter} from 'react-router-dom'
import {bankIRI, binIRI} from "../model/iri";

function DryDryberBankConveyor(props) {
    return <DryerBankConveyor bgStyle="bg-warning" {...props}/>
}

function WetDryberBankConveyor(props) {
    return <DryerBankConveyor bgStyle="bg-primary" {...props}/>
}

function createAirDoorClickHandler(bin, closeAirDoor, openAirDoor) {
  const airDoorClickHandler = bin.airDoorOpen ? closeAirDoor : openAirDoor;
  return (e) => {
      e.stopPropagation();
      airDoorClickHandler(bin.id);
  };
}

function DryerBinBody({bin, onAirDoorClick, eventHandlers = {}, growerColor = null, binLot = null, isLocked = false, isFirst = false}) {
  return <div className={"DryerBin d-flex flex-column border mb-2 text-center " + (!isFirst ? "border-left-0 " : "") + (isLocked ? "locked " : "")} style={{width: "5%", cursor: "pointer"}} {...eventHandlers}>
    <div>
      <b>{bin.id}</b>
      <hr className="my-0"/>
      <span>{getBinMoisture(bin)}</span>
      <hr className="my-0"/>
      {binLot && <span>{binLot.full ? <React.Fragment>&nbsp;</React.Fragment> : binLot.percentFull + "%"}</span>}
      {!binLot && <span>&nbsp;</span>}
    </div>
    <div style={{backgroundColor: growerColor, height: "14px"}}/>
    <div
      onClick={onAirDoorClick}
      className={classNames(
        "flex-grow-1",
        bin.airDoorOpen && bin.binMoisture <= 8 ? "bg-danger" : null,
        bin.airDoorOpen && bin.binMoisture > 8 ? "bg-success" : null,
        !bin.airDoorOpen ? "bg-secondary" : null,
      )}
      style={{marginTop: "2px", cursor: "pointer", minHeight: "4px"}}
    />
  </div>
}

function DryerBin({match, bin, binLot, grower, openAirDoor, closeAirDoor, lockBin, selectBin, removeSelectedBin, isLocked, isFirst = false, filteredBinListClickHandler, dryerBinActions = {}}) {
  if (!binLot) {
    return <DryerBinBody bin={bin} isFirst={isFirst} onAirDoorClick={() => {}}/>
  }
  const onAirDoorClick = createAirDoorClickHandler(bin, closeAirDoor, openAirDoor);
  if(window.location.href.includes("fill-orders")) {
    isLocked = false;
  }

  function onClick(e) {
    lockBin(bin.id, e);
    dryerBinActions.onClick && dryerBinActions.onClick({bin, binLot, grower}, e);
  }
  function onDoubleClick(e) {
    filteredBinListClickHandler(binLot.id, e)
    dryerBinActions.onDoubleClick && dryerBinActions.onDoubleClick({bin, binLot, grower}, e);
  }
  function onMouseEnter(e) {
    selectBin(bin.id, e);
    dryerBinActions.onMouseEnter && dryerBinActions.onMouseEnter({bin, binLot, grower}, e);
  }
  function onMouseLeave(e) {
    removeSelectedBin(e);
    dryerBinActions.onMouseLeave && dryerBinActions.onMouseLeave({bin, binLot, grower}, e);
  }

  return <DryerBinBody
    bin={bin}
    binLot={binLot}
    onAirDoorClick={match.path === "/wetside" ? onAirDoorClick : () => {}}
    isFirst={isFirst}
    isLocked={isLocked}
    growerColor={grower.color}
    eventHandlers={{onClick, onDoubleClick, onMouseEnter, onMouseLeave}}
  />
}

function hydrateDryerBin(state, {bin}) {
  const binLot = getBinLotsByBin(state)[binIRI(bin.id)];
  return {
    binLot: binLot,
    isLocked: getSelectedBinId(state) === bin.id,
    grower: binLot ? getGrowersById(state)[binLot.grower] : null
  }
}

const DryerBinContainer = compose(
  connect(hydrateDryerBin, bindContainerActions('commonComponentActions@DryerBin')),
  withRouter
)(DryerBin);


function DryerBank({bank, bins, dryerBinActions}) {
  const bankTemp = Math.round(100 * (Number(bank.coldEndTemp))) / 100;
  const bankPressure = Math.round(100 * (Number(bank.plenumPressure))) / 100;
  return <div className="d-flex position-relative" style={{fontSize: ".8rem"}}>
      {bins.map((bin, i) => <DryerBinContainer key={bin.id} bin={bin} isFirst={i === 0} dryerBinActions={dryerBinActions} />)}
      <div className="position-absolute text-right container" style={{top: "1px", left: "-35px", width: "30px"}}>
        <span className="row m-0 justify-content-end "><b>#{bank.bankNum}</b></span>
        <span className="row m-0 justify-content-end" style={{color: 'blue'}}>{bankTemp}°F</span>
        <span className="row m-0 justify-content-end" style={{color: 'green'}}>{bankPressure}"</span>
      </div>
  </div>
}

const DryerBankContainer = connect((state, {id}) => {
  return {bank: getBanksById(state)[id], bins: getBinsByBank(state)[id]};
})(DryerBank);

function DryerBankConveyor({bgStyle, numBanks = 20}) {
    const divStyle = {
        height: "8px",
    };
    if (numBanks < 20) {
        divStyle.width = (numBanks / 20 * 100) + "%";
    }
    return <div style={divStyle} className={bgStyle + " my-2"}/>
}

function OverheadDryerBanksBody({dryerBanksPage = null, dryerBinActions}) {
  const className = classNames("pl-5", dryerBanksPage !== null ? "col-12" : "col-12 col-lg-6");
  const pages = [
    [
      <DryerBankContainer id={bankIRI(6)} dryerBinActions={dryerBinActions} />,
      <DryerBankContainer id={bankIRI(5)} dryerBinActions={dryerBinActions} />,
      <DryerBankContainer id={bankIRI(4)} dryerBinActions={dryerBinActions} />,
      <DryerBankContainer id={bankIRI(3)} dryerBinActions={dryerBinActions} />,
      <DryerBankContainer id={bankIRI(2)} dryerBinActions={dryerBinActions} />,
      <DryerBankContainer id={bankIRI(1)} dryerBinActions={dryerBinActions} />,
    ],
    [
      <DryerBankContainer id={bankIRI(11)} dryerBinActions={dryerBinActions} />,
      <DryerBankContainer id={bankIRI(10)} dryerBinActions={dryerBinActions} />,
      <DryerBankContainer id={bankIRI(9)} dryerBinActions={dryerBinActions} />,
      <DryerBankContainer id={bankIRI(8)} dryerBinActions={dryerBinActions} />,
      <DryerBankContainer id={bankIRI(7)} dryerBinActions={dryerBinActions} />,
    ],
    [
      <DryerBankContainer id={bankIRI(15)} dryerBinActions={dryerBinActions} />,
      <DryerBankContainer id={bankIRI(14)} dryerBinActions={dryerBinActions} />,
      <DryerBankContainer id={bankIRI(13)} dryerBinActions={dryerBinActions} />,
      <DryerBankContainer id={bankIRI(12)} dryerBinActions={dryerBinActions} />,
      <DryerBankContainer id={bankIRI(17)} dryerBinActions={dryerBinActions} />,
      <DryerBankContainer id={bankIRI(16)} dryerBinActions={dryerBinActions} />,
    ]
  ];

  return <div>
    <div className="row">
      {dryerBanksPage !== null && <div className={className} style={{paddingTop: "0px"}}>
        {pages[dryerBanksPage]}
      </div>}
      {dryerBanksPage === null && <React.Fragment>
        <div className={className} style={{paddingTop: "0px"}}>
          {pages[1]}
          {pages[0]}
        </div>
        <div className={className}>
          {pages[2]}
        </div>
      </React.Fragment>}
    </div>
  </div>
}

function ListViewBinFill({binFill}) {
  return <div>
    <GrowerColumnWrapper color={binFill.incomingLoadDump.incomingLoad.grower.color}>
      <div>
        <div className="d-flex justify-content-between">
          <div>
            <div><b>{binFill.incomingLoadDump.incomingLoad.grower.name}</b></div>
            <div>WC: #{binFill.incomingLoadDump.incomingLoad.weightCert.num}</div>
          </div>
          <div>
            <div>Percent Full: <b>{binFill.percentFull + "%"}</b></div>
            <div>Created At: <b>{DateTime.fromISO(binFill.createdAt).toFormat("yyyy-MM-dd HH:mm")}</b></div>
          </div>
        </div>
        <div>
          <button type="button" className="btn btn-link-danger">Remove</button>
        </div>
      </div>
    </GrowerColumnWrapper>
  </div>
}

function ListViewBinFillList({binFills}) {
  return <div>
    <b>Bin Fills</b>
    <div style={{overflow: "auto", maxHeight: "120px"}}>
      {binFills.map(bf => <ListViewBinFill key={bf.id} binFill={bf}/>)}
    </div>
  </div>
}

function BinLotFullChart({percentFull, height = 30, className = ''}) {
  return <div style={{height: height, overflow: "hidden"}} className={"border border-dark rounded " + className}>
    <div className="bg-success" style={{width: percentFull + "%", height: "100%"}}></div>
  </div>
}

function ListViewDryerBin({match, bin, binLot, grower, field, variety, isLocked, lockBin, openAirDoor, closeAirDoor}) {
  const isEmpty = !binLot;
  const onAirDoorClick = createAirDoorClickHandler(bin, closeAirDoor, openAirDoor);

  return <li>
    <div className="row align-items-center" style={{cursor: "pointer"}} onClick={lockBin.bind(null, bin.id, true)}>
      <div className="col flex-grow-0">
        <div style={{width: "26px"}}><b>{bin.id}</b></div>
      </div>
      <div className="col">
        <GrowerColumnWrapper color={grower && get(grower, "color")}>
          <div className="flex-grow-1">
            <div>{isEmpty ? <span className="text-muted">Grower</span> : <b>{get(grower, "name")}</b>}</div>
            <div className="d-flex justify-content-between">
              <div>{isEmpty ? <span className="text-muted">Field</span> : <span>{get(field, "name")}</span>}</div>
              <div>{isEmpty ? <span className="text-muted">Variety</span> : <span>{get(variety, "name")}</span>}</div>
            </div>
          </div>
        </GrowerColumnWrapper>
      </div>
      <div className="col flex-grow-0">
        <div className="p-1" onClick={match.path === "/wetside" ? onAirDoorClick : () => {}}>
          <div className={"rounded " + ((bin.airDoorOpen && bin.binMoisture <= 8) ? "bg-danger" : (bin.airDoorOpen && bin.binMoisture >= 8)  ? "bg-success" : "bg-secondary")} style={{height: "30px", width: "30px"}}/>
        </div>
      </div>
      <div className="col flex-grow-0">
        <div style={{width: "28px"}} className="text-right">{getBinMoisture(bin)}</div>
      </div>
    </div>
  </li>
}

const ListViewDryerBinContainer = compose(
  withRouter,
  connect((state, {bin}) => {
    const newProps = hydrateDryerBin(state, {bin});
    return Object.assign(newProps, {
      field: newProps.binLot ? getFieldsById(state)[newProps.binLot.field] : null,
      variety: newProps.binLot ? getVarietiesById(state)[newProps.binLot.variety] : null,
    });
  }, bindContainerActions('commonComponentActions@DryerBin'))
)(ListViewDryerBin);

function ListViewDryerBanksBody({bins}) {
  return <div>
    <ul className="pl-0 list-unstyled">
      {bins.map(b => <ListViewDryerBinContainer key={b.id} bin={b} />)}
    </ul>
    <DryerBinModal/>
  </div>
}

const ListViewDryerBanksBodyContainer = connect((state) => {
  return {
    bins: getBins(state)
  }
})(ListViewDryerBanksBody);

function DryerBanks({hasWetsideData, isMobile, dryerBanksPage, setDryerBanksPage, dryerBinActions, tightLayout = false}) {
  const BanksBody = isMobile ? ListViewDryerBanksBodyContainer : OverheadDryerBanksBody;
  return <FullPanel>
    {(!tightLayout || setDryerBanksPage) && <PanelHeader title="Dryer Banks" divider={true}>
      {setDryerBanksPage && <React.Fragment>
        <button type="button" className="btn rounded-lg ml-2 btn-info py-1" onClick={() => setDryerBanksPage(0)}>1 - 6</button>
        <button type="button" className="btn rounded-lg ml-2 btn-primary py-1" onClick={() => setDryerBanksPage(1)}>7 - 11</button>
        <button type="button" className="btn rounded-lg ml-2 btn-danger py-1" onClick={() => setDryerBanksPage(2)}>N1 - N6</button>
      </React.Fragment>}}
    </PanelHeader>}
    <PanelBody>
      {hasWetsideData && <BanksBody dryerBanksPage={dryerBanksPage} dryerBinActions={dryerBinActions} />}
    </PanelBody>
  </FullPanel>;
}

function withSwitchingDryerBanks(DryerBanksBody) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = { dryerBanksPage: 0 };
      this.setDryerBanksPage = this.setDryerBanksPage.bind(this);
    }

    setDryerBanksPage(dryerBanksPage) {
      this.setState({dryerBanksPage});
    }

    render() {
      const {switchDryerBanks = false, ...props} = this.props;
      if (!switchDryerBanks) {
        return <DryerBanksBody {...props} />
      }
      return <DryerBanksBody {...props} dryerBanksPage={this.state.dryerBanksPage} setDryerBanksPage={this.setDryerBanksPage} />
    }
  }
}

export default compose(
  connect((state) => {
    return {hasWetsideData: state.wetside.banks && state.wetside.bins && state.wetside.binLots && state.common.growers};
  }),
  withSwitchingDryerBanks,
  withMobileSize
)(DryerBanks);
