import bottle from 'bottlejs';
import {EventEmitter} from 'fbemitter';
import commonRegisterServices from './common/registerServices';
import wetsideRegisterServices from './wetside/registerServices';
import fillordersRegisterServices from './fillorders/registerServices';
import incomingLoadsRegisterServices from './incoming-loads/registerServices';
import loginRegisterServices from './login/registerServices';
import outgoingLoadsRegisterServices from './outgoing-loads/registerServices';
import homePageRegisterServices from "./home/registerServices";
import dryTruckRegisterServices from './dry-trucks/registerServices';

export const hullerBottle = bottle.pop('huller');

/** Register services for the main container */
export function registerServices(bottle, initialState) {
  return [
    (bottle, initialState) => {
      bottle.service('emitter', EventEmitter);
      return bottle;
    },
    commonRegisterServices,
    wetsideRegisterServices,
    outgoingLoadsRegisterServices,
    incomingLoadsRegisterServices,
    fillordersRegisterServices,
    loginRegisterServices,
    homePageRegisterServices,
    dryTruckRegisterServices,
  ].reduce((bottle, register) => {
    return register(bottle, initialState);
  }, bottle);
}
