import React from "react";
import {FullPanel, PanelBody, PanelHeader} from "../../common/component/panel";
import { InlineSpinner } from "../../common/component/Spinner";

export function UnprocessedDryTruckLotsPanel({isFetchingDryTrucks = false}) {
  return <FullPanel>
    <PanelHeader title="Unprocessed Dry Trucks" divider>
    <InlineSpinner enabled={isFetchingDryTrucks} />
    </PanelHeader>
    <PanelBody>
      <h3>dry trucks go here</h3>
    </PanelBody>
  </FullPanel>
}

export default UnprocessedDryTruckLotsPanel;
