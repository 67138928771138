import {wetsideActions, wetsideSubscribers} from "./redux/wetside";
import {dryerBinModalActions} from "./redux/dryer-bin-modal";
import {wetsideCommonActions} from "./redux/common";

export default function registerServices(bottle, initialState) {
  bottle.decorator("emitter", wetsideSubscribers.bind(null, bottle.container));
  bottle.serviceFactory('wetsideCommonActions', wetsideCommonActions, 'apiClient');
  bottle.serviceFactory('wetsideActions', wetsideActions, 'apiClient', 'commonActions', 'wetsideCommonActions', 'wetsideDryerBinModalActions');
  bottle.serviceFactory('wetsideDryerBinModalActions', dryerBinModalActions, 'apiClient', 'wetsideCommonActions');
  return bottle;
}