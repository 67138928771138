import axios from 'axios';
import axiosRetry from 'axios-retry';
import mapValues from 'lodash/mapValues';
import {
  binFillIRI, binIRI,
  driverIRI,
  fieldIRI,
  fillOrderIRI, fillOrderItemIRI,
  growerIRI, incomingLoadIRI, noteIRI, pitIRI,
  receiverIRI, storageBinIRI,
  storageLotIRI,
  trailerSetIRI,
  truckIRI,
  varietyIRI
} from "./iri";

function createResultSet(results, currentPage, perPage) {
  if(!currentPage && !perPage){
    return {items: results['hydra:member'], totalItems: results['hydra:totalItems']};
  }
  return {items: results['hydra:member'], totalItems: results['hydra:totalItems'], perPage, currentPage};
}

const mapLoad = (req) => {
  return mapValues(req, (value, key) => {
    switch (key) {
      case 'grower':
        return growerIRI(value);
      case 'field':
        return fieldIRI(value);
      case 'variety':
        return varietyIRI(value);
      case 'driver':
        return driverIRI(value);
      case 'truck':
        return truckIRI(value);
      case 'receiver':
        return receiverIRI(value);
      case 'designatedReceiver':
        return value === '' ? null : receiverIRI(value);
      case 'trailers':
      case 'trailerSet':
        return trailerSetIRI(value);
      case 'weightCert':
      case 'freightBill':
      case 'gross':
      case 'tare':
        return parseInt(value);
      default:
        return value;
    }
  });
};

const mapOutgoingLoad = (req) => {
  return mapValues(req, (value, key) => {
    switch (key) {
      case 'driver':
        return value;
      case 'truck':
        return value;
      case 'receiver':
        return value === '' ? null : receiverIRI(value);
      case 'trailers':
      case 'trailerSet':
        return value;
      case 'weightCert':
      case 'manifest':
      case 'gross':
      case 'tare':
        return parseInt(value);
      case 'parts':
        return mapDryLoadParts(value);
      default:
        return value;
    }
  });
};

//OutgoingLoadParts
const mapDryLoadParts = (parts) => {
  let newParts = [];
  parts.forEach((part, index) => {
    newParts[index] = {
      "storageLot": storageLotIRI(part.storageLot.id),
      "netPercent": part.netPercent
    }
  });
  return newParts;
}

//Fillorders dryLoad
const mapDryLoad = (req) => {
  return mapValues(req, (value, key) => {
    switch (key) {
      case 'items':
        return mapDryLoadItems(req.items);
      default:
        return value;
    }
  });
}

const mapDryLoadItems = (items) => {
  let newItems = [];
  items.forEach((item, index) => {
    newItems[index] = {
      "binId": item.binId,
      "percentRequested": item.percentRequested
    }
  });
  return newItems;
}

//FO move-all
const mapMoveAllItems = (req) => {
  return {
    "fillOrder": fillOrderIRI(req.id),
    "storageBin": storageBinIRI(req.storageBin.id),
  };
}

//FO move-item
const mapMoveItem = (req, itemId) => {
  return {
    "fillOrder": fillOrderIRI(req.id),
    "itemId": itemId
  };
}

export default class ApiClient {
  constructor(axios, createCancelToken) {
    this.client = axios;
    this.createCancelToken = createCancelToken;
  }

  static createFromBaseUri(baseUri) {
    const client = axios.create({baseURL: baseUri});

    axiosRetry(client, { retries: 5, retryDelay: axiosRetry.exponentialDelay });

    client.defaults.headers.common['Accept'] = 'application/json';
    client.defaults.headers.common['Content-Type'] = 'application/json';
    client.interceptors.response.use(resp => resp.data, (err) => {
      if (err instanceof axios.Cancel) {
        return;
      }

      console.log(err);
      if (err.response && err.response.data && err.response.data.title && err.response.data.detail) {
        throw err.response.data.title + ': ' + err.response.data.detail;
      }

      throw err.message || "An unexpected error occurred";
    });

    return new ApiClient(client, () => {
      return axios.CancelToken.source();
    });
  }

  setApiToken(apiToken) {
    this.client.defaults.headers.common['Authorization'] = 'Bearer ' + apiToken;
  }

  login(email, password) {
    return this.client.post('/users/login', {email, password});
  }

  getGrowers() {
    let params = {'order[name]': 'asc'};
    return this.client.get("/growers?pagination=false", {params});
  }

  getGrowerStats() {
    return this.client.get('/growers/stats');
  }

  getFields() {
    let params = {'order[name]': 'asc'};
    return this.client.get("/fields?pagination=false", {params});
  }

  getVarieties() {
    let params = {'order[name]': 'asc'};
    return this.client.get("/varieties?pagination=false", {params});
  }

  getReceivers() {
    let params = {'order[name]': 'asc'};
    return this.client.get("/receivers?pagination=false", {params})
  }

  getWeightCerts(num = null) {
    return this.client.get('/weight-certs', {
      params: num === null ? {} : {num},
    });
  }

  getFreightBills(num = null) {
    return this.client.get('/freight-bills', {
      params: num === null ? {} : {num},
    });
  }

  getManifests(num = null) {
    return this.client.get('/manifests', {
      params: num === null ? {} : {num},
    });
  }

  getBins() {
    const cancelToken = this.createCancelToken();
    return [this.client.get("/bins?pagination=false", {cancelToken: cancelToken.token}), cancelToken];
  }

  getBinLots(params={}){
    const cancelToken = this.createCancelToken();
    return [this.client.get("/bin-lots?pagination=false", {
      params,
      headers: {"Accept": "application/json"}
    }), cancelToken ]
  }

  getCurrentBinLots(params={}) {
    const cancelToken = this.createCancelToken();
    return [this.client.get("/bin-lots/current", {
      params,
      headers: {"Accept": "application/json"}
    }), cancelToken];
  }

  getCurrentBinMoistures() {
    return this.client.get("/bin-moistures/current");
  }

  getCurrentAirDoorEvents() {
    return this.client.get('/air-door-events/current');
  }

  getCurrentBinFillsByBin(binId) {
    return this.client.get(`/bins/${binId}/current-bin-fills`);
  }

  getBanks() {
    return this.client.get('/banks?pagination=false');
  }

  getPits() {
    return this.client.get('/pits?pagination=false');
  }

  getStorageBins() {
    return this.client.get('/storage-bins');
  }

  getStorageLots(){
    return this.client.get('/storage-lots/current');
  }

  getTrucks() {
    let params = {'order[name]': 'asc'};
    return this.client.get('/trucks?pagination=false', {params});
  }

  getDrivers() {
    let params = {'order[name]': 'asc'};
    return this.client.get('/drivers?pagination=false', {params});
  }

  getTrailerSets() {
    let params = {'order[name]': 'asc'};
    return this.client.get('/trailer-sets?pagination=false', {params});
  }

  getNextWeightCert() {
    return this.client.get('/weight-certs/next');
  }

  updateIncomingLoad(wetLoad, selectedWetLoadId) {
    let mapUpdates = {
      "id": selectedWetLoadId,
      "grower": growerIRI(wetLoad.grower),
      "field": fieldIRI(wetLoad.field),
      "variety": varietyIRI(wetLoad.variety),
      "gross": parseInt(wetLoad.gross),
      "tare": parseInt(wetLoad.tare),
      "truck": truckIRI(wetLoad.truck),
      "driver": driverIRI(wetLoad.driver),
      "trailerSet": trailerSetIRI(wetLoad.trailerSet),
      "splitTrailerType": wetLoad.splitTrailerType,
      "designatedReceiver": wetLoad.designatedReceiver ? receiverIRI(wetLoad.designatedReceiver) : null
    }
    return this.client.put('/incoming-loads/' + selectedWetLoadId, mapUpdates);
  }

  updateFillOrder(fillOrder, selectedFillOrderId){
    let mapItems = (fillOrder) => {
      let newItems = [];
      fillOrder.items.forEach((item, index) => {
        newItems[index] = {
          "fillOrderItemId": parseInt(item.id),
          "percentRequested": parseInt(item.percentRequested),
          binId: item.binId,
        }
      })
      return newItems;
    };
    let mapUpdates = {
      "id": selectedFillOrderId,
      "storageBin": fillOrder.storageBin,
      "receiver": fillOrder.receiver,
      "items": mapItems(fillOrder)
    }
    return this.client.put('/fill-orders/' + selectedFillOrderId, mapUpdates);
  }

  updateOutgoingLoad(dryLoad, selectedDryLoadId) {
    let mapUpdates = {
      "id": selectedDryLoadId,
      "weightCert": parseInt(dryLoad.weightCert),
      "manifest": parseInt(dryLoad.manifest),
      "gross": parseInt(dryLoad.gross),
      "tare": parseInt(dryLoad.tare),
      "truck": dryLoad.truck,
      "driver": dryLoad.driver,
      "trailers": dryLoad.trailers,
      "carrier": dryLoad.carrier,
      "receiver": receiverIRI(dryLoad.receiver),
    }
    return this.client.put('/outgoing-loads/' + selectedDryLoadId, mapUpdates);
  }

  saveOutgoingLoad(dryLoad) {
    return this.client.post('/outgoing-loads', mapOutgoingLoad(dryLoad));
  }

  setReceiverNet(receiverNet, selectedDryLoadId){
    let req = {
      "outgoingLoadId": selectedDryLoadId,
      "receiverNet": Number(receiverNet)
    }
    return this.client.post('/outgoing-loads/receiver-net', req);
  }

  saveIncomingLoad(wetLoad) {
    return this.client.post('/incoming-loads', mapLoad(wetLoad));
  }

  moveAllFillOrder(fillOrder) {
    return this.client.post('/fill-orders/move-all', mapMoveAllItems(fillOrder))
  }

  moveFillOrderItem(fillOrder, itemId) {
    return this.client.post('/fill-orders/move-item', mapMoveItem(fillOrder, itemId))
  }

  moveBackFillOrderItem(fillOrderId, itemId){
    let params = {
      "fillOrder": fillOrderIRI(fillOrderId),
      "fillOrderItem": fillOrderItemIRI(itemId),
    };
    return this.client.post('fill-orders/move-back-item', params, {
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      }
    })
  }

  createFillOrder(newFillOrder) {
    return this.client.post('/fill-orders', mapDryLoad(newFillOrder));
  }

  getFillOrderItemById(id){
    return this.client.get(`/fill-order-items/${id}`)
  }

  getFillOrder(fillOrderId) {
    return this.client.get(`/fill-orders/${fillOrderId}`)
  }

  getFillOrderWithCancelToken(fillOrderId) {
    const cancelToken = this.createCancelToken();
    return [this.client.get(`/fill-orders/${fillOrderId}`, {cancelToken: cancelToken.token}), cancelToken];
  }

  removeFillOrderItem(itemId){
    return this.client.post(`/fill-order-items/delete`, {"fillOrderItemId": itemId});
  }

  getHydraFillOrders(params = {}){
    const cancelToken = this.createCancelToken();
    return [this.client.get('/fill-orders?pagination=false', {
      params: Object.assign({
        'order[createdAt]': 'desc',
      }, params),
      headers: {"Accept": "application/ld+json"}
    }).then(resp => createResultSet(resp, null)), cancelToken];
  }

  getHydraOutgoingLoads(params = {}) {
    const cancelToken = this.createCancelToken();
    return [this.client.get('/outgoing-loads', {
      params: Object.assign({
        'order[createdAt]': 'desc',
      }, params),
      headers: {"Accept": "application/ld+json"}
    }).then(resp => createResultSet(resp, params.page || 1, 30)), cancelToken];
  }

  getHydraIncomingLoads(params = {}) {
    const cancelToken = this.createCancelToken();
    return [this.client.get('/incoming-loads', {
      params: Object.assign({
        'order[createdAt]': 'desc',
      }, params),
      headers: {"Accept": "application/ld+json"}
    }).then(resp => createResultSet(resp, params.page || 1, 30)), cancelToken];
  }

  getIncomingLoads(params = {}) {
    const cancelToken = this.createCancelToken();
    return [this.client.get('/incoming-loads', {
      cancelToken: cancelToken.token,
      params: Object.assign({
        pagination: false,
        'order[prioritySort]': 'desc',
        'order[createdAt]': 'asc',
      }, params)
    }), cancelToken];
  }

  getPrioritizedLoads(name) {
    const cancelToken = this.createCancelToken();
    return [this.client.get('/incoming-loads/prioritized/'+name, {
      cancelToken: cancelToken.token,
    }), cancelToken];
  }

  prioritizeLoads(name, highPriorityLoadIds, lowPriorityLoadIds) {
    const cancelToken = this.createCancelToken();
    return [this.client.put('/incoming-loads/prioritize', {name, highPriorityLoadIds, lowPriorityLoadIds}, {
      cancelToken: cancelToken.token,
    }), cancelToken];
  }

  getHydraStorageLotsCurrent(){
    return this.client.get('/storage-lots/current', {
      headers: {"Accept": "application/ld+json"}
    }).then(resp => createResultSet(resp));
  }

  removeIncomingLoad(incomingLoadId) {
    return this.client.delete(`/incoming-loads/${incomingLoadId}`);
  }

  removeOutgoingLoad(outgoingLoadId) {
    return this.client.delete(`/outgoing-loads/${outgoingLoadId}`);
  }

  getIncomingLoadDumps(state = null, params = {}) {
    const cancelToken = this.createCancelToken();
    return [this.client.get('/incoming-load-dumps', {
      cancelToken: cancelToken.token,
      params: Object.assign(
        {pagination: false, 'order[createdAt]': 'DESC'},
        state ? {state} : {},
        params),
    }), cancelToken];
  }

  dumpIncomingLoad(incomingLoadId, pitId) {
    return this.client.post('/incoming-load-dumps', {
      incomingLoad: incomingLoadIRI(incomingLoadId),
      pit: pitIRI(pitId),
    });
  }

  removeDumpedLoad(incomingLoadDumpId) {
    return this.client.delete(`/incoming-load-dumps/${incomingLoadDumpId}`);
  }

  openAirDoor(binId) {
    return this.client.post(`/bins/${binId}/open-air-door`, {});
  }

  closeAirDoor(binId) {
    return this.client.post(`/bins/${binId}/close-air-door`, {});
  }

  fillBin(dumpedLoadId, binId, percentFull) {
    return this.client.post('/bin-fills', {
      incomingLoadDump: `/incoming-load-dumps/${dumpedLoadId}`,
      bin: binIRI(binId),
      percentFull: percentFull,
    });
  }

  binFillEditPercent(binFillId, percentFull) {
    return this.client.post('/bin-fills/edit-percent', {
      binFill: binFillIRI(binFillId),
      percentFull: percentFull,
    });
  }

  removeBinFill(binFillId) {
    return this.client.delete(`/bin-fills/${binFillId}`);
  }

  createIncomingLoadNote(wetLoadId, text) {
    let params = {
      "incomingLoad": incomingLoadIRI(wetLoadId),
      "text": text
    };
    return this.client.post('/incoming-loads/note', params);
  }

  updateIncomingLoadNote(noteId, text) {
    let params = {
      "note": noteIRI(noteId),
      "text": text
    };
    return this.client.post('/notes/update', params);
  }

  removeIncomingLoadNote(noteId) {
    return this.client.delete('/notes/' + noteId);
  }

  createDryTruck(dryTruck) {
    return this.client.post('/dry-trucks', dryTruck);
  }

  getDryTrucks(params = null) {
    return this.client.get('/dry-trucks', {params});
  }

  getDryTruckLots(params = null) {
    const cancelToken = this.createCancelToken();
    return [this.client.get('/dry-truck-lots', {params, cancelToken: cancelToken.token}), cancelToken];
  }

  getDryTruck(id) {
    const cancelToken = this.createCancelToken();
    return [this.client.get(`/dry-trucks/${id}`, { cancelToken: cancelToken.token }), cancelToken];
  }

  addDryTruckAllotment(dryTruckId, allotRequest) {
    return this.client.post(`/dry-trucks/${dryTruckId}/allotment`, allotRequest);
  }

  removeDryTruck(dryTruckId) {
    return this.client.delete(`/dry-trucks/${dryTruckId}`);
  }

  removeDryTruckAllotment(dryTruckLotId) {
    return this.client.delete(`/dry-truck-lots/${dryTruckLotId}`);
  }
}
