import {createDryTruckListPanelExports} from "./dryTruckListPanelFactory";

export const {
  SET_DRY_TRUCKS,
  FETCH_DRY_TRUCKS_START,
  FETCH_DRY_TRUCKS_FINISH,
  dryTruckListPanelActions: unfilledDryTruckListPanelActions,
  dryTruckListPanelSelectors: unfilledDryTruckListPanelSelectors,
  dryTruckListPanelReducer: unfilledDryTruckListPanelReducer,
} = createDryTruckListPanelExports('unfilled', {fillState: ['created', 'filling']});

export default unfilledDryTruckListPanelReducer;
