import React from 'react';
import { Navbar, NavbarToggler, Collapse, Nav, NavItem } from 'reactstrap';
import {NavLink} from 'react-router-dom';
import compose from 'lodash/fp/compose';
import {withMobileSize} from "../../common/component/size";

class GHMFNav extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isOpen: false }
    this.toggleNav = this.toggleNav.bind(this);
  }

  toggleNav() {
    this.setState((state) => {
      return {isOpen: !state.isOpen};
    });
  }

  render() {
    const {isLoggedIn, logoutAction, forceMobile, toggleMobileView, isNavMobile, tightLayout = false} = this.props;
    const navItems = isLoggedIn ? <React.Fragment>
      <NavItem><NavLink to="/incoming-loads" className="nav-link">Incoming Loads</NavLink></NavItem>
      <NavItem><NavLink to="/outgoing-loads" className="nav-link">Outgoing Loads</NavLink></NavItem>
      <NavItem><NavLink to="/wetside" className="nav-link">Wetside</NavLink></NavItem>
      <NavItem><NavLink to="/dry-trucks" className="nav-link">Dry Trucks</NavLink></NavItem>
      <NavItem><NavLink to="/fill-orders" className="nav-link">Fill Orders</NavLink></NavItem>
      <NavItem><NavLink to="#" className="nav-link" onClick={() => toggleMobileView()}>{forceMobile ? "Desktop App" : "Mobile App"}</NavLink></NavItem>
      <NavItem>
        <a href="#" className="nav-link" onClick={logoutAction}>Logout</a>
      </NavItem>
    </React.Fragment> : null;

    return <Navbar color="dark" dark={true} expand="md" style={tightLayout && !isNavMobile ? {height: "24px"} : {}}>
      <NavbarToggler onClick={this.toggleNav}/>
      <NavLink to="/" className="navbar-brand">GHMF Huller</NavLink>
      <Collapse isOpen={this.state.isOpen} navbar={true}>
        <Nav className="ml-auto"  navbar={true}>{navItems}</Nav>
      </Collapse>
    </Navbar>
  }
}

export default compose(
  withMobileSize
)(GHMFNav);
