import createActionType from '../createActionType';
import {produce} from 'immer';
import pickBy from 'lodash/fp/pickBy';

import {createFilter, createFilterMap, createSelectOption} from "../common/component/filter";
import composeReducers from "../util/composerReducers";
import {INIT, SET_COMMON_STATE_DATA} from "../common/actions";
import * as loginSelectors from '../login/selector/login';
import {listenOnceLoggedIn} from "../common/subscribers";
import {reloadForever} from "../util/time";
import { getCurrentSeason } from '../home/selector/season';

export const OPEN_ADD_WET_LOAD_MODAL = createActionType('incomingLoads', 'OPEN_ADD_WET_LOAD_MODAL');
export const CLOSE_ADD_WET_LOAD_MODAL = createActionType('incomingLoads', 'CLOSE_ADD_WET_LOAD_MODAL');

export const SET_WET_LOAD_RESULTS = createActionType('incomingLoads', 'SET_WET_LOAD_RESULTS');
export const SET_IS_LOADING = createActionType('incomingLoads', 'SET_IS_LOADING');
export const SET_PAGE = createActionType('incomingLoads', 'SET_PAGE');
export const SET_FILTER_VALUE = createActionType('incomingLoads', 'SET_FILTER_VALUE');

export const INPUT_ON_CHANGE = createActionType('incomingLoads', 'INPUT_ON_CHANGE');

export const UPDATE_SELECTED_WET_LOAD = createActionType('incomingLoads', 'UPDATE_SELECTED_WET_LOAD');
export const SET_SELECTED_WET_LOAD_ID = createActionType('incomingLoads', 'SET_SELECTED_WET_LOAD_ID');

export const SET_WEIGHT_CERT = createActionType('incomingLoads','SET_WEIGHT_CERT');
export const SET_STATS = createActionType('incomingLoads', 'SET_STATS');


/** @param {ApiClient} apiClient */
export function incomingLoadsActions(apiClient, commonActions) {
  function saveIncomingLoad(wetLoad, selectedWetLoadId) {
    return (dispatch) => {
      dispatch(setIsLoading(true));

      const saveIncomingLoadPromise = selectedWetLoadId
        ? apiClient.updateIncomingLoad(wetLoad, selectedWetLoadId)
        : apiClient.saveIncomingLoad(wetLoad);

      return saveIncomingLoadPromise.then(() => {
        dispatch(closeAddWetLoadModal());
      }, alert).then(() => {
        dispatch(setIsLoading(false));
      });
    };
  }

  function setStats(wetLoadResults) {
    return {type: SET_STATS, wetLoadResults}
  }

  function inputOnChange(name, value) {
    return {type: INPUT_ON_CHANGE, name, value}
  }

  function setSelectedWetLoadId(selectedWetLoadId) {
    return {type: SET_SELECTED_WET_LOAD_ID, selectedWetLoadId};
  }

  function openAddWetLoadModal(wetLoad) {
    return (dispatch) => {
      if (wetLoad) {
        return Promise.all([
          dispatch(showAddWetLoadModal()),
          dispatch(preFillWetLoad(wetLoad)),
          dispatch(setSelectedWetLoadId(wetLoad.id))
        ]);
      } else {
        dispatch(showAddWetLoadModal());
        return apiClient.getNextWeightCert().then(resp => dispatch(setWeightCert(resp)))
      }
    }
  }

  function setWeightCert(weightCert) {
    return {type: SET_WEIGHT_CERT, weightCert}
  }

  function preFillWetLoad(wetLoad) {
    return {type: UPDATE_SELECTED_WET_LOAD, wetLoad};
  }

  function showAddWetLoadModal() {
    return {type: OPEN_ADD_WET_LOAD_MODAL};
  }

  function closeAddWetLoadModal() {
    return (dispatch, getState) => Promise.all([
      dispatch(hideAddWetLoadModal()),
      dispatch(setSelectedWetLoadId(null)),
      dispatch(clearIncomingLoad()),
      dispatch(setWeightCert(null)),
    ]);
  }

  function hideAddWetLoadModal() {
    return {type: CLOSE_ADD_WET_LOAD_MODAL};
  }

  function setWetLoadResults(wetLoadResults) {
    return {type: SET_WET_LOAD_RESULTS, wetLoadResults};
  }

  function setIsLoading(isLoading) {
    return {type: SET_IS_LOADING, isLoading};
  }
  function setPage(page) {
    return {type: SET_PAGE, page};
  }

  function loadData(fn) {
    return (dispatch, getState) => {
      dispatch(setIsLoading(true));
      return fn().then(() => {}, () => {}).then(() => {
        dispatch(setIsLoading(false));
      });
    }
  }

  const pickNotNull = pickBy(Boolean);
  function getTableFilterSelectedValue(getState, filterName) {
    const res = getState().incomingLoads.tableFilters[filterName].selectedValue;
    return res && res.value || null;
  }

  function fetchWetLoads() {
    return (dispatch, getState) => {
      return dispatch(loadData(() => {
        const params = pickNotNull({
          page: getState().incomingLoads.currentPage,
          weightCert: getTableFilterSelectedValue(getState, 'weightCert'),
          freightBill: getTableFilterSelectedValue(getState, 'freightBill'),
          grower: getTableFilterSelectedValue(getState, 'grower'),
          field: getTableFilterSelectedValue(getState, 'field'),
          variety: getTableFilterSelectedValue(getState, 'variety'),
          driver: getTableFilterSelectedValue(getState, 'driver'),
          truck: getTableFilterSelectedValue(getState, 'truck'),
          trailerSet: getTableFilterSelectedValue(getState, 'trailerSet'),
          designatedReceiver: getTableFilterSelectedValue(getState, 'receiver'),
          season: getCurrentSeason(getState()),
        });
        return apiClient.getHydraIncomingLoads(params)[0].then(resultSet => {
          dispatch(setWetLoadResults(resultSet));
          dispatch(setStats(resultSet));
        });
      }));
    }
  }

  function changePage(page) {
    return (dispatch, getState) => {
      dispatch(setPage(page));
      return dispatch(fetchWetLoads());
    };
  }

  function removeWetLoad(wetLoadId, weightCert, grower) {
    return (dispatch, getState) => {
      if (!window.confirm(`Are you sure you want to delete this load? \nWeight Cert #${weightCert} \nGrower: ${grower}`)) {
        return Promise.resolve();
      }
      dispatch(setIsLoading(true));
      return apiClient.removeIncomingLoad(wetLoadId).then(() => {
        return dispatch(fetchWetLoads());
      }, alert).then(() => {
        dispatch(setIsLoading(false));
      });
    }
  }

  function setFilterValue(filterName, selectedValue) {
    return (dispatch, getState) => {
      dispatch({type: SET_FILTER_VALUE, filterName, selectedValue});
      dispatch(setPage(1));
      return dispatch(fetchWetLoads());
    }
  }

  function clearIncomingLoad() {
    return {type: UPDATE_SELECTED_WET_LOAD, wetLoad: null}
  }

  function initIncomingLoadsComponent() {
    return (dispatch) => {
      dispatch(fetchWetLoads());
    };
  }

  //checks if fields are there before printing
  function checkPrint(printLink) {
    return (dispatch, getState) => {
      const state = getState();
      const wetLoadForm = state.incomingLoads.wetLoadForm;
      const gross = wetLoadForm.gross;
      const tare = wetLoadForm.tare;
      const params = {
        weightCert: parseInt(wetLoadForm.weightCert),
        grower: parseInt(wetLoadForm.grower),
        field: parseInt(wetLoadForm.field),
        variety: parseInt(wetLoadForm.variety),
        driver: parseInt(wetLoadForm.driver),
        truck: parseInt(wetLoadForm.truck),
        trailerSet: parseInt(wetLoadForm.trailerSet),
        season: getCurrentSeason(getState()),
      };
      for (let key in params) {
        if(!params[key]) return alert(`Please insert a ${key}`);
      }
      if(!gross || !tare) return alert('Please insert a gross and tare');
      return apiClient.getHydraIncomingLoads(params)[0].then(res => {
        if(!res.items.length) {
          window.open(printLink, "_blank");
        }
        else if(res.items[0].weightCert.state === "unavailable"){
          alert("Weight Cert is Taken")
        }
      })
    }
  }

  return {
    openAddWetLoadModal,
    closeAddWetLoadModal,
    fetchWetLoads,
    changePage,
    clearIncomingLoad,
    inputOnChange,
    saveIncomingLoad,
    setFilterValue,
    removeWetLoad,
    initIncomingLoadsComponent,
    checkPrint
  };
}

export function incomingLoadsSubscribers(container, emitter) {
  listenOnceLoggedIn(container, emitter, (dispatch) => {
    const fetchWetLoads = () => dispatch(container.incomingLoadsActions.fetchWetLoads());

    fetchWetLoads();
    container.pusher.subscribe('reload').bind('incomingLoads', fetchWetLoads);
  });
  return emitter;
}

const initialState = {
  showAddWetLoadModal: false,
  wetLoadResults: {},
  selectedWetLoadId: null,
  wetLoadForm: {
    weightCert: '', freightBill: '', grower: '', field: '', variety: '', designatedReceiver: '', gross: '',driver: '', tare: '', truck: '', trailerSet: '', splitTrailerType: 'none'
  },
  nextWeightCert: null,
  tableFilters: createFilterMap([
    createFilter('weightCert', 'Weight Certs'),
    createFilter('freightBill', 'Freight Bill'),
    createFilter('grower', 'Grower'),
    createFilter('field', 'Field'),
    createFilter('variety', 'Variety'),
    createFilter('driver', 'Driver'),
    createFilter('truck', 'Truck'),
    createFilter('trailerSet', 'Trailer Set'),
    createFilter('receiver', 'Receiver')
  ]),
  currentPage: 1,
  isLoading: false,
  stats: {
    loads: 0,
    net: 0
  }
};

export function updateTableSelectFilterFromEntities(state, action, field, defaultOptionName) {
  const defaultOption = createSelectOption(null, defaultOptionName);
  state.tableFilters[field].options = action.value.map(e => createSelectOption(e.id, e.name, {entity: e}));
  state.tableFilters[field].options.unshift(defaultOption);
}

export function commonDataReducer(state, action) {
  return produce(state, draft => {
    switch (action.type) {
      case SET_COMMON_STATE_DATA:
        switch (action.field) {
          case 'growers':
            updateTableSelectFilterFromEntities(draft, action, 'grower', 'All Growers');
            break;
          case 'fields':
            updateTableSelectFilterFromEntities(draft, action, 'field', 'All Fields');
            break;
          case 'varieties':
            updateTableSelectFilterFromEntities(draft, action, 'variety', 'All Varieties');
            break;
          case 'trucks':
            updateTableSelectFilterFromEntities(draft, action, 'truck', 'All Trucks');
            break;
          case 'drivers':
            updateTableSelectFilterFromEntities(draft, action, 'driver', 'All Drivers');
            break;
          case 'trailerSets':
            updateTableSelectFilterFromEntities(draft, action, 'trailerSet', 'All Trailer Sets');
            break;
          case 'receivers':
            updateTableSelectFilterFromEntities(draft, action, 'receiver', 'All Receivers');
            break;
        }
    }
  });
}

function updateSelectedWetLoad(state, action) {
  if (action.wetLoad) {
    return {
      weightCert: action.wetLoad.weightCert.num || '',
      freightBill: action.wetLoad.freightBill ? action.wetLoad.freightBill.num || '' : '' ,
      grower: action.wetLoad.grower.id || '',
      field: action.wetLoad.field.id || '',
      variety: action.wetLoad.variety.id || '',
      gross: action.wetLoad.gross || '',
      driver: action.wetLoad.driver.id || '',
      net: action.wetLoad.net || '',
      tare: action.wetLoad.tare || '',
      truck: action.wetLoad.truck.id || '',
      trailerSet: action.wetLoad.trailerSet.id || '',
      splitTrailerType: action.wetLoad.splitTrailerType || 'none',
      designatedReceiver: action.wetLoad.designatedReceiver ? action.wetLoad.designatedReceiver.id || '' : null,
      scaleTime: action.wetLoad.createdAt || new Date()
    };
  } else {
      initialState.wetLoadForm.weightCert = state.wetLoadForm.weightCert
      return initialState.wetLoadForm;
  }
}

function calculateNets(wetLoadResults) {
  var nets = 0;
  wetLoadResults.items.map((item, index) => (nets += item.net));
  return Number(Math.round(nets / 2000)).toLocaleString();
}

function incomingLoadsReducer(state = initialState, action) {
  return produce(state, draft =>{
    switch(action.type){
      case OPEN_ADD_WET_LOAD_MODAL:
        draft.showAddWetLoadModal = true;
        break;
      case CLOSE_ADD_WET_LOAD_MODAL:
        draft.showAddWetLoadModal = false;
        break;
      case SET_WET_LOAD_RESULTS:
        draft.wetLoadResults = action.wetLoadResults;
        break;
      case SET_STATS:
        draft.stats = {
          loads: action.wetLoadResults.totalItems,
          net: calculateNets(action.wetLoadResults)
        };
        break;
      case SET_IS_LOADING:
        draft.isLoading = action.isLoading;
        break;
      case SET_PAGE:
        draft.currentPage = action.page;
        break;
      case UPDATE_SELECTED_WET_LOAD:
        draft.wetLoadForm = updateSelectedWetLoad(state, action);
        break;
      case SET_FILTER_VALUE:
        draft.tableFilters[action.filterName].selectedValue = action.selectedValue;
        break;
      case INPUT_ON_CHANGE:
        draft.wetLoadForm[action.name] = action.value;
        break;
      case SET_WEIGHT_CERT:
        action.weightCert === null ? draft.wetLoadForm.weightCert = '' : draft.wetLoadForm.weightCert = action.weightCert
        break;
      case SET_SELECTED_WET_LOAD_ID:
        draft.selectedWetLoadId = action.selectedWetLoadId;
        break;
    }
  })
}

export default composeReducers(incomingLoadsReducer, commonDataReducer);
