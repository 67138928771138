import {produce} from 'immer'
import {canReloadByName, canReloadIncomingLoads} from "./selector/common";
import {
  SET_COMMON_STATE_DATA,
  INIT,
  DEFER_RELOAD,
  END_DEFER_RELOAD,
  START_RELOAD,
  END_RELOAD,
  SET_FORCE_MOBILE,
  SET_GROWER_FOR_STATS,
} from './actions';
import {listenOnceLoggedIn} from "./subscribers";

/**
 * @param {ApiClient} apiClient
 * @param {EventEmitter} eventEmitter
 */
export function commonActions(apiClient, eventEmitter) {
  function setForceMobile(forceMobile) {
    return {type: SET_FORCE_MOBILE, forceMobile};
  }
  function setGrowerForStats(growerId) {
    return {type: SET_GROWER_FOR_STATS, growerId};
  }

  function setCommonStateData(field, value) {
    return {type: SET_COMMON_STATE_DATA, field, value};
  }
  const setGrowers = setCommonStateData.bind(null, 'growers');
  const setGrowerStats = setCommonStateData.bind(null, 'growerStats');
  const setFields = setCommonStateData.bind(null, 'fields');
  const setVarieties = setCommonStateData.bind(null, 'varieties');
  const setPits = setCommonStateData.bind(null, 'pits');
  const setTrucks = setCommonStateData.bind(null, 'trucks');
  const setDrivers = setCommonStateData.bind(null, 'drivers');
  const setTrailerSets = setCommonStateData.bind(null, 'trailerSets');
  const setReceivers = setCommonStateData.bind(null, 'receivers');
  const setStorageBins = setCommonStateData.bind(null, 'storageBins');
  const setStorageLots = setCommonStateData.bind(null, 'storageLots');

  function loadData(setData, fetchData) {
    return (dispatch, getState) => {
      return fetchData().then(data => {
        return dispatch(setData(data));
      });
    }
  }

  function loadGrowers() { return loadData(setGrowers, () => apiClient.getGrowers()); }
  function loadGrowerStats() { return loadData(setGrowerStats, () => apiClient.getGrowerStats()); }
  function loadFields() { return loadData(setFields, () => apiClient.getFields()); }
  function loadVarieties() { return loadData(setVarieties, () => apiClient.getVarieties()); }
  function loadPits() { return loadData(setPits, () => apiClient.getPits().then(pits => pits.reverse())); }
  function loadTrucks() { return loadData(setTrucks, () => apiClient.getTrucks()); }
  function loadDrivers() { return loadData(setDrivers, () => apiClient.getDrivers()); }
  function loadTrailerSets() { return loadData(setTrailerSets, () => apiClient.getTrailerSets()); }
  function loadReceivers() { return loadData(setReceivers, () => apiClient.getReceivers()); }
  function loadStorageBins() { return loadData(setStorageBins, () => apiClient.getStorageBins()); }
  function loadStorageLots() { return loadData(setStorageLots, () => apiClient.getStorageLots()); }

  function init() {
    return (dispatch, getState) => {
      eventEmitter.emit('common.init', dispatch, getState);
      dispatch(setForceMobile(localStorage.getItem('mobileView') === 'true' ? true : false));
    };
  }

  /** Cancel any currently running reloads and then wrap an fn with defer and end defer actions */
  function deferReload(names, fn) {
    names = Array.isArray(names) ? names : [names];
    return (dispatch, getState) => {
      names.forEach((name) => {
        const tokens = getState().common.reloadCancelTokens[name];
        if (tokens) {
          tokens.forEach(cancelToken => cancelToken.cancel());
        }
        dispatch({type: DEFER_RELOAD, name});
      });

      return fn().then((res) => {
        names.forEach(name => dispatch(endDeferReload(name)));
        return res;
      }, (e) => {
        names.forEach(name => dispatch(endDeferReload(name)));
        throw e;
      })
    };
  }

  function endDeferReload(name) {
    return {type: END_DEFER_RELOAD, name};
  }

  function startReload(name, cancelToken) {
    return {type: START_RELOAD, name, cancelToken};
  }
  function endReload(name, cancelToken) {
    return {type: END_RELOAD, name, cancelToken};
  }

  function toggleMobileView() {
    return (dispatch, getState) => {
      const forceMobile = !getState().common.mobileContext.forceMobile;
      localStorage.setItem('mobileView', JSON.stringify(forceMobile));
      dispatch(setForceMobile(forceMobile));
    }
  }

  function reloadData(name, reloadFn) {
    return (dispatch, getState) => {
      if (!canReloadByName(name)(getState())) {
        return Promise.resolve();
      }

      const [resPromise, cancelToken] = reloadFn();
      dispatch(startReload(name, cancelToken));
      return resPromise.then((res) => {
        dispatch(endReload(name, cancelToken));
        return res;
      }, (e) => {
        dispatch(endReload(name, cancelToken));
        throw e;
      });
    }
  }

  return {
    loadGrowers,
    loadGrowerStats,
    loadFields,
    loadVarieties,
    loadPits,
    loadTrucks,
    loadDrivers,
    loadReceivers,
    loadStorageBins,
    loadStorageLots,
    loadTrailerSets,
    init,
    deferReload,
    reloadData,
    toggleMobileView,
    setGrowers,
    setFields,
    setVarieties,
    setStorageBins,
    setGrowerForStats,
    setReceivers,
    setPits,
  };
}

export function commonSubscribers(container, emitter) {
  const dispatch = container.store.dispatch;

  listenOnceLoggedIn(container, emitter, (dispatch) => {
    dispatch(container.commonActions.loadGrowers());
    dispatch(container.commonActions.loadGrowerStats());
    dispatch(container.commonActions.loadFields());
    dispatch(container.commonActions.loadVarieties());
    dispatch(container.commonActions.loadPits());
    dispatch(container.commonActions.loadTrucks());
    dispatch(container.commonActions.loadDrivers());
    dispatch(container.commonActions.loadTrailerSets());
    dispatch(container.commonActions.loadReceivers());
    dispatch(container.commonActions.loadStorageBins());
    dispatch(container.commonActions.loadStorageLots());

    const reloadChannel = container.pusher.subscribe('reload');
    reloadChannel.bind('grower', (data) => {
      dispatch(container.commonActions.loadGrowers());
    });
    reloadChannel.bind('growerStats', () => {
      dispatch(container.commonActions.loadGrowerStats());
    });
    reloadChannel.bind('storageLots', () => {
      dispatch(container.commonActions.loadStorageLots());
    });
  });
  return emitter;
}

const initialState = {
  growers: null,
  growerStats: null,
  fields: null,
  varieties: null,
  pits: null,
  trucks: null,
  drivers: null,
  trailerSets: null,
  receivers: null,
  storageBins: null,
  storageLots: null,
  deferredReload: {},
  reloadCancelTokens: {},
  mobileContext: {forceMobile: false},
  selectedGrowerIdForStats: null,
};

 export default function commonReducer(state = initialState, action) {
  return produce(state, draft => {
    switch(action.type) {
      case SET_COMMON_STATE_DATA:
        draft[action.field] = action.value;
        break;
      case DEFER_RELOAD:
        draft.deferredReload[action.name] = action.name in draft.deferredReload ? draft.deferredReload[action.name] + 1 : 1
        break;
      case END_DEFER_RELOAD:
        draft.deferredReload[action.name] -= 1;
        break;
      case START_RELOAD:
        draft.reloadCancelTokens[action.name] = action.name in draft.deferredReload ? draft.reloadCancelTokens[action.name].add(action.cancelToken) : new Set([action.cancelToken]);
        break;
      case END_RELOAD:
        draft.reloadCancelTokens[action.name].delete(action.cancelToken);
        break;
      case SET_FORCE_MOBILE:
        draft.mobileContext.forceMobile = action.forceMobile;
        break;
      case SET_GROWER_FOR_STATS:
        draft.selectedGrowerIdForStats = action.growerId;
    }
  })
}
