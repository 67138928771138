import createActionType from '../createActionType';
import {produce} from 'immer';
import {get} from 'lodash';
import pickBy from 'lodash/fp/pickBy';

import {INIT, SET_COMMON_STATE_DATA} from "../common/redux";
import {createFilter, createFilterMap, createSelectOption} from "../common/component/filter";
import {commonDataReducer, updateTableSelectFilterFromEntities} from "../incoming-loads/redux";
import composeReducers from "../util/composerReducers";
import {getDryLoad} from "./selector/outgoing-loads";
import {getStorageLotsById} from "../common/selector/common";
import {listenOnceLoggedIn} from "../common/subscribers";
import {reloadForever} from "../util/time";
import {storageLotIRI} from "../common/model/iri";
import { getCurrentSeason } from '../home/selector/season';

export const OPEN_ADD_DRY_LOAD_MODAL = createActionType('outgoingLoads', 'OPEN_ADD_DRY_LOAD_MODAL');
export const CLOSE_ADD_DRY_LOAD_MODAL = createActionType('outgoingLoads', 'CLOSE_ADD_DRY_LOAD_MODAL');

export const SET_DRY_LOAD_RESULTS = createActionType('outgoingLoads', 'SET_DRY_LOAD_RESULTS');
export const SET_IS_LOADING = createActionType('outgoingLoads', 'SET_IS_LOADING');
export const SET_PAGE = createActionType('outgoingLoads', 'SET_PAGE');
export const SET_FILTER_VALUE = createActionType('outgoingLoads', 'SET_FILTER_VALUE');
export const SET_SEARCH_VALUE = createActionType('outgoingLoads', 'SET_SEARCH_VALUE');

export const INPUT_ON_CHANGE = createActionType('outgoingLoads', 'INPUT_ON_CHANGE');

export const CLEAR_CURRENT_RECEIVER = createActionType('outgoingLoads', 'CLEAR_CURRENT_RECEIVER');
export const SET_SELECTED_DRY_LOAD = createActionType('outgoingLoads', 'SET_SELECTED_DRY_LOAD');

export const SET_WEIGHT_CERT = createActionType('outgoingLoads','SET_WEIGHT_CERT');
export const SET_STATS = createActionType('outgoingLoads', 'SET_STATS');
export const ADD_PART_TO_DRY_LOAD_FORM = createActionType('outgoingLoads', 'ADD_PART_TO_DRY_LOAD_FORM');
export const REMOVE_PART_FROM_DRY_LOAD_FORM = createActionType('outgoingLoads', 'REMOVE_PART_FROM_DRY_LOAD_FORM');
export const STEP_PART_PERCENT = createActionType('outgoingLoads', 'STEP_PART_PERCENT');
export const PART_PERCENT_EMPTY = createActionType('outgoingLoads', 'PART_PERCENT_EMPTY');
export const SET_PART_POUNDS_REMAINING = createActionType('outgoingLoads', 'SET_PART_POUNDS_REMAINING');

function round(value, decimals) {
  return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
}

/** @param {ApiClient} apiClient */
export function outgoingLoadsActions(apiClient, commonActions, fillordersActions) {

  function saveOutgoingLoad(dryLoad, selectedDryLoadId) {
    return (dispatch) => {
      dispatch(setIsLoading(true));

      const saveIncomingLoadPromise = selectedDryLoadId
        ? apiClient.updateOutgoingLoad(dryLoad, selectedDryLoadId)
        : apiClient.saveOutgoingLoad(dryLoad);

      return saveIncomingLoadPromise.then(() => {
        dispatch(closeAddDryLoadModal());
      }, alert).then(() => {
        dispatch(setIsLoading(false));
      });
    }
  }

  function addPartToOutgoingLoad(storageLotId){
    return (dispatch, getState) => {
      const state = getState();
      const dryLoadForm = getDryLoad(state);
      const firstStorageLotId = get(dryLoadForm, 'parts[0].storageLot.id');

      const storageLotToAdd = getStorageLotsById(state)[storageLotIRI(storageLotId)];
      if (dryLoadForm.parts.some(part => get(part, "storageLot.id") === storageLotToAdd.id)) {
        alert('This lot already exists in the pending outgoing load.');
        return
      }
      if (firstStorageLotId) {
        const firstStorageLot = getStorageLotsById(state)[storageLotIRI(firstStorageLotId)];
        if(firstStorageLot.grower.id !== storageLotToAdd.grower.id || firstStorageLot.variety.id !== storageLotToAdd.variety.id){
          alert('Cannot mix grower or variety');
          return
        }
      }
      dispatch({type: ADD_PART_TO_DRY_LOAD_FORM, part: storageLotToAdd});
    }
  }

  function removePartFromDryLoadForm(idx){
      return {type: REMOVE_PART_FROM_DRY_LOAD_FORM, idx}
  }

  function setPartPoundsRemaining(idx, poundsRemaining) {
    return {type: SET_PART_POUNDS_REMAINING, idx, poundsRemaining};
  }

  function stepPartPercent(direction, idx, storageSize) {
    return {type: STEP_PART_PERCENT, direction, idx, storageSize}
  }

  function partPercentEmpty(empty, idx, storageSize){
    return {type: PART_PERCENT_EMPTY, empty, idx, storageSize}
  }

  function setReceiverNet(receiverNet, outgoingLoadId){
    return dispatch => {
      return apiClient.setReceiverNet(receiverNet, outgoingLoadId).then(() => {
        dispatch(fetchDryLoads());
      })
    }
  }

  function setStats(dryLoadResults) {
    return {type: SET_STATS, dryLoadResults}
  }

  function inputOnChange(name, value) {
    return {type: INPUT_ON_CHANGE, name, value}
  }

  function setSelectedDryLoad(dryLoad) {
    return {type: SET_SELECTED_DRY_LOAD, dryLoad};
  }

  function openAddDryLoadModal(dryLoad) {
    return (dispatch) => {
      if (dryLoad) {
        return Promise.all([
          dispatch(showAddDryLoadModal()),
          dispatch(setSelectedDryLoad(dryLoad)),
        ]);
      } else {
        dispatch(showAddDryLoadModal());
        return apiClient.getNextWeightCert().then(resp => dispatch(setWeightCert(resp)))
      }
    }
  }

  function setWeightCert(weightCert) {
    return {type: SET_WEIGHT_CERT, weightCert}
  }

  function showAddDryLoadModal() {
    return {type: OPEN_ADD_DRY_LOAD_MODAL};
  }

  function closeAddDryLoadModal() {
    return (dispatch) => Promise.all([
      dispatch(hideAddDryLoadModal()),
      dispatch(setSelectedDryLoad(null)),
      dispatch(setWeightCert(null)),
    ]);
  }

  function hideAddDryLoadModal() {
    return {type: CLOSE_ADD_DRY_LOAD_MODAL};
  }

  function setDryLoadResults(dryLoadResults) {
    return {type: SET_DRY_LOAD_RESULTS, dryLoadResults};
  }

  function setIsLoading(isLoading) {
    return {type: SET_IS_LOADING, isLoading};
  }
  function setPage(page) {
    return {type: SET_PAGE, page};
  }

  function loadData(fn) {
    return (dispatch, getState) => {
      dispatch(setIsLoading(true));
      return fn().then(() => {}, () => {}).then(() => {
        dispatch(setIsLoading(false));
      });
    }
  }

  const pickNotNull = pickBy(Boolean);
  function getTableFilterSelectedValue(getState, filterName) {
    const res = getState().outgoingLoads.tableFilters[filterName].selectedValue;
    return res && res.value || null;
  }

  function getSearchValue(getState, searchFilterName) {
    const res = getState().outgoingLoads.searchFilters[searchFilterName];
    return res || null;
  }

  function fetchDryLoads() {
    return (dispatch, getState) => {
      return dispatch(loadData(() => {
        const params = pickNotNull({
          page: getState().outgoingLoads.currentPage,
          weightCert: getTableFilterSelectedValue(getState, 'weightCert'),
          manifest: getTableFilterSelectedValue(getState, 'manifest'),
          grower: getTableFilterSelectedValue(getState, 'grower'),
          field: getTableFilterSelectedValue(getState, 'field'),
          variety: getTableFilterSelectedValue(getState, 'variety'),
          driver: getSearchValue(getState, 'driver'),
          truck: getSearchValue(getState, 'truck'),
          trailers: getSearchValue(getState, 'trailers'),
          receiver: getTableFilterSelectedValue(getState, 'receiver'),
          carrier: getSearchValue(getState, 'carrier'),
          season: getCurrentSeason(getState()),
        });
        return apiClient.getHydraOutgoingLoads(params)[0].then(resultSet => {
          dispatch(setDryLoadResults(resultSet));
          dispatch(setStats(resultSet));
          dispatch(fillordersActions.getDryStorage());
        });
      }));
    }
  }

  function changePage(page) {
    return (dispatch) => {
      dispatch(setPage(page));
      return dispatch(fetchDryLoads());
    };
  }

  function removeDryLoad(dryLoadId) {
    return (dispatch) => {
      if (!window.confirm("Are you sure you want to delete this load?")) {
        return Promise.resolve();
      }
      dispatch(setIsLoading(true));
      return apiClient.removeOutgoingLoad(dryLoadId).then(() => {
        return dispatch(fetchDryLoads());
      }, alert).then(() => {
        dispatch(setIsLoading(false));
      });
    }
  }

  function setFilterValue(filterName, selectedValue) {
    return (dispatch) => {
      dispatch({type: SET_FILTER_VALUE, filterName, selectedValue});
      dispatch(setPage(1));
      return dispatch(fetchDryLoads());
    }
  }

  function setSearchValue(searchName, value) {
    return (dispatch) => {
      dispatch({type: SET_SEARCH_VALUE, searchName, value});
      dispatch(setPage(1));
      return dispatch(fetchDryLoads());
    }
  }

  function clearOutgoingLoad() {
    return setSelectedDryLoad(null);
  }

  function clearReceiver() {
    return {type: CLEAR_CURRENT_RECEIVER}
  }

  return {
    openAddDryLoadModal,
    closeAddDryLoadModal,
    fetchDryLoads,
    changePage,
    clearOutgoingLoad,
    inputOnChange,
    saveOutgoingLoad,
    setFilterValue,
    removeDryLoad,
    addPartToOutgoingLoad,
    removePartFromDryLoadForm,
    stepPartPercent,
    partPercentEmpty,
    setReceiverNet,
    setSearchValue,
    clearReceiver,
    setPartPoundsRemaining,
  };
}

function createOutgoingLoadPart(part){
  return {
    storageLot: part,
    netPercent: part.netPercent,
    isStorageEmpty: true,
    poundsRemaining: 0,
  }
}

export function outgoingLoadsSubscribers(container, emitter) {
  listenOnceLoggedIn(container, emitter, (dispatch) => {
    const fetchDryLoads = () => dispatch(container.outgoingLoadsActions.fetchDryLoads());
    fetchDryLoads();
    container.pusher.subscribe('reload').bind('outgoingLoads', fetchDryLoads);
  });
  return emitter;
}

//immer initial
const initialState = {
  showAddDryLoadModal: false,
  dryLoadResults: {},
  selectedDryLoadId: null,
  selectedDryLoad: null,
  dryLoadForm: {
    weightCert: '', manifest: '', carrier: '', driver: '', truck: '', trailers: '', receiver: '', gross: '', tare: '', parts: []
  },
  nextWeightCert: null,
  tableFilters: createFilterMap([
    createFilter('weightCert', 'Weight Certs'),
    createFilter('manifest', 'Manifest'),
    createFilter('grower', 'Grower'),
    createFilter('field', 'Field'),
    createFilter('variety', 'Variety'),
    createFilter('driver', 'Driver'),
    createFilter('truck', 'Truck'),
    createFilter('trailerSet', 'Trailers'),
    createFilter('receiver', 'Receiver')
  ]),
  searchFilters: {
    driver: '', truck: '', trailers: '', carrier: ''
  },
  currentPage: 1,
  isLoading: false,
  stats: {
    loads: 0,
    net: 0,
    ratio: 0,
    receiverNet: 0
  }
};

function updateSelectedDryLoad(state, action) {
  if (action.dryLoad) {
    return {
      weightCert: action.dryLoad.weightCert.num || '',
      manifest: action.dryLoad.manifest ? action.dryLoad.manifest.num || '' : '' ,
      gross: action.dryLoad.gross || '',
      tare: action.dryLoad.tare || '',
      truck: action.dryLoad.truck || '',
      trailers: action.dryLoad.trailers || '',
      driver: action.dryLoad.driver || '',
      carrier: action.dryLoad.carrier || '',
      receiver: action.dryLoad.receiver.id || ''
    };
  } else {
      initialState.dryLoadForm.weightCert = state.dryLoadForm.weightCert;
      return initialState.dryLoadForm;
  }
}

function calculateNets(dryLoadResults) {
  var nets = 0;
  dryLoadResults.items.map((item, index) => (nets += (item.gross - item.tare)));
  return Number(Math.round(nets / 2000)).toLocaleString();
}

function outgoingLoadsReducer(state = initialState, action) {
  return produce(state, draft =>{
    switch (action.type) {
      case ADD_PART_TO_DRY_LOAD_FORM:
        draft.dryLoadForm.parts.push(createOutgoingLoadPart(action.part));
        break;
      case REMOVE_PART_FROM_DRY_LOAD_FORM:
        draft.dryLoadForm.parts.splice(action.idx, 1);
        break;
      case STEP_PART_PERCENT:
        if (draft.dryLoadForm.parts[action.idx].isStorageEmpty) {
          break; // don't allow step if we marked as empty
        }

        let tempPercent = draft.dryLoadForm.parts[action.idx].netPercent;
        let maxPercent = action.storageSize * 100;
        let maxAlteration = action.storageSize * 10;
        let tempMaxPercent = draft.dryLoadForm.parts[action.idx].storageLot.netPercent;
        if(action.direction === 'up') {
          tempPercent += maxAlteration;
          if(tempMaxPercent < tempPercent) return;
          draft.dryLoadForm.parts[action.idx].netPercent = Math.min(Math.max(0, tempPercent), maxPercent);
        } else if(action.direction === 'down') {
          tempPercent -= maxAlteration;
          draft.dryLoadForm.parts[action.idx].netPercent = Math.min(Math.max(0, tempPercent), maxPercent);
        }
        draft.dryLoadForm.parts[action.idx].poundsRemaining = Math.round((tempMaxPercent - draft.dryLoadForm.parts[action.idx].netPercent) * 70);
        break;
      case PART_PERCENT_EMPTY:
        let startPercent = draft.dryLoadForm.parts[action.idx].storageLot.netPercent;
        if (action.empty) {
          draft.dryLoadForm.parts[action.idx].isStorageEmpty = false;
          draft.dryLoadForm.parts[action.idx].netPercent = 0;
          draft.dryLoadForm.parts[action.idx].poundsRemaining = startPercent * 70;
        }
        else if (!action.empty) {
          draft.dryLoadForm.parts[action.idx].isStorageEmpty = true;
          draft.dryLoadForm.parts[action.idx].netPercent = startPercent;
          draft.dryLoadForm.parts[action.idx].poundsRemaining = 0;
        }
        break;
      case SET_PART_POUNDS_REMAINING:
        const part = draft.dryLoadForm.parts[action.idx];
        if (part.isStorageEmpty) {
          break; // can only set when part is marked as not empty
        }

        part.poundsRemaining = action.poundsRemaining;
        const percentRemaining = action.poundsRemaining / 70;
        part.netPercent = Math.round(part.storageLot.netPercent - percentRemaining);
        break;
      case OPEN_ADD_DRY_LOAD_MODAL:
        draft.showAddDryLoadModal = true;
        break;
      case CLOSE_ADD_DRY_LOAD_MODAL:
        draft.showAddDryLoadModal = false;
        break;
      case SET_DRY_LOAD_RESULTS:
        draft.dryLoadResults = action.dryLoadResults;
        break;
      case SET_STATS:
        draft.stats = {
          loads: action.dryLoadResults.totalItems,
          net: calculateNets(action.dryLoadResults)
        }
        break;
      case SET_IS_LOADING:
        draft.isLoading = action.isLoading;
        break;
      case SET_PAGE:
        draft.currentPage = action.page;
        break;
      case CLEAR_CURRENT_RECEIVER:
        draft.dryLoadForm.receiver = '';
        break;
      case SET_FILTER_VALUE:
        draft.tableFilters[action.filterName].selectedValue = action.selectedValue;
        break;
      case SET_SEARCH_VALUE:
        draft.searchFilters[action.searchName] = action.value;
      case INPUT_ON_CHANGE:
        draft.dryLoadForm[action.name] = action.value;
        break;
      case SET_WEIGHT_CERT:
        action.weightCert === null ? draft.dryLoadForm.weightCert = '' : draft.dryLoadForm.weightCert = action.weightCert
        break;
      case SET_SELECTED_DRY_LOAD:
        draft.dryLoadForm = updateSelectedDryLoad(state, action);
        draft.selectedDryLoad = action.dryLoad;
        draft.selectedDryLoadId = action.dryLoad ? action.dryLoad.id : null;
        break;
    }
  });
}

export default composeReducers(outgoingLoadsReducer, commonDataReducer)
