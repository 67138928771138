import createActionType from "../../createActionType";
import { produce } from "immer";
import {unfilledDryTruckListPanelSelectors} from "./unfilledDryTruckListPanel";
import {dryTruckIRI} from "../../common/model/iri";

export const SET_DRY_TRUCK_ID = createActionType('dry-trucks/dryTruckViewPage', 'SET_DRY_TRUCK_ID');
export const SET_DRY_TRUCK = createActionType('dry-trucks/dryTruckViewPage', 'SET_DRY_TRUCK');
export const FETCH_DRY_TRUCK_START = createActionType('dry-trucks/dryTruckViewPage', 'FETCH_DRY_TRUCK_START');
export const FETCH_DRY_TRUCK_END = createActionType('dry-trucks/dryTruckViewPage', 'FETCH_DRY_TRUCK_END');
export const SET_MODIFYING_DRY_TRUCK = createActionType('dry-trucks/dryTruckViewPage', 'SET_MODIFYING_DRY_TRUCK');

export const dryTruckViewPageSelectors = (() => {
  const dryTruckId = state => state.dryTruckViewPage.dryTruckId;
  const dryTruck = state => state.dryTruckViewPage.dryTruck;
  const isFetchingDryTruck = state => state.dryTruckViewPage.isFetchingDryTruck;
  const modifyingDryTruck = state => state.dryTruckViewPage.modifyingDryTruck;

  return {
    dryTruckId,
    dryTruck,
    isFetchingDryTruck,
    modifyingDryTruck,
  };
})();

const initialState = {
  dryTruck: null,
  dryTruckId: null,
  isFetchingDryTruck: false,
  modifyingDryTruck: false,
};

/**
 * @param {ApiClient} apiClient
 * @returns {{init: *}}
 */
export function dryTruckViewPageActions(apiClient, pusher) {
  const setDryTruckId = (dryTruckId) => ({ type: SET_DRY_TRUCK_ID, dryTruckId });
  const setDryTruck = (dryTruck) => ({ type: SET_DRY_TRUCK, dryTruck });
  const fetchDryTruckStart = () => ({ type: FETCH_DRY_TRUCK_START });
  const fetchDryTruckEnd = () => ({ type: FETCH_DRY_TRUCK_END });
  const setModifyingDryTruck = (modifyingDryTruck) => ({ type: SET_MODIFYING_DRY_TRUCK, modifyingDryTruck });

  function reloadDryTruck() {
    return (dispatch, getState) => {
      const currentDryTruckId = dryTruckViewPageSelectors.dryTruckId(getState());
      const [req, cancelToken] = apiClient.getDryTruck(currentDryTruckId);
      const mappedReq = req.then(res => {
        dispatch(setDryTruck(res));
      }, alert).then(() => {
        dispatch(fetchDryTruckEnd());
      });
      return [mappedReq, cancelToken];
    };
  }

  function initDryTruckViewPage(dryTruckId) {
    return (dispatch, getState) => {
      const currentDryTruckId = dryTruckViewPageSelectors.dryTruckId(getState());
      if (currentDryTruckId !== dryTruckId) {
        dispatch(setDryTruck(null));
      }

      const dryTrucksById = unfilledDryTruckListPanelSelectors.dryTrucksById(getState()) || {};
      const iri = dryTruckIRI(dryTruckId);
      if (dryTruckViewPageSelectors.dryTruck(getState()) === null && iri in dryTrucksById) {
        dispatch(setDryTruck(dryTrucksById[iri]));
      }

      dispatch(setDryTruckId(dryTruckId));
      dispatch(fetchDryTruckStart());

      function reloadDryTruckHandler() {
        return dispatch(reloadDryTruck());
      }

      const cancelToken = reloadDryTruckHandler()[1];

      const channel = pusher.subscribe('reload');
      channel.bind('dryTrucks', reloadDryTruckHandler);

      return () => {
        channel.unbind('dryTrucks', reloadDryTruckHandler);
        cancelToken.cancel();
      }
    }
  }

  function removeAllotment(lotId) {
    return (dispatch, getState) => {
      dispatch(setModifyingDryTruck(true));
      apiClient.removeDryTruckAllotment(lotId)
        .then(() => dispatch(reloadDryTruck())[0])
        .then(null, alert)
        .then(() => dispatch(setModifyingDryTruck(false)));
    }
  }

  function addAllotment(allotmentForm) {
    return (dispatch, getState) => {
      const dryTruckId = dryTruckViewPageSelectors.dryTruckId(getState());
      const sanitizedAllotmentForm = {
        ...allotmentForm,
        allottedStorageBins: allotmentForm.allottedStorageBins.map(lot => {
          return lot.remainingPounds ? { ...lot, remainingPounds: parseInt(lot.remainingPounds) } : lot;
        })
      };

      dispatch(setModifyingDryTruck(true));
      // returns if request was successful as a boolean
      return apiClient.addDryTruckAllotment(dryTruckId, sanitizedAllotmentForm)
        .then(() => true)
        .then((res) => dispatch(reloadDryTruck())[0].then(() => res))
        .then(null, alert)
        .then((res) => {
          dispatch(setModifyingDryTruck(false));
          return res || false;
        });
    };
  }

  function removeDryTruck(goToLocation) {
    return (dispatch, getState) => {
      const dryTruckId = dryTruckViewPageSelectors.dryTruckId(getState());
      dispatch(setModifyingDryTruck(true));
      apiClient.removeDryTruck(dryTruckId)
        .then(null, alert)
        .then(() => dispatch(setModifyingDryTruck(false)))
        .then(() => goToLocation('/dry-trucks'));
    }
  }

  return { initDryTruckViewPage, removeAllotment, addAllotment, removeDryTruck };
}

export default function dryTruckViewPageReducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case SET_DRY_TRUCK_ID:
        draft.dryTruckId = action.dryTruckId;
        break;
      case SET_DRY_TRUCK:
        draft.dryTruck = action.dryTruck;
        break;
      case FETCH_DRY_TRUCK_START:
        draft.isFetchingDryTruck = true;
        break;
      case FETCH_DRY_TRUCK_END:
        draft.isFetchingDryTruck = false;
        break;
      case SET_MODIFYING_DRY_TRUCK:
        draft.modifyingDryTruck = action.modifyingDryTruck;
        break;
      default:
        break;
    }
  });
}
