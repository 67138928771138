import React from 'react';
import StorageBins from '../../common/component/StorageBins';
import Fillorder from './Fillorder';
import DryerBankSection from '../../common/component/DryerBanks';
import {FullPanel, Panel, PanelBody, PanelHeader} from "../../common/component/panel";
import {connect} from "react-redux";
import {AbsoluteFlexStretchedWrapper} from "../../common/component/util";
import FiltersSection from "./FiltersSection";
import {Route, withRouter} from 'react-router-dom';
import CreateFillOrderSection from "./CreateFillOrderSection";
import EditFillOrderSection from "./EditFillOrderSection";
import FilteredBinListSection from "./FilteredBinListSection";
import bindContainerActions from "../../bindContainerActions";
import compose from 'lodash/fp/compose';
import {withMobileSize} from "../../common/component/size";
import GrowerInfoModal from "./GrowerInfoModal";
import FillOrderUpdateModal from "./FillOrderUpdateModal";
import GrowerFillOrdersSection from "./GrowerFillOrdersSection";
import * as fillOrderSelectors from "../selector/fillorders";
import ShowFillOrderSection from "./ShowFillOrderSection";
import GrowerInfoSection from "./GrowerInfoSection";
import Layout from "../../layout/component/Layout";

function FilterHistorySection() {
  return <FullPanel>
      <PanelHeader title="Filter History" divider={true}/>
      <PanelBody>
      </PanelBody>
    </FullPanel>
}

function MobileFillOrderPageBody({fillOrdersList, fillOrdersByGrower}) {
  return <div>
    <GrowerFillOrdersSection fillOrdersByGrower={fillOrdersByGrower} />
    <Route path="/fill-orders/show/:fillOrderId" component={GrowerInfoModal} />
    <Route path="/fill-orders/edit/:fillOrderId" component={FillOrderUpdateModal} />
  </div>
}

function DesktopFillOrderPageBody({filters, fillOrdersByGrower, setFilterValues }) {
  return <div className="row panel-row flex-grow-1">
    <div className="col-12 col-md-2">
      <AbsoluteFlexStretchedWrapper>
        <GrowerFillOrdersSection fillOrdersByGrower={fillOrdersByGrower} />
      </AbsoluteFlexStretchedWrapper>
    </div>
    <div className="col-12 col-md-10 d-flex flex-column">
      <div className="row panel-row">
        <div className="col mb-2"><FiltersSection filters={filters} setFilterValues={setFilterValues}/></div>
        <div className="col col-md-4 mb-2"><FilterHistorySection /></div>
      </div>
      <div className="row flex-grow-1">
        <div className="col col-md-5 d-flex flex-column">
          <Route exact path="/fill-orders" component={CreateFillOrderSection}/>
          <Route path="/fill-orders/edit/:fillOrderId" component={EditFillOrderSection}/>
          <Route path="/fill-orders/show/:fillOrderId" component={ShowFillOrderSection}/>
          <GrowerInfoSection/>
        </div>
        <div className="col col-md-7">
          <AbsoluteFlexStretchedWrapper>
            <FilteredBinListSection/>
          </AbsoluteFlexStretchedWrapper>
        </div>
      </div>
    </div>
  </div>
}

function FillOrders({filters, fillOrdersList, fillOrdersByGrower, setFilterValues, isMobile, setGrowerForStats}) {
  const dryerBankActions = {
    onMouseEnter: ({grower}) => setGrowerForStats(grower.id),
    onMouseLeave: () => setGrowerForStats(null)
  };
  return <Layout>
    <div>
      <div className="row panel-row">
        <div className="col-md-8 mb-2 d-flex flex-column">
          <div className="panel-row mb-2">
            <div style={(!isMobile ? {maxHeight: 215} : {maxHeight: 215, overflowX:"scroll", overflowY:"hidden"})}>
              <StorageBins/>
            </div>
          </div>
          {isMobile && <MobileFillOrderPageBody fillOrdersList={fillOrdersList} fillOrdersByGrower={fillOrdersByGrower} />}
          {!isMobile && <DesktopFillOrderPageBody fillOrdersByGrower={fillOrdersByGrower} filters={filters} setFilterValues={setFilterValues} setGrowerForStats={setGrowerForStats}/>}
        </div>
        <div className="col col-md-4 mb-2">
          <DryerBankSection switchDryerBanks dryerBinActions={dryerBankActions} />
        </div>
      </div>
    </div>
  </Layout>
}

export default compose(
  connect(state => {
    return {
      fillOrdersList: state.fillorders.fillOrdersList,
      fillOrdersByGrower: fillOrderSelectors.fillOrdersByGrower(state),
      filters: state.fillorders.tableFilters
    }
  }, bindContainerActions('fillordersActions@FillOrders')),
  withMobileSize
)(FillOrders);
