import {createDryTruckListPanelExports} from "./dryTruckListPanelFactory";

export const {
  SET_DRY_TRUCKS,
  FETCH_DRY_TRUCKS_START,
  FETCH_DRY_TRUCKS_FINISH,
  dryTruckListPanelActions: recentlyFilledDryTruckListPanelActions,
  dryTruckListPanelSelectors: recentlyFilledDryTruckListPanelSelectors,
  dryTruckListPanelReducer: recentlyFilledDryTruckListPanelReducer,
} = createDryTruckListPanelExports('recentlyFilled', {
  fillState: ['full'],
  'order[fullAt]': 'DESC',
  perPage: 15,
});

export default recentlyFilledDryTruckListPanelReducer;
