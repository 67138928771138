import {FullPanel, Panel, PanelBody, PanelHeader} from "../../common/component/panel";
import React from "react";
import {connect} from 'react-redux';
import {getBinLotsByBin, getBinsById, getSelectedBinId} from "../../common/selector/bin";
import {getGrowersById, getFieldsById, getVarietiesById, getCommonDataIsLoaded} from "../../common/selector/common";
import GrowerColorColumn from "../../common/component/GrowerColorColumn";
import {percentToPounds} from "../../bin/model/bin";
import Weight from "../../common/component/Weight";
import {binIRI} from "../../common/model/iri";

function BinSectionBody({bin, binLot, grower, variety, field}) {
  const poundsFull = percentToPounds(binLot.percentFull);
  return <div className="d-flex">
    <GrowerColorColumn color={grower.color}/>
      <div>
        <b>Bin #{bin.id}</b><br/>
        <b>{grower.name}</b> - <span>{field.name}</span> - <span>{variety.name}</span><br/>
        <b>{binLot.percentFull + "%"} full <Weight pounds={poundsFull} Tag="span" units line/></b> - <span>Air Door: {bin.airDoorOpen ? 'Open' : 'Closed'}</span><br/>
        <b>Hours in bin:</b> - <span>{binLot.hoursInBin.toFixed(2)}</span>
      </div>
  </div>
}

function EmptySectionBody() {
  return <div>Select/hover over a bin to see the details</div>
}

function EmptyWithBinSectionBody({bin}) {
  return <div>
    <b>#{bin.id}</b><br/>
    <div>This bin is currently empty.</div>
  </div>
}

function SelectedBinSection({bin, binLot, grower, field, variety, tightLayout = false}) {
  const Body = (bin ? (binLot ? BinSectionBody : EmptyWithBinSectionBody) : EmptySectionBody);
  return <FullPanel>
    {!tightLayout && <PanelHeader title="Selected Bin" divider={true}/>}
    <PanelBody>
      <Body bin={bin} binLot={binLot} grower={grower} field={field} variety={variety}/>
    </PanelBody>
  </FullPanel>
}

export default connect((state) => {
  if (!getCommonDataIsLoaded(state)) {
    return {};
  }
  const binId = getSelectedBinId(state);
  if (!binId) {
    return {};
  }
  const bin = getBinsById(state)[binIRI(binId)];
  const binLot = getBinLotsByBin(state)[binIRI(binId)];
  if (!binLot) {
    return {bin};
  }

  return {
    bin,
    binLot,
    grower: getGrowersById(state)[binLot.grower],
    variety: getVarietiesById(state)[binLot.variety],
    field: getFieldsById(state)[binLot.field]
  };
})(SelectedBinSection);
