import createActionType from '../createActionType';
import {produce} from 'immer';

export const SET_FORM_VALUE = createActionType('login', 'SET_FORM_VALUE');
export const START_SUBMIT_FORM = createActionType('login', 'START_SUBMIT_FORM');
export const END_SUBMIT_FORM = createActionType('login', 'END_SUBMIT_FORM');
export const FORM_ERROR = createActionType('login', 'FORM_ERROR');
export const SET_LOGGED_IN = createActionType('login', 'SET_LOGGED_IN');

/** @param {ApiClient} apiClient */
export function loginActions(apiClient, eventEmitter) {
  function setFormValue(name, value) {
    return {type: SET_FORM_VALUE, name, value};
  }

  function formError(errorMessage) {
    return {type: FORM_ERROR, errorMessage};
  }

  function startSubmitForm() {
    return {type: START_SUBMIT_FORM};
  }

  function endSubmitForm() {
    return {type: END_SUBMIT_FORM};
  }

  function setLogggedIn(isLoggedIn) {
    return {type: SET_LOGGED_IN, isLoggedIn};
  }

  function init() {
    return (dispatch, getState) => {
      const apiToken = localStorage.getItem('ghmfApiToken');
      const isLoggedIn = Boolean(localStorage.getItem('ghmfApiToken'));
      apiClient.setApiToken(apiToken);
      dispatch(setLogggedIn(isLoggedIn));
      eventEmitter.emit('login.init', isLoggedIn, dispatch, getState);
    }
  }

  function logout() {
    return function(dispatch) {
      localStorage.removeItem('ghmfApiToken');
      apiClient.setApiToken(null);
      dispatch(setLogggedIn(false));
      eventEmitter.emit('login.logout');
    };
  }

  function submitLoginForm() {
    return (dispatch, getState) => {
      const form = getState().login.form;
      dispatch(startSubmitForm());
      return apiClient.login(form.email, form.password).then((resp) => {
        apiClient.setApiToken(resp.apiToken);
        localStorage.setItem('ghmfApiToken', resp.apiToken);
        dispatch(setLogggedIn(true));
        eventEmitter.emit('login.success', dispatch, getState);
        return resp;
      }, (errorMessage) => {
        dispatch(formError(errorMessage));
      }).then(() => {
        dispatch(endSubmitForm());
      });
    }
  }

  return {
    submitLoginForm,
    setFormValue,
    init,
    logout,
  };
}

export function initSubscribers(container, emitter) {
  emitter.addListener('common.init', (dispatch) => {
    dispatch(container.loginActions.init());
  });
  return emitter;
}

const initialState = {
  form: {email: '', password: ''},
  loginError: null,
  isSubmitting: false,
  isLoggedIn: null,
};

export default function reducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case SET_FORM_VALUE:
        draft.form[action.name] = action.value;
        break;
      case START_SUBMIT_FORM:
        draft.loginError = null;
        draft.isSubmitting = true;
        break;
      case END_SUBMIT_FORM:
        draft.isSubmitting = false;
        break;
      case FORM_ERROR:
        draft.loginError = action.errorMessage;
        break;
      case SET_LOGGED_IN:
        draft.isLoggedIn = action.isLoggedIn;
        break;
    }
  })
}
