import { produce } from 'immer';
import createActionType from "../../createActionType";
import {createSelector} from "reselect";
import keyBy from 'lodash/keyBy';
import {dryTruckIRI} from "../../common/model/iri";

export function createDryTruckListPanelExports(name, getDryTrucksParams) {
  const SET_DRY_TRUCKS = createActionType(`dry-trucks/${name}DryTruckListPanel`, 'SET_DRY_TRUCKS');
  const FETCH_DRY_TRUCKS_START = createActionType(`dry-trucks/${name}DryTruckListPanel`, 'FETCH_DRY_TRUCKS_START');
  const FETCH_DRY_TRUCKS_FINISH = createActionType(`dry-trucks/${name}DryTruckListPanel`, 'FETCH_DRY_TRUCKS_FINISH');

  /**
   * @param {ApiClient} apiClient
   */
  function dryTruckListPanelActions(apiClient, pusher) {
    function setDryTrucks(dryTrucks) {
      return {type: SET_DRY_TRUCKS, dryTrucks};
    }

    function fetchDryTrucksStart() {
      return {type: FETCH_DRY_TRUCKS_START};
    }

    function fetchDryTrucksFinish() {
      return {type: FETCH_DRY_TRUCKS_FINISH};
    }

    function loadDryTrucks() {
      return (dispatch, getState) => {
        dispatch(fetchDryTrucksStart());

        return apiClient.getDryTrucks(getDryTrucksParams).then((dryTrucks) => {
          dispatch(setDryTrucks(dryTrucks));
        }, alert).then(() => dispatch(fetchDryTrucksFinish()));
      }
    }

    function initDryTrucks() {
      return (dispatch, getState) => {
        const loadDryTrucksHandler = () => dispatch(loadDryTrucks());

        loadDryTrucksHandler();
        const channel = pusher.subscribe('reload');
        channel.bind('dryTrucks', loadDryTrucksHandler);

        return () => {
          channel.unbind('dryTrucks', loadDryTrucksHandler);
        }
      }
    }

    return {
      initDryTrucks
    };
  }

  const dryTruckListPanelSelectors = (() => {
    const dryTrucks = state => state[`${name}DryTruckListPanel`].dryTrucks;
    const isLoadingDryTrucks = state => state[`${name}DryTruckListPanel`].isLoadingDryTrucks;
    const dryTrucksById = createSelector(dryTrucks, dryTrucks => {
      return !dryTrucks ? null : keyBy(dryTrucks, dryTruck => dryTruckIRI(dryTruck.id));
    });

    return { dryTrucks, isLoadingDryTrucks, dryTrucksById };
  })();

  const initialState = {
    dryTrucks: null,
    isLoadingDryTrucks: true,
  };

  function dryTruckListPanelReducer(state = initialState, action) {
    return produce(state, draft => {
      switch (action.type) {
        case SET_DRY_TRUCKS:
          draft.dryTrucks = action.dryTrucks;
          break;
        case FETCH_DRY_TRUCKS_START:
          draft.isLoadingDryTrucks = true;
          break;
        case FETCH_DRY_TRUCKS_FINISH:
          draft.isLoadingDryTrucks = false;
          break;
      }
    });
  }


  return {
    SET_DRY_TRUCKS,
    FETCH_DRY_TRUCKS_START,
    FETCH_DRY_TRUCKS_FINISH,
    dryTruckListPanelActions,
    dryTruckListPanelSelectors,
    dryTruckListPanelReducer
  }
}
