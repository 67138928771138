import createActionType from '../../createActionType';
import {produce} from 'immer';
import { getCurrentSeason } from '../../home/selector/season';

export const SET_CURRENT_BIN_FILLS = createActionType('wetside/dryerBinModal', 'SET_CURRENT_BIN_FILLS');
export const SET_FETCHING_BIN_FILLS = createActionType('wetside/dryerBinModal', 'SET_FETCHING_BIN_FILLS');
export const SET_FETCHING_PROCESSED_LOADS = createActionType('wetside/dryerBinModal', 'SET_FETCHING_PROCESSED_LOADS');
export const SET_FETCHING_DUMPED_LOADS = createActionType('wetside/dryerBinModal', 'SET_FETCHING_DUMPED_LOADS');
export const SET_PROCESSED_LOADS = createActionType('wetside/dryerBinModal', 'SET_PROCESSED_LOADS');
export const SET_DUMPED_LOADS = createActionType('wetside/dryerBinModal', 'SET_DUMPED_LOADS');
export const CLOSE_MODAL = createActionType('wetside/dryerBinModal', 'CLOSE_MODAL');
export const START_ADDING_BIN_FILL = createActionType('wetside/dryerBinModal', 'START_ADDING_BIN_FILL');
export const CANCEL_ADDING_BIN_FILL = createActionType('wetside/dryerBinModal', 'CANCEL_ADDING_BIN_FILL');
export const ADD_BIN_FILL = createActionType('wetside/dryerBinModal', 'ADD_BIN_FILL');
export const SELECT_DUMPED_LOAD = createActionType('wetside/dryerBinModal', 'SELECT_DUMPED_LOAD');
export const UPDATE_SLIDER_VALUE = createActionType('wetside/dryerBinModal', 'UPDATE_SLIDER_VALUE');
export const EDIT_BIN_FILL_PERCENT_ID = createActionType('wetside/dryerBinModal', 'EDIT_BIN_FILL_PERCENT_ID');

export function dryerBinModalActions(apiClient, wetsideCommonActions) {
  function setCurrentBinFills(binFills) {
    return {type: SET_CURRENT_BIN_FILLS, binFills};
  }
  function setFetchingBinFills(isFetching) {
    return {type: SET_FETCHING_BIN_FILLS, isFetching};
  }
  function setFetchingProcessedLoads(isFetching) {
    return {type: SET_FETCHING_PROCESSED_LOADS, isFetching};
  }
  function setFetchingDumpedLoads(isFetching) {
    return {type: SET_FETCHING_DUMPED_LOADS, isFetching};
  }
  function setProcessedLoads(processedLoads) {
    return {type: SET_PROCESSED_LOADS, processedLoads};
  }
  function setDumpedLoads(dumpedLoads) {
    return {type: SET_DUMPED_LOADS, dumpedLoads};
  }
  function startAddingBinFill() {
    return (dispatch, getState) => {
      dispatch({type: START_ADDING_BIN_FILL});
      dispatch(setFetchingProcessedLoads(true));
      dispatch(setFetchingDumpedLoads(true));

      const season = getCurrentSeason(getState());

      return Promise.all([
        apiClient.getIncomingLoadDumps('processed', {'order[processedAt]': 'DESC', pagination: true, season})[0]
          .then(resp => dispatch(setProcessedLoads(resp))),
        apiClient.getIncomingLoadDumps('created', {'order[createdAt]': 'DESC', season})[0]
          .then(resp => dispatch(setDumpedLoads(resp))),
      ])
        .then(null, alert)
        .then(() => {
          dispatch(setFetchingProcessedLoads(false));
          dispatch(setFetchingDumpedLoads(false));
      });
    };
  }
  function cancelAddingBinFill() {
    return {type: CANCEL_ADDING_BIN_FILL};
  }
  function updateSliderValue(value) {
    return {type: UPDATE_SLIDER_VALUE, value};
  }
  function setEditBinFillId(id) {
    return {type: EDIT_BIN_FILL_PERCENT_ID, id};
  }
  function selectDumpedLoad(loadId) {
    return {type: SELECT_DUMPED_LOAD, loadId};
  }
  function addBinFill() {
    return (dispatch, getState) => {
      const selectedLoadId = getState().wetsideDryerBinModal.selectedLoadId;
      const percentFull = parseInt(getState().wetsideDryerBinModal.sliderPercent);
      const binId = getState().wetside.lockedBinId;

      return apiClient.fillBin(selectedLoadId, binId, percentFull)
        .then(() => dispatch(wetsideCommonActions.loadBinLots()))
        .then(() => dispatch(cancelAddingBinFill()))
        .then(() => dispatch(fetchBinFills(binId)))
        .then(null, alert);
    }
  }

  function editBinFillPercent(binFillId) {
    return (dispatch, getState) => {
      const percentFull = parseInt(getState().wetsideDryerBinModal.sliderPercent);
      const binId = getState().wetside.lockedBinId;
      const binLot = getState().wetsideDryerBinModal.currentBinFills[0].binLot;

      if(binLot.inFillOrder) {
        return alert('Cannot edit bin fill, bin already fill ordered')
      }
      if(binLot.emptied) {
        return alert('Cannot edit bin fill, bin is emptied')
      }

      apiClient.binFillEditPercent(binFillId, percentFull)
        .then(() => dispatch(wetsideCommonActions.loadBinLots()))
        .then(() => dispatch(cancelAddingBinFill()))
        .then(() => dispatch(fetchBinFills(binId)))
        .then(null, alert);
    }
  }

  function fetchBinFills(binId) {
    return (dispatch, getState) => {
      dispatch(setFetchingBinFills(true));
      return apiClient.getCurrentBinFillsByBin(binId)
        .then(binFills => dispatch(setCurrentBinFills(binFills)), alert)
        .then(() => dispatch(setFetchingBinFills(false)));
    }
  }

  function removeBinFill(binFillId) {
    return (dispatch, getState) => {
      const binId = getState().wetside.lockedBinId;
      return apiClient.removeBinFill(binFillId)
        .then(dispatch(wetsideCommonActions.loadBinLots()))
        .then(dispatch(fetchBinFills(binId)));
    }
  }

  function openModal(binId) {
    return fetchBinFills(binId);
  }

  function closeModal(binId) {
    return {type: CLOSE_MODAL, binId};
  }

  return {
    openModal,
    closeModal,
    startAddingBinFill,
    cancelAddingBinFill,
    updateSliderValue,
    addBinFill,
    selectDumpedLoad,
    removeBinFill,
    setEditBinFillId,
    editBinFillPercent,
  };
}

const initialState = {
  selectedLoadId: null,
  sliderPercent: 100,
  isAddingBinFill: false,
  isFetchingBinFills: false,
  isFetchingProcessedLoads: false,
  isFetchingDumpedLoads: false,
  processedLoads: [],
  dumpedLoads: [],
  currentBinFills: null,
  binFillEditId: null
};

export default function dryerBinModalReducer(state = initialState, action) {
  return produce(state, draft => {
    switch(action.type){
      case SET_CURRENT_BIN_FILLS:
        draft.currentBinFills = action.binFills;
        break;
      case SET_FETCHING_BIN_FILLS:
        draft.isFetchingBinFills = action.isFetching;
        break;
      case SET_FETCHING_PROCESSED_LOADS:
        draft.isFetchingProcessedLoads = action.isFetching;
        break;
      case SET_FETCHING_DUMPED_LOADS:
        draft.isFetchingDumpedLoads = action.isFetching;
        break;
      case SET_PROCESSED_LOADS:
        draft.processedLoads = action.processedLoads;
        break;
      case SET_DUMPED_LOADS:
        draft.dumpedLoads = action.dumpedLoads;
        break;
      case SELECT_DUMPED_LOAD:
        draft.selectedLoadId === action.loadId ?  draft.selectedLoadId = null :  draft.selectedLoadId = action.loadId;
        break;
      case CLOSE_MODAL:
        return initialState;
      case START_ADDING_BIN_FILL:
        draft.isAddingBinFill = true;
        break;
      case CANCEL_ADDING_BIN_FILL:
        draft.isAddingBinFill = false;
        break;
      case UPDATE_SLIDER_VALUE:
        draft.sliderPercent = action.value;
        break;
      case EDIT_BIN_FILL_PERCENT_ID:
        draft.binFillEditId= action.id;
    }
  });
}
