import React from 'react';
import { PanelActionsHeader } from "../../common/component/panel";
import {AsyncSelectFilter, createSelectOption, FilterList, SelectFilterFromFilter} from "../../common/component/filter";
import { connect } from 'react-redux';
import bindContainerActions from "../../bindContainerActions";
import IncomingLoadFormModal from "./IncomingLoadFormModal";
import {hullerBottle} from "../../ioc";
import WetLoadList from "./WetLoadList";
import {getFieldFilterByGrower} from '../selector/incoming-loads';
import Layout from "../../layout/component/Layout";

function SectionHeader({children, openAddWetLoadModal, stats}) {
  let subtitle = 'Loads ' + stats.loads + ' -- Tons received ' + stats.net;
  return <PanelActionsHeader title={children} subtitle={subtitle}>
    <button type="button" className="btn btn-success" onClick={() => openAddWetLoadModal()}>Add Incoming Load</button>
  </PanelActionsHeader>
}

function createFilterOnChange(setFilterValue) {
  return filterName => e => {
    setFilterValue(filterName, e);
  }
}

function WetLoadListFilters({tableFilters, fieldFilter, setFilterValue}) {
  const apiClient = hullerBottle.container.apiClient; // TODO: add the hullerBottle to ReactContext and fetch from there.
  const loadWeightCertOptions = (inputValue, callback) => {
    apiClient.getWeightCerts(inputValue).then(weightCerts => {
      weightCerts.unshift({id: "", num: "All Weight Certs"})
      callback(weightCerts.map(wc => createSelectOption(wc.id, wc.num)));
    });
  };
  const loadFreightBillOptions = (inputValue, callback) => {
    apiClient.getFreightBills(inputValue).then(freightBills => {
      freightBills.unshift({id: "", num: "All Freight Bills"})
      callback(freightBills.map(fb => createSelectOption(fb.id, fb.num)));
    });
  };

  const filterOnChange = createFilterOnChange(setFilterValue);

  return <FilterList>
    <div className="col-12 col-lg-6">
      <div className="row">
        <AsyncSelectFilter filter={tableFilters.weightCert} loadOptions={loadWeightCertOptions} onChange={filterOnChange('weightCert')}/>
        <AsyncSelectFilter filter={tableFilters.freightBill} loadOptions={loadFreightBillOptions} onChange={filterOnChange('freightBill')}/>
        <SelectFilterFromFilter filter={tableFilters.grower} onChange={filterOnChange('grower')}/>
        <SelectFilterFromFilter filter={fieldFilter} onChange={filterOnChange('field')}/>
      </div>
    </div>
    <div className="col-12 col-lg-6">
      <div className="row">
        <SelectFilterFromFilter filter={tableFilters.variety} onChange={filterOnChange('variety')}/>
        <SelectFilterFromFilter filter={tableFilters.receiver} onChange={filterOnChange('receiver')}/>
        <SelectFilterFromFilter filter={tableFilters.driver} onChange={filterOnChange('driver')}/>
        <SelectFilterFromFilter filter={tableFilters.truck} onChange={filterOnChange('truck')}/>
        <SelectFilterFromFilter filter={tableFilters.trailerSet} onChange={filterOnChange('trailerSet')}/>
      </div>
    </div>
  </FilterList>
}

function IncomingLoads({showAddWetLoadModal, openAddWetLoadModal, closeAddWetLoadModal, tableFilters, fieldFilter, setFilterValue, stats, selectedWetLoadId}) {
  return <Layout>
    <div className="IncomingLoads">
      <IncomingLoadFormModal isOpen={showAddWetLoadModal} closeAddWetLoadModal={closeAddWetLoadModal} selectedWetLoadId={selectedWetLoadId}/>
      <div className="panel panel-default panel-table">
        <SectionHeader stats={stats} openAddWetLoadModal={openAddWetLoadModal}>Incoming Loads</SectionHeader>
        <WetLoadListFilters tableFilters={tableFilters} fieldFilter={fieldFilter} setFilterValue={setFilterValue} />
        <div className="panel-body">
          <WetLoadList/>
        </div>
      </div>
    </div>
  </Layout>
}

export default connect((state) => {
  return {
    showAddWetLoadModal: state.incomingLoads.showAddWetLoadModal,
    tableFilters: state.incomingLoads.tableFilters,
    fieldFilter: getFieldFilterByGrower(state),
    stats: state.incomingLoads.stats,
    selectedWetLoadId: state.incomingLoads.selectedWetLoadId
  };
}, bindContainerActions("incomingLoadsActions"))(IncomingLoads);
