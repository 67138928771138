import React from 'react';
import {connect} from 'react-redux';
import {LineChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Line, ReferenceLine} from "recharts";
import {isEmpty} from 'lodash'
import {
  getAirDoorEventsByBinLot,
  getBinLotsByBin,
  getBinMoisturesByBin,
  getBinsById,
  getSelectedBinId
} from "../../common/selector/bin";
import { DateTime } from 'luxon';
import {Panel, PanelBody, PanelHeader} from "../../common/component/panel";
import {head, last} from 'lodash'
import {binIRI, binLotIRI} from "../../common/model/iri";

function generateDataPoints(binMoistures) {
  return binMoistures.map((bm) => ({
    createdAt: DateTime.fromISO(bm.createdAt).toMillis(),
    moisture: bm.moisture,
  }));
}

const dateConversion = (date) => DateTime.fromMillis(date).toLocal().toLocaleString(DateTime.DATETIME_SHORT);

const airDoorColors = {
  open: "green",
  closed: "red"
};

function generateSplitColorPoints(bin, binMoistures, airDoorEvents) {
  if (isEmpty(airDoorEvents)) {
    return [[0, bin.airDoorState], [1, bin.airDoorState]];
  }

  const startTime = DateTime.fromISO(head(binMoistures).createdAt).toMillis();
  const endTime = DateTime.fromISO(last(binMoistures).createdAt).toMillis();
  const maxTime = endTime - startTime;

  return airDoorEvents.reduce((percentages, airDoorEvent) => {
    const percentage = (DateTime.fromISO(airDoorEvent.createdAt).toMillis() - startTime) / maxTime;
    if (percentages.length === 0) {
      const initialAirDoorState = airDoorEvent.state === 'closed' ? 'open' : 'closed';
      return [[0, initialAirDoorState], [percentage, initialAirDoorState], [percentage, airDoorEvent.state]];
    }

    const [, lastState] = percentages[percentages.length - 1];
    return percentages.concat([[percentage, lastState], [percentage, airDoorEvent.state]]);
  }, []);
}

function MoistureChart({bin, binMoistures, airDoorEvents}) {
  if (!bin) {
    return <div style={{height: "400px"}}>Select/hover over a bin to view the moisture chart.</div>
  }
  if (!binMoistures) {
    return <div style={{height: "400px"}}>Bin {bin.id} doesn't have any collected moistures at this time.</div>
  }

  const startTime = DateTime.fromISO(head(binMoistures).createdAt).toMillis();
  const endTime = startTime + (1000*60*60*30);

  return <div style={{height: "400px", width: "100%", minWidth: "400px"}}>
    <ResponsiveContainer>
      <LineChart
        data={generateDataPoints(binMoistures)}
        margin={{top: 0, right: 0, left: -30, bottom: 0}}>
        <CartesianGrid strokeDasharray="3 3"/>
        <XAxis
          dataKey="createdAt"
          domain={[startTime, endTime]}
          type="number"
          tickFormatter={dateConversion}
        />
        <YAxis/>
        <Tooltip labelFormatter={dateConversion}/>
        <defs>
          <linearGradient id="splitColor">
            {generateSplitColorPoints(bin, binMoistures, airDoorEvents).map(([percentage, state]) => <stop offset={percentage} stopColor={airDoorColors[state]}/>)}
          </linearGradient>
        </defs>
        {/* fill="url(#splitColor)"*/}
        <Line type="monotone" dataKey="moisture" stroke="url(#splitColor)" strokeWidth={2}  dot={false} isAnimationActive={false}/>
        <ReferenceLine y={6} stroke="red" strokeDasharray="3 3" isAnimationActive={false}/>
      </LineChart>
    </ResponsiveContainer>
  </div>;
}

const MoistureChartContainer = connect((state) => {
  const binId = getSelectedBinId(state);
  if (!binId) {
    return {};
  }
  const binLot = getBinLotsByBin(state)[binIRI(binId)];
  return {
    bin: getBinsById(state)[binIRI(binId)],
    binMoistures: getBinMoisturesByBin(state)[binIRI(binId)],
    airDoorEvents: (binLot ? getAirDoorEventsByBinLot(state)[binLotIRI(binLot.id)] : null) || [],
  }
})(MoistureChart);

export function MoistureChartSection() {
  return <Panel>
    <PanelHeader title="Moisture Chart" divider={true}>
    </PanelHeader>
    <PanelBody>
      <MoistureChartContainer/>
    </PanelBody>
  </Panel>
}

export default MoistureChartContainer;
