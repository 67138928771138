
export class WetLoadAccess {
  constructor(wetLoad) {
    this.wetLoad = wetLoad;
  }

  growerColor() {
    return this.wetLoad.grower.color;
  }

  growerName() {
    return this.wetLoad.grower.name;
  }

  trailerSetName() {
    return this.wetLoad.trailerSet.name;
  }

  fieldName() {
    return this.wetLoad.field.name;
  }

  varietyName() {
    return this.wetLoad.variety.name;
  }

  gross() {
    return this.wetLoad.gross;
  }

  net() {
    return this.wetLoad.net;
  }

  weightCertNum() {
    return this.wetLoad.weightCert.num;
  }

  freightBillNum() {
    return this.wetLoad.freightBill ? this.wetLoad.freightBill.num : '';
  }

  createdAt() {
    return this.wetLoad.createdAt;
  }
}
