import React from 'react';
import {Panel, PanelActionsHeader} from "../../common/component/panel";
import {AsyncSelectFilter, createSelectOption, FilterList, SelectFilterFromFilter} from "../../common/component/filter";
import { connect } from 'react-redux';
import bindContainerActions from "../../bindContainerActions";
import OutgoingLoadFormModal from "./OutgoingLoadFormModal";
import {hullerBottle} from "../../ioc";
import DryLoadList from "./DryLoadList";
import { Label, Input } from 'reactstrap';
import Layout from "../../layout/component/Layout";
import CreateDryTruckPanel from "./CreateDryTruckPanel";
import StorageBins from '../../common/component/StorageBins';
import UnprocessedDryTruckLotsPanel from "./UnprocessedDryTruckLotsPanel";

function SectionHeader({children, openAddDryLoadModal, stats}) {
  let subtitle = 'Loads ' + stats.loads + ' -- Tons shipped ' + 0 + ' -- Wet/Dry ratio ' + 0 + ' -- Receiver Net Lbs ' + 0;
  return <PanelActionsHeader title={children} subtitle={subtitle}>
    <button type="button" className="btn btn-success" onClick={() => openAddDryLoadModal()}>Add Outgoing Load</button>
  </PanelActionsHeader>
}

function createFilterOnChange(setFilterValue) {
  return filterName => e => {
    setFilterValue(filterName, e);
  }
}

function DryLoadListFilters({tableFilters, setFilterValue, setSearchValue}) {
  const apiClient = hullerBottle.container.apiClient;
  const loadWeightCertOptions = (inputValue, callback) => {
    apiClient.getWeightCerts(inputValue).then(weightCerts => {
      weightCerts.unshift({id: "", num: "All Weight Certs"})
      callback(weightCerts.map(wc => createSelectOption(wc.id, wc.num)));
    });
  };
  const loadManifestOptions = (inputValue, callback) => {
    apiClient.getManifests(inputValue).then(manifests => {
      manifests.unshift({id: "", num: "All Manifests"})
      callback(manifests.map(fb => createSelectOption(fb.id, fb.num)));
    });
  };

  const filterOnChange = createFilterOnChange(setFilterValue);
  const searchOnChange = (e, name) => setSearchValue(name, e.target.value)

  return <FilterList>
    <div className="col-12 col-lg-6">
      <div className="row">
        <AsyncSelectFilter filter={tableFilters.weightCert} loadOptions={loadWeightCertOptions} onChange={filterOnChange('weightCert')}/>
        <AsyncSelectFilter filter={tableFilters.manifest} loadOptions={loadManifestOptions} onChange={filterOnChange('manifest')}/>
        <SelectFilterFromFilter filter={tableFilters.grower} onChange={filterOnChange('grower')}/>
        <SelectFilterFromFilter filter={tableFilters.field} onChange={filterOnChange('field')}/>
        <SelectFilterFromFilter filter={tableFilters.variety} onChange={filterOnChange('variety')}/>
      </div>
    </div>
    <div className="col-12 col-lg-6">
      <div className="row">
      <div className="col-sm p-2 table-filters" style={{maxWidth: 150}}>
          <Label className="table-filter-title" style={{marginBottom: '9px'}}>Driver</Label>
          <Input className="css-vj8t7z" style={{height: 'unset', padding: '0 1rem'}} type="text" name="driver" placeholder="Search Drivers" onChange={e => searchOnChange(e, 'driver')}/>
        </div>
        <div className="col-sm p-2 table-filters" style={{maxWidth: 150}}>
          <Label className="table-filter-title" style={{marginBottom: '9px'}}>Trucks</Label>
          <Input className="css-vj8t7z" style={{height: 'unset', padding: '0 1rem'}} type="text" name="truck" placeholder="Search Trucks" onChange={e => searchOnChange(e, 'truck')}/>
        </div>
        <div className="col-sm p-2 table-filters" style={{maxWidth: 150}}>
          <Label className="table-filter-title" style={{marginBottom: '9px'}}>Trailers</Label>
          <Input className="css-vj8t7z" style={{height: 'unset', padding: '0 1rem'}} type="text" name="trailers" placeholder="Search Trailers" onChange={e => searchOnChange(e, 'trailers')}/>
        </div>
        <div className="col-sm p-2 table-filters" style={{maxWidth: 150}}>
          <Label className="table-filter-title" style={{marginBottom: '9px'}}>Carrier</Label>
          <Input className="css-vj8t7z" style={{height: 'unset', padding: '0 1rem'}} type="text" name="darrier" placeholder="Search Carriers" onChange={e => searchOnChange(e, 'carrier')}/>
        </div>
        <SelectFilterFromFilter filter={tableFilters.receiver} onChange={filterOnChange('receiver')}/>
      </div>
    </div>
  </FilterList>
}

function OutgoingLoads({ showAddDryLoadModal, openAddDryLoadModal, closeAddDryLoadModal, tableFilters, setFilterValue, stats, selectedDryLoadId, setSearchValue }) {
  return <Layout>
    <div className="OutgoingLoads">
      <div className="row panel-row mb-2">
        <div className="col">
          <UnprocessedDryTruckLotsPanel/>
        </div>
        <div className="col-2">
          <CreateDryTruckPanel/>
        </div>
      </div>
      <OutgoingLoadFormModal isOpen={showAddDryLoadModal} closeAddDryLoadModal={closeAddDryLoadModal} selectedDryLoadId={selectedDryLoadId}/>
      <div className="panel panel-default panel-table">
        <SectionHeader stats={stats} openAddDryLoadModal={openAddDryLoadModal}>Outgoing Loads</SectionHeader>
        <DryLoadListFilters tableFilters={tableFilters} setFilterValue={setFilterValue} setSearchValue={setSearchValue}/>
        <div className="panel-body">
          <DryLoadList/>
        </div>
      </div>
    </div>
  </Layout>
}

export default connect((state) => {
  return {
    showAddDryLoadModal: state.outgoingLoads.showAddDryLoadModal,
    tableFilters: state.outgoingLoads.tableFilters,
    stats: state.outgoingLoads.stats,
    selectedDryLoadId: state.outgoingLoads.selectedDryLoadId
  };
}, bindContainerActions("outgoingLoadsActions"))(OutgoingLoads);
