import {createSelector} from "reselect";
import {getGrowersById} from "./common";
import {createWetLoadsStats} from "./bin";
import {isEmpty, head} from 'lodash';
import keyBy from 'lodash/fp/keyBy';
import {growerIRI} from "../model/iri";

export const getWetLoads = state => state.wetside.wetLoads || [];
export const getDumpedLoads = state => state.wetside.dumpedLoads || [];
export const getCurrentBinFills = state => state.wetsideDryerBinModal.currentBinFills  || [];

export const getGroupedLoads = createSelector(
  getWetLoads,
  getGrowersById,
  (wetLoads, growersById) => {
    if (isEmpty(growersById)) {
      return [];
    }

    let wetLoadMap = wetLoads.reduce((acc, wetLoad) => {
      const growerId = wetLoad.grower.id;
      if(growerId in acc){
        acc[growerId].push(wetLoad);
      } else {
        acc[growerId] = [wetLoad];
      }
      return acc;
    }, {});

    return Object.entries(wetLoadMap)
      .map( ([growerId, wetLoads]) => {
        const grower = growersById[growerIRI(growerId)];
        const stats = createWetLoadsStats(wetLoads);
        return {grower, stats};
      })
  }
);

export const getWetLoadsByEntityId = createSelector(
  getWetLoads,
  keyBy(wl => wl.id)
);

export const getPrioritizedLoadIds = createSelector(
  state => state.wetside.prioritizedLoadIds,
  getWetLoadsByEntityId,
  (prioritizedLoadIds, wetLoadsById) => {
    return {
      highPriority: prioritizedLoadIds.highPriority.filter(id => id in wetLoadsById),
      lowPriority: prioritizedLoadIds.lowPriority.filter(id => id in wetLoadsById),
    }
  }
);

export const getDefaultScheduledWetLoads = createSelector(
  getWetLoads,
  getPrioritizedLoadIds,
  (wetLoads, prioritizedLoadIds) => wetLoads.filter(wl => !prioritizedLoadIds.highPriority.includes(wl.id) && !prioritizedLoadIds.lowPriority.includes(wl.id))
);

export const getNextWetLoad = createSelector(
  getDefaultScheduledWetLoads,
  getPrioritizedLoadIds,
  getWetLoadsByEntityId,
  (scheduledWetLoads, prioritizedLoadIds, wetLoadsById) => {
    if (prioritizedLoadIds.highPriority.length) {
      return wetLoadsById[prioritizedLoadIds.highPriority[0]];
    } else if (scheduledWetLoads.length) {
      return head(scheduledWetLoads);
    } else if (prioritizedLoadIds.lowPriority.length) {
      return wetLoadsById[prioritizedLoadIds.lowPriority[0]];
    } else {
      return null;
    }
  }
);

export const canReloadPrioritizedLoadIds = state => state.wetside.reloadPrioritizedLoadCancelTimeoutId === null;

export const getDumpedLoadsByPit = createSelector(
  getDumpedLoads,
  dumpedLoads => dumpedLoads.reduce((acc, dumpedLoad) => {
    const pitId = dumpedLoad.pit;
    if(pitId in acc) {
      acc[pitId].push(dumpedLoad);
    } else {
      acc[pitId] = [dumpedLoad];
    }
    return acc
  }, {})
);
