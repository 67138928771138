import React from 'react';
import {Panel, PanelBody, PanelHeader} from "../../common/component/panel";
import {connect} from "react-redux";
import size from 'lodash/size';
import {getGrowersById, getGrowerStatsById} from "../../common/selector/common";
import GrowerColumnWrapper from "../../common/component/GrowerColumnWrapper";
import Weight from "../../common/component/Weight";
import {growerIRI} from "../../common/model/iri";


function EmptyGrowerStatsBody() {
  return <p className="text-center">Hover over any grower fill orders, filtered bin list, or dryer bin to view grower stats.</p>
}

function TitleValueRow({title, children}) {
  return <div className="d-flex justify-content-between grower-info-section-title-value-row">
    <div><b>{title}:</b></div>
    <div className="text-right">{children}</div>
  </div>
}

function GrowerStatsBody({growerStats, grower}) {
  return <div>
    <GrowerColumnWrapper color={grower.color} grow>
      <b>{growerStats.growerName} - #{growerStats.growerId}</b>
      <TitleValueRow title="Total Shipped"><Weight pounds={growerStats.totalPoundsShipped} units line /></TitleValueRow>
      <TitleValueRow title="Total Pounds"><Weight pounds={growerStats.totalPounds} units line /></TitleValueRow>
      <TitleValueRow title="In Dryer Bins"><Weight pounds={growerStats.totalPoundsInDryerBins} units line /></TitleValueRow>
      <TitleValueRow title="In Storage"><Weight pounds={growerStats.totalPoundsInStorageBins} units line /></TitleValueRow>
      <TitleValueRow title="Not in Fill Orders"><Weight pounds={growerStats.totalPoundsNotInFillOrders} units line /></TitleValueRow>
      <TitleValueRow title="Receiver Accounts">{growerStats.allReceivers.join(', ')}</TitleValueRow>
      <TitleValueRow title="Fields">{growerStats.allFields.join(', ')}</TitleValueRow>
      <TitleValueRow title="Varieties">{growerStats.allVarieties.join(', ')}</TitleValueRow>
    </GrowerColumnWrapper>
  </div>
}

function GrowerInfoSection({growerStats, grower}) {
  const isEmpty = !growerStats || !grower;
  return <Panel className="panel panel-default mb-0">
    <PanelHeader title="Grower Info" divider />
    <PanelBody>
      {isEmpty && <EmptyGrowerStatsBody/>}
      {!isEmpty && <GrowerStatsBody growerStats={growerStats} grower={grower} />}
    </PanelBody>
  </Panel>
}
export default connect((state) => {
  const growerId = state.common.selectedGrowerIdForStats;
  const growerStatsById = getGrowerStatsById(state);
  const growersById = getGrowersById(state);

  const growerStats = growerId && size(growerStatsById) ? growerStatsById[growerIRI(growerId)] : null;
  const grower = growerId && size(growersById) ? growersById[growerIRI(growerId)] : null;
  return {growerStats, grower};
})(GrowerInfoSection);
