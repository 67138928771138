import React from "react";
import Select from 'react-select';
import AsyncSelect from 'react-select/lib/Async';

export function createFilterState(options = [], selected = null) {
  return {selected, options};
}

export function createSelectOption(value, label, meta = undefined) {
  return {value, label, meta};
}

/** Creates a filter struct */
export function createFilter(key, name, options = []) {
  return {key, name, selectedValue: null, options: options};
}

export function createMultiFilter(key, name, selectedValues = [], options = []) {
  return {key, name, selectedValues, options};
}

export function createFilterMap(filters) {
  return filters.reduce((acc, filter) => {
    acc[filter.key] = filter;
    return acc;
  }, {});
}

export function SelectFilter({title, onInputChange, onChange, value, options}) {
  return <div className="col-12 col-sm table-filters p-2">
    <span className="table-filter-title mb-1">{title}</span>
    <div className="filter-container pb-1">
      <Select classNamePrefix="react-select" options={options} onInputChange={onInputChange} onChange={onChange} value={value} placeholder="Select a Filter"/>
    </div>
  </div>
}

export function MultiSelectFilter({title, onInputChange, onChange, value, options, defaultValue}) {
  return <div className="col-12 col-sm table-filters p-2">
    <span className="table-filter-title mb-1">{title}</span>
    <div className="filter-container pb-1">
      <Select classNamePrefix="react-select" isMulti options={options} defaultValue={defaultValue} onInputChange={onInputChange} onChange={onChange} value={value} placeholder="Select a Filter"/>
    </div>
  </div>
}


export function AsyncSelectFilter({filter, onInputChange, onChange, loadOptions}) {
  return <div className="col-12 col-sm table-filters p-2">
    <span className="table-filter-title mb-1">{filter.name}</span>
    <div className="filter-container pb-1">
      <AsyncSelect cacheOptions={true} loadOptions={loadOptions} onInputChange={onInputChange} onChange={onChange} inputLabel={filter.selectedValue != null ? filter.selectedValue.label: null} placeholder="Select a Filter"/>
    </div>
  </div>
}

export function SelectFilterFromFilter({filter, onChange}) {
  return <SelectFilter title={filter.name} options={filter.options} onChange={onChange} value={filter.selectedValue}/>
}

export function FilterList({children}) {
  return <div className="row table-filters-container px-3">
    {children}
  </div>
}
