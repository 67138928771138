import {Panel, PanelBody, PanelHeader} from "../../common/component/panel";
import React from "react";
import {connect} from 'react-redux';
import {getPits} from "../../common/selector/common";
import GrowerColumnWrapper from "../../common/component/GrowerColumnWrapper";
import {getDumpedLoadsByPit} from "../../common/selector/wetside";
import bindContainerActions from "../../bindContainerActions";
import {pitIRI} from "../../common/model/iri";

function PitInfoBody({dumpedLoad, isDumpingLoad, removeDumpedLoad}) {
  return (
    <div >
      {dumpedLoad.map((load, idx) => {
        return (
          <GrowerColumnWrapper color={load.incomingLoad.grower.color} key={idx}>
            <div>
              <div>
                <b>{load.incomingLoad.grower.name}</b>
                {load.state === 'created' && <React.Fragment>
                  &nbsp; - <RemoveDumpedLoadButton dumpedLoad={load} isDumpingLoad={isDumpingLoad} removeDumpedLoad={removeDumpedLoad}/>
                </React.Fragment>}
              </div>
              <div>
                <span>{load.incomingLoad.field.name}</span> - <span>{load.incomingLoad.variety.name}</span>
              </div>
              <div><b>WC#: {load.incomingLoad.weightCert.num}</b> - <DumpedLoadStatus dumpedLoad={load}/></div>
              <div>{load.incomingLoad.trailerSet.name}</div>
            </div>
          </GrowerColumnWrapper>
        );
      })}
    </div>
  );
}

function RemoveDumpedLoadButton({dumpedLoad, removeDumpedLoad, isDumpingLoad}) {
  return <button type="button" className="btn btn-danger p-1" style={{verticalAlign: "0px"}} onClick={removeDumpedLoad.bind(null, dumpedLoad.id)} disabled={isDumpingLoad}>Remove</button>
}

function DumpedLoadStatus({dumpedLoad}) {
  return dumpedLoad.state === 'created'
    ? <span className="text-success">New</span>
    : <span className="text-danger">Used</span>
}

function PitInfo({pit, dumpedLoad, removeDumpedLoad, isDumpingLoad}) {
  return <div >
    <h6><b>{pit.name}</b></h6>
    {dumpedLoad && <PitInfoBody dumpedLoad={dumpedLoad} removeDumpedLoad={removeDumpedLoad} isDumpingLoad={isDumpingLoad}/>}
  </div>
}

const PitInfoContainer = connect((state, {pit}) => {
  return {
    dumpedLoad: getDumpedLoadsByPit(state)[pitIRI(pit.id)],
    isDumpingLoad: state.wetside.isDumpingLoad,
  }
}, bindContainerActions('wetsideActions'))(PitInfo);

function PitsSection({pits}) {
  return <Panel style={{marginBottom: 0}}>
    <PanelHeader title="Pits" divider={true}/>
    <PanelBody >
      <div className="row" style={{overflowY: 'scroll', height: '115px'}}>
        {pits.map(p => <div className="col" key={p.id} ><PitInfoContainer pit={p}/></div>)}
      </div>
    </PanelBody>
  </Panel>
}

export default connect((state) => {
  return {
    pits: getPits(state)
  }
})(PitsSection);
