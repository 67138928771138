import {SET_BIN_LOTS} from "./wetside";

export function wetsideCommonActions(apiClient) {
  function setBinLots(binLots) {
    return {type: SET_BIN_LOTS, binLots};
  }

  function getTableFilterSelectedValue(getState, filterName) {
    if(window.location.href.includes("wetside")) return [];
    const tempRes = getState().fillorders.tableFilters[filterName].selectedValues;
    let res = [];
    tempRes.forEach(obj => {
      if(!obj.value) {
        res.push(false);
        return;
      };
      res.push(obj.value);
    });
    for(let i = 0; i < res.length; i++){
      if(!res[i]){
        res = [];
        break;
      }
    }
    return res;
  }

  function loadBinLots() {
    return (dispatch, getState) => {
      const params = {
        growerId: getTableFilterSelectedValue(getState, 'grower'),
        fieldId: getTableFilterSelectedValue(getState, 'field'),
        varietyId: getTableFilterSelectedValue(getState, 'variety'),
      };
      return apiClient.getCurrentBinLots(params)[0].then(resp => dispatch(setBinLots(resp)), () => {});
    }
  }

  return {loadBinLots, setBinLots};
}
