import {createSelector} from 'reselect';
import {getBinLots, getBinsById} from "../../common/selector/bin";
import {SortKeys} from "../redux";
import {getFieldsById, getGrowersById, getVarietiesById} from "../../common/selector/common";
import {isEmpty} from "lodash"
import {fieldIRI, growerIRI, varietyIRI} from "../../common/model/iri";

function compareAsc(a, b) {
  return a < b ? -1 : a > b ? 1 : 0;
}
function compareDesc(b, a) {
  return a < b ? -1 : a > b ? 1 : 0;
}

function byEntityField(entityField) {
  return (entitiesById, binLot, field) => {
    return entitiesById[binLot[entityField]][field];
  }
}

const byBinField = byEntityField('bin');
const byGrowerField = byEntityField('grower');
const byFieldField = byEntityField('field');
const byVarietyField = byEntityField('variety');

function createSortComparator(binsById, growersById, fieldsById, varietiesById, key, isAsc) {
  const cmp = isAsc ? compareAsc : compareDesc;
  switch (key) {
    case SortKeys.Grower:
      return (a, b) => cmp(byGrowerField(growersById, a, 'name'), byGrowerField(growersById, b, 'name'));
    case SortKeys.Field:
      return (a, b) => cmp(byFieldField(fieldsById, a, 'name'), byFieldField(fieldsById, b, 'name'));
    case SortKeys.Variety:
      return (a, b) => cmp(byVarietyField(varietiesById, a, 'name'), byVarietyField(varietiesById, b, 'name'));
    case SortKeys.Pounds:
      return (a, b) => cmp(a.poundsFull, b.poundsFull);
    case SortKeys.Moisture:
      return (a, b) => cmp(byBinField(binsById, a, 'binMoisture'), byBinField(binsById, b, 'binMoisture'));
    case SortKeys.Time:
      return (a, b) => cmp(a.hoursInBin, b.hoursInBin);
    case SortKeys.Bin:
    default:
      return (a, b) => cmp(byBinField(binsById, a, 'id'), byBinField(binsById, b, 'id'));
  }
}

export const getSortBy = state => state.fillorders.sortBy;
export const getSelectedGrowers = state => state.fillorders.tableFilters.grower.selectedValues;
export const getSelectedFields = state => state.fillorders.tableFilters.field.selectedValues;
export const getSelectedVarieties = state => state.fillorders.tableFilters.variety.selectedValues;

function compareBinLotFieldWithSelectedEntities(binLot, selectedEntities, fieldName, createIRI) {
  return selectedEntities.length === 0 || selectedEntities.some(({value}) => {
    return binLot[fieldName] === createIRI(value);
  })
}

export const getFilteredBinList = createSelector(
  getBinLots,
  getBinsById,
  getGrowersById,
  getFieldsById,
  getVarietiesById,
  getSortBy,
  getSelectedGrowers,
  getSelectedFields,
  getSelectedVarieties,
  (binLots, binsById, growersById, fieldsById, varietiesById, sortBy, selectedGrowers, selectedFields, selectedVarieties) => {
    if (isEmpty(binLots)|| isEmpty(binsById)) {
      return [];
    }

    return binLots
      .filter((binLot) => {
        return compareBinLotFieldWithSelectedEntities(binLot, selectedGrowers, 'grower', growerIRI)
          && compareBinLotFieldWithSelectedEntities(binLot, selectedFields, 'field', fieldIRI)
          && compareBinLotFieldWithSelectedEntities(binLot, selectedVarieties, 'variety', varietyIRI);
      })
      .sort(createSortComparator(
        binsById,
        growersById,
        fieldsById,
        varietiesById,
        sortBy.key,
        sortBy.asc
      ));
  }
);
