import React from 'react';

export default function TrailerChoiceSection({splitTrailerType, onChange}) {
  return <div className="form-group text-right" style={{alignSelf: "flex-end"}}>
    <label className="custom-control custom-radio custom-control-inline">
      <input type="radio" onChange={onChange} checked={splitTrailerType === 'front'} name="splitTrailerType" value="front" className="custom-control-input"/>
      <span className="custom-control-label">Front Only</span>
    </label>
    <label className="custom-control custom-radio custom-control-inline">
      <input type="radio" onChange={onChange} checked={splitTrailerType === 'back'} name="splitTrailerType" value="back" className="custom-control-input"/>
      <span className="custom-control-label">Back Only</span>
    </label>
    <label className="custom-control custom-radio custom-control-inline">
      <input type="radio" onChange={onChange} checked={splitTrailerType === 'none'} value="none" name="splitTrailerType" className="custom-control-input"/>
      <span className="custom-control-label">Includes Both</span>
    </label>
  </div>
};
