import React from 'react';
import {connect} from 'react-redux';
import {PaginationFooter, PaginationFooterWrapper, Table} from "../../common/component/table";
import bindContainerActions from "../../bindContainerActions";
import Spinner from "../../common/component/Spinner";
import GrowerColumnWrapper from "../../common/component/GrowerColumnWrapper";
import {DateTime} from 'luxon';

function wetLoadItemToRow(removeWetLoad, wetLoad) {
  function removeWetLoadHandler(e) {
    e.stopPropagation();
    removeWetLoad(wetLoad.id, wetLoad.weightCert.num, wetLoad.grower.name);
  }

  const isDumped = wetLoad.state === 'dumped';
  return [
    wetLoad,
    DateTime.fromISO(wetLoad.createdAt).toFormat('yy-MM-dd HH:mm'),
    wetLoad.weightCert.num,
    wetLoad.freightBill ? wetLoad.freightBill.num: '',
    <GrowerColumnWrapper color={wetLoad.grower.color}>{wetLoad.grower.name}</GrowerColumnWrapper>,
    wetLoad.field.name,
    wetLoad.variety.name,
    wetLoad.designatedReceiver ? wetLoad.designatedReceiver.name : wetLoad.designatedReceiver,
    wetLoad.gross,
    wetLoad.tare,
    wetLoad.net,
    wetLoad.driver.name,
    wetLoad.truck.name,
    wetLoad.splitTrailerType === 'none' ? wetLoad.trailerSetName : <span className="text-danger">{wetLoad.trailerSetName}</span>,
    <div className="text-center"><button type="button" className="btn px-3 btn-danger" disabled={isDumped} onClick={isDumped ? null : removeWetLoadHandler}>X</button></div>
  ];
}

function WetLoadList({isLoading, wetLoadResults, changePage, removeWetLoad, openAddWetLoadModal}) {
  return <div>
    {isLoading && <Spinner enabled={isLoading} className="my-2"/>}
    <Table openFormModal={openAddWetLoadModal}
      header={["id", "Date", "Weight Cert", "Freight Bill", "Grower", "Field", "Variety", "Receiver", "Gross (lbs)", "Tare (lbs)", "Net (lbs)", "Driver", "Truck", "Trailer", ""]}
      rows={(wetLoadResults.items || []).map(wetLoadItemToRow.bind(null, removeWetLoad)) || []}/>
    <PaginationFooterWrapper>
      {wetLoadResults.totalItems > 0 && <PaginationFooter total={wetLoadResults.totalItems} page={wetLoadResults.currentPage} perPage={wetLoadResults.perPage} goToPage={changePage}/>}
    </PaginationFooterWrapper>
  </div>
}

export default connect((state) => {
  return {
    wetLoadResults: state.incomingLoads.wetLoadResults,
    isLoading: state.incomingLoads.isLoading,
  };
}, bindContainerActions('incomingLoadsActions'))(WetLoadList);
