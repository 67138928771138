import React from "react";
import Nav from '../../nav/component/Nav';
import {MobileContext} from "../../common/context";
import {connect} from "react-redux";
import bindContainerActions from "../../bindContainerActions";

function Layout({isLoggedIn, logout, toggleMobileView, tightHeader = false, children}) {
  return <MobileContext.Consumer>{mobileContext => (
    <div className="App">
      <Nav isLoggedIn={isLoggedIn} logoutAction={logout} toggleMobileView={toggleMobileView} forceMobile={mobileContext.forceMobile} tightLayout={tightHeader} />
      <div className="mai-wrapper">
        <div className="main-content container-fluid p-2">{children}</div>
      </div>
    </div>
  )}</MobileContext.Consumer>
}

export default connect((state) => {
  return {isLoggedIn: state.login.isLoggedIn};
}, bindContainerActions('login@appActions'))(Layout);
