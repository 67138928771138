import React, {Component} from 'react';
import {connect} from 'react-redux';
import bindContainerActions from "../../bindContainerActions";

function Login({submitLoginForm, setFormValue, isSubmitting, loginError, form: {email, password}, onLoginSuccess}) {
  function handleSubmit(e) {
    e.preventDefault();
    submitLoginForm(onLoginSuccess);
  }
  function handleInputChange(e) {
    setFormValue(e.target.name, e.target.value);
  }

  return <div>
    <div className="container">
      <h1 className="my-4 text-center">GHMF Huller Login</h1>
      <form id="form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label for="email">Email</label>
          <input className="form-control" name="email" placeholder="Email" value={email} onChange={handleInputChange}/>
        </div>
        <div className="form-group">
          <label for="psw">Password</label>
          <input type="password" className="form-control" name="password" placeholder="Password" value={password} onChange={handleInputChange}/>
        </div>
        <div className="form-group">
          <button id="submit" type="submit" className="btn btn-primary" disabled={isSubmitting}>Login</button>
          {loginError && <div className="text-danger">{loginError}</div>}
        </div>
      </form>
    </div>
  </div>;
}

export default connect((state) => {
  return {...state.login};
}, bindContainerActions('loginActions'))(Login);
