import React, {useState} from 'react';
import {FullPanel, PanelBody, PanelHeader} from "../../common/component/panel";
import {growerIRI} from "../../common/model/iri";
import GrowerColumnWrapper from "../../common/component/GrowerColumnWrapper";
import {Collapse} from 'reactstrap';
import partition from 'lodash/fp/partition';
import size from 'lodash/size';
import uniq from 'lodash/uniq';
import {connect} from "react-redux";
import {getFieldsById, getReceiversById, getStorageBinsById, getVarietiesById} from "../../common/selector/common";
import {InlineSpinner} from "../../common/component/Spinner";
import {getBinsById} from "../../common/selector/bin";
import {compose} from "redux";
import withRouter from "react-router/es/withRouter";
import bindContainerActions from "../../bindContainerActions";

function calcPoundsRemainingFromFillOrders(fillOrders) {
  return fillOrders
    .filter(fo => fo.state !== "complete")
    .reduce((acc, fo) => {
      return acc + fo.items
        .filter(foi => foi.state !== "complete")
        .reduce((acc, foi) => acc + foi.poundsRequested, 0)
    }, 0)
}

class FillOrderAccess {
  constructor(fillOrder, varietiesById, fieldsById, storageBinsById, binsById, receiversById) {
    this.fillOrder = fillOrder;
    this.varietisById = varietiesById;
    this.fieldsById = fieldsById;
    this.storageBinsById = storageBinsById;
    this.binsById = binsById;
    this.receiversById = receiversById;
  }

  varietyName() {
    return this.varietisById[this.fillOrder.variety].name;
  }

  fieldNames() {
    return uniq(this.fillOrder.items.map((foi) => {
      return this.fieldsById[foi.binLot.field].name;
    })).join(', ');
  }

  storageBinName() {
    return this.storageBinsById[this.fillOrder.storageBin].name;
  }

  receiverName() {
    return this.fillOrder.receiver ? this.receiversById[this.fillOrder.receiver].name : null;
  }

  binNames() {
    return uniq(this.fillOrder.items.map(foi => {
      return this.binsById[foi.binLot.bin].binNum;
    })).join(', ');
  }

  totalPoundsRequested() {
    return this.fillOrder.items
      .reduce((acc, foi) => acc + foi.poundsRequested, 0)
  }

  totalPoundsRemaining() {
    return this.fillOrder.items
      .filter(foi => foi.state !== "complete")
      .reduce((acc, foi) => acc + foi.poundsRequested, 0)
  }

  isComplete() {
    return this.fillOrder.state === "complete";
  }
}

const splitFillOrdersByState = partition(fo => fo.state === 'complete');

function TitleValueRow({title, children}) {
  return <div className="d-flex justify-content-between">
    <div>{title}:</div>
    <div className="text-right">{children}</div>
  </div>
}

function FillOrderState({fillOrderAccess}) {
  return <div className={fillOrderAccess.isComplete() ? "text-danger" : "text-success"}>{fillOrderAccess.isComplete() ? "Complete" : "Created"}</div>
}

const FillOrderInfo = compose(
  connect((state) => {
    return {
      fieldsById: getFieldsById(state),
      varietiesById: getVarietiesById(state),
      storageBinsById: getStorageBinsById(state),
      receiversById: getReceiversById(state),
      binsById: getBinsById(state),
    };
  }, bindContainerActions('fillordersActions')),
  withRouter
)(({fillOrder, fieldsById, varietiesById, storageBinsById, binsById, receiversById, history}) => {
  function clickHandler(e) {
    e.stopPropagation();
    history.push('/fill-orders/show/'+fillOrder.id);
  }

  const foAccess = new FillOrderAccess(fillOrder, varietiesById, fieldsById, storageBinsById, binsById, receiversById);
  const notAllDataIsLoaded = !size(fieldsById) || !size(varietiesById) || !size(storageBinsById) || !size(binsById) || !size(receiversById)
  return <InlineSpinner enabled={notAllDataIsLoaded}>{() => (
    <div className="mt-2" onClick={clickHandler} style={{cursor: "pointer"}}>
      <div className="d-flex justify-content-between">
        <div><b>#{fillOrder.id}</b></div>
        <FillOrderState fillOrderAccess={foAccess} />
      </div>
      <TitleValueRow title="Fields">{foAccess.fieldNames()}</TitleValueRow>
      <TitleValueRow title="Variety">{foAccess.varietyName()}</TitleValueRow>
      <TitleValueRow title="Storage Bin">{foAccess.storageBinName()}</TitleValueRow>
      <TitleValueRow title="Receiver">{foAccess.receiverName() || 'N/A'}</TitleValueRow>
      <TitleValueRow title="Lbs Requested">{foAccess.totalPoundsRequested()}</TitleValueRow>
      <TitleValueRow title="Lbs Remaining">{foAccess.totalPoundsRemaining()}</TitleValueRow>
      <TitleValueRow title="Bins">{foAccess.binNames()}</TitleValueRow>
    </div>
  )}</InlineSpinner>
});

function GrowerFillOrderInfo({grower, fillOrders, setSelectedGrowerId, isSelected, setGrowerForStats}) {
  const [completedFillOrders, createdFillOrders] = splitFillOrdersByState(fillOrders);
  function onMouseLeave() {
    setSelectedGrowerId(null);
    setGrowerForStats(null);
  }

  return <GrowerColumnWrapper
    color={grower.color}
    grow
    style={{cursor: "pointer"}}
    onClick={() => setSelectedGrowerId(grower.id)}
    onMouseLeave={onMouseLeave}
    onMouseEnter={() => setGrowerForStats(grower.id)}>
    <div><b>{grower.name}</b></div>
    <div className="d-flex justify-content-between">
      <div>Lbs Remaining:</div>
      <div>{calcPoundsRemainingFromFillOrders(fillOrders)}</div>
    </div>
    <Collapse isOpen={isSelected}>
      <hr/>
      <div className="px-2">
        {createdFillOrders.concat(completedFillOrders).map(fo => <FillOrderInfo key={fo.id} fillOrder={fo} />)}
      </div>
    </Collapse>
  </GrowerColumnWrapper>;
}

function filterAndSortGrowersByFillOrdersState(growers, fillOrdersByGrower) {
  return growers.filter(grower => {
    const fillOrders = fillOrdersByGrower[growerIRI(grower.id)];
    return fillOrders && fillOrders.length > 0;
  }).sort((growerA, growerB) => {
    const hasPoundsA = calcPoundsRemainingFromFillOrders(fillOrdersByGrower[growerIRI(growerA.id)]) > 0;
    const hasPoundsB = calcPoundsRemainingFromFillOrders(fillOrdersByGrower[growerIRI(growerB.id)]) > 0;

    if (hasPoundsA && !hasPoundsB) {
      return -1;
    } else if (!hasPoundsA && hasPoundsB) {
      return 1
    } else {
      return growerA.name.localeCompare(growerB.name);
    }
  });
}

function GrowerFillOrdersSection({growers, fillOrdersByGrower, setGrowerForStats}) {
  const [selectedGrowerId, setSelectedGrowerId] = useState(null);
  function toggleSelectedGrowerId(growerId) {
    if (growerId === selectedGrowerId) {
      setSelectedGrowerId(null);
      setGrowerForStats(null);
    } else {
      setSelectedGrowerId(growerId);
      setGrowerForStats(growerId);
    }
  }

  return <FullPanel style={{overflowY: 'scroll'}}>
    <PanelHeader title="Grower FillOrders" divider />
    <PanelBody>
      {filterAndSortGrowersByFillOrdersState(growers, fillOrdersByGrower).map(grower => {
        return <GrowerFillOrderInfo
          key={grower.id}
          grower={grower}
          fillOrders={fillOrdersByGrower[growerIRI(grower.id)]}
          setSelectedGrowerId={toggleSelectedGrowerId}
          isSelected={grower.id === selectedGrowerId}
          setGrowerForStats={setGrowerForStats} />
      })}
    </PanelBody>
  </FullPanel>
}

export default connect((state) => {
  return {growers: state.common.growers || []}
}, bindContainerActions('commonActions@setGrowerForStats'))(GrowerFillOrdersSection);
