import React from 'react';
import isFunction from 'lodash/isFunction';

export default function Spinner({enabled, className = "", color = "#333", width = 70, children = null}) {
  if (!enabled) {
    return children;
  }

  return <div className={"spinner " + className} style={{width: width + "px", position: "fixed", left: "50%", top: "50%", marginLeft: (-1 * width) + "px"}}>
    <div className="bounce1" style={{color}}></div>
    <div className="bounce2" style={{color}}></div>
    <div className="bounce3" style={{color}}></div>
  </div>
}

export function InlineSpinner({enabled, className = "", color = "#333", width = 70, children = null}) {
  if (!enabled) {
    return isFunction(children) ? children() : children;
  }

  return <div className={"spinner mt-1 " + className} style={{width: width + "px"}}>
    <div className="bounce1" style={{color}}></div>
    <div className="bounce2" style={{color}}></div>
    <div className="bounce3" style={{color}}></div>
  </div>
}
