import { produce } from 'immer';
import createActionType from "../../createActionType";
import {reloadForever} from "../../util/time";
import capitalize from 'lodash/capitalize';
import { DateTime } from 'luxon';
import range from 'lodash/range';

export const SET_WEBSOCKET_STATS = createActionType('home-page/homePage', 'SET_WEBSOCKET_STATS');
export const SET_CURRENT_SEASON = createActionType('home-page/homePage', 'SET_CURRENT_SEASON');

export function homePageActions(pusher) {
  function setWebsocketStats(stats) {
    return { type: SET_WEBSOCKET_STATS, stats };
  }

  function setCurrentSeason(season) {
    return (dispatch, getState) => {
      dispatch({ type: SET_CURRENT_SEASON, season: season });
      const reloadChannel = pusher.channels.find('reload');
      reloadChannel.emit('incomingLoads');
      reloadChannel.emit('fillOrder');
      reloadChannel.emit('outgoingLoads');
      reloadChannel.emit('growerStats');
      reloadChannel.emit('prioritizedLoads');
      reloadChannel.emit('dryTrucks');
    };
  }

  function pingConnection() {
    return (dispatch, getState) => {
      const conn = pusher.connection;

      if (conn.state === 'disconnected') {
        conn.connect();
      } else if (conn.state === 'failed') {
        // if failed, pusher won't automatically retry, so we need to reset some pusher state and invoke connect for pusher
        // to retry
        conn.strategy = conn.options.getStrategy({
          key: conn.key,
          timeline: conn.timeline,
          useTLS: conn.usingTLS,
        });
        conn.connect();
      }

      dispatch(setWebsocketStats({
        connectionStatus: capitalize(conn.state),
        refreshedAt: DateTime.local().toLocaleString(DateTime.TIME_WITH_SECONDS),
      }));
    }
  }

  function pingConnectionForever() {
    return (dispatch, getState) => {
      reloadForever(1)(() => {
        dispatch(pingConnection());
        return Promise.resolve();
      });
    }
  }

  return { pingConnectionForever, setCurrentSeason };
}

export function homePageSubscriber(container, emitter) {
  emitter.addListener('common.init', (dispatch, getState) => {
    dispatch(container.homePageActions.pingConnectionForever());
  });
  return emitter;
}

const initialState = {
  websocketStatus: {
    connectionStatus: null,
    refreshedAt: null,
  },
  season: {
    currentSeason: DateTime.local().year,
    allSeasons: range(DateTime.local().year, 2018),
  }
};

export default function homePageReducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case SET_WEBSOCKET_STATS:
        draft.websocketStatus = action.stats;
        break;
      case SET_CURRENT_SEASON:
        draft.season.currentSeason = action.season;
        break;
    }
  });
}
