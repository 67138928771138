import React, {Component} from 'react';
import PropTypes from 'prop-types';

export default class FormRowWrapper extends Component {
    render() {
        return <div className={"form-group " + this.props.outerClass}>
            <label className="form-label" htmlFor={this.props.id}> {this.props.label}</label>
            {this.props.children}
        </div>;
    }
}

FormRowWrapper.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    children: PropTypes.any.isRequired,
    outerClass: PropTypes.string,
};

FormRowWrapper.defaultProps = {
    id: null,
    label: null,
    children: null,
    outerClass: ""
};