import React from 'react';
import { FullPanel, Panel, PanelBody, PanelHeader } from "./panel";
import {connect} from 'react-redux';
import {getStorageBins} from "../selector/common";
import {InlineSpinner} from "./Spinner";
import {isEmpty} from 'lodash';
import GrowerColumnWrapper from './GrowerColumnWrapper';
import {getDryStorage} from "../../fillorders/selector/fillorders";
import {keys, get, sortedUniq} from "lodash";
import {percentToPounds} from "../../bin/model/bin";
import Weight from "../../common/component/Weight";

function StorageBins({storageBins, dryStorage, tightLayout = false, storageBinHandlers = {} }) {
  return <InlineSpinner enabled={isEmpty(storageBins) || isEmpty(dryStorage)}>
    <div className="row">
      {storageBins.map(sb => {
        sb.item = null;
        if (!dryStorage.items) {
          return <div className="col" key={sb.id}><StorageBin name={sb.name}/></div>
        } else {
          dryStorage.items.forEach(dryStorageItem => {
            if (dryStorageItem.storageBin.id === sb.id){
              sb.item = dryStorageItem;
            }
          });

          const storageBinOnClick = !storageBinHandlers.click ? null : () => storageBinHandlers.click(sb.id);
          return <div className="col" key={sb.id} onClick={storageBinOnClick}>
            <StorageBin name={sb.name} storageLot={sb.item} tightLayout={tightLayout} />
          </div>
        }
      })}
    </div>
  </InlineSpinner>
}

export function StorageBin({name, storageLot = null, tightLayout = false}) {
  return <div>
    <h6 className={tightLayout ? "mb-0" : "mb-1"}>{name}</h6>
    {storageLot && <StorageBinLot storageLot={storageLot} />}
    {!storageLot && <React.Fragment>&nbsp;</React.Fragment>}
  </div>
}

export function StorageBinLot({ storageLot }) {
  let fillOrderNumbers = (storageLot.storageFills || []).map(fill => fill.fillOrderNum);
  return <GrowerColumnWrapper color={storageLot.grower.color}>
    <div style={{fontSize: ".9rem"}}>
      <div><b>{storageLot.grower.name}</b></div>
      <div>{storageLot.field.name} / {storageLot.variety.name}</div>
      <div>FO.# {sortedUniq(fillOrderNumbers).join(", ")}</div>
      <Weight pounds={percentToPounds(storageLot.netPercent)} units line/>
    </div>
  </GrowerColumnWrapper>
}

const StorageBinsContainer = connect((state) => {
  return {
    storageBins: getStorageBins(state),
    dryStorage: getDryStorage(state)
  };
})(StorageBins);

export { StorageBinsContainer as StorageBins };

function StorageBinsPanel({ tightLayout = false }) {
  return <FullPanel style={{minWidth:"480px"}}>
    {!tightLayout && <PanelHeader title="Overhead Bins" divider={true}/>}
    <PanelBody>
      <StorageBinsContainer tightLayout={tightLayout} />
    </PanelBody>
  </FullPanel>;
}

export default StorageBinsPanel;
