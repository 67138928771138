import React, { useEffect, useState } from 'react';
import Layout from "../../layout/component/Layout";
import {TablePanel, TablePanelBody, PanelHeader, Panel, PanelBody} from "../../common/component/panel";
import { FilterList } from "../../common/component/filter";
import {connect} from "react-redux";
import bindContainerActions from "../../bindContainerActions";
import {InlineSpinner} from "../../common/component/Spinner";
import {PaginationFooter, PaginationFooterWrapper, Table} from "../../common/component/table";
import {DryTruckAccess} from "../model/dryTruck";
import { DateTime } from 'luxon';
import compose from 'lodash/fp/compose';
import map from 'lodash/map';
import keyBy from 'lodash/keyBy';
import size from 'lodash/size';
import {Switch, Route, withRouter} from "react-router";
import {unfilledDryTruckListPanelSelectors} from "../redux/unfilledDryTruckListPanel";
import {dryTruckViewPageSelectors} from "../redux/dryTruckViewPage";
import {dryTruckIRI, storageBinIRI} from "../../common/model/iri";
import {Link} from "react-router-dom";
import {getGrowersById, getStorageBinsById, getVarietiesById} from "../../common/selector/common";
import {getCurrentStorageLotsById} from "../../fillorders/selector/fillorders";
import {StorageBinLot} from "../../common/component/StorageBins";
import classNames from 'classnames';
import GrowerColumnWrapper from "../../common/component/GrowerColumnWrapper";
import Weight from "../../common/component/Weight";

function dryTruckToTableRow(row) {
  const dryTruck = new DryTruckAccess(row);

  return [
    dryTruck.id(),
    dryTruck.truckNumber(),
    dryTruck.companyName(),
    dryTruck.trailerTypeForDisplay(),
    dryTruck.fillStateForDisplay(),
    dryTruck.processStateFromDisplay(),
    dryTruck.createdAt().toLocaleString(DateTime.DATETIME_SHORT),
  ];
}

function DryTruckListPanel({ title, dryTrucks, isLoadingDryTrucks, initDryTrucks, history }) {
  useEffect(() => {
    return initDryTrucks();
  }, []);

  return <TablePanel>
    <PanelHeader title={title}>
      <InlineSpinner enabled={isLoadingDryTrucks}/>
    </PanelHeader>
    <FilterList />
    <TablePanelBody className={{"px-3": false, "py-2": false}}>
      <Table
        openFormModal={(id) => { history.push('/dry-trucks/' + id); }}
        header={['Id', 'Truck #', 'Company Name', 'Trailer Type', 'Fill State', 'Process State', 'Created At']}
        rows={(dryTrucks || []).map(dryTruckToTableRow)} />
    </TablePanelBody>
  </TablePanel>
}

const UnfilledDryTruckListPanel = compose(
  withRouter,
  connect((state) => {
    return state.unfilledDryTruckListPanel;
  }, bindContainerActions('unfilledDryTruckListPanelActions'))
)((props) => {
  return <DryTruckListPanel title="Active Dry Trucks" {...props} />
});

const RecentlyFilledDryTruckListPanel = compose(
  withRouter,
  connect((state) => {
    return state.recentlyFilledDryTruckListPanel;
  }, bindContainerActions('recentlyFilledDryTruckListPanelActions'))
)((props) => {
  return <DryTruckListPanel title="Recently Filled Dry Trucks" {...props} />
});

function DryTruckListPage() {
  return <div className="row panel-row">
    <div className="col">
      <UnfilledDryTruckListPanel />
    </div>
    <div className="col">
      <RecentlyFilledDryTruckListPanel />
    </div>
  </div>
}

function TrailerCompartmentCheckbox({ label, value, selectedCompartments, disabledCompartments, disabled, onCompartmentsChanged }) {
  const checked = selectedCompartments.includes(value) || disabledCompartments.includes(value);
  const isDisabled = disabled || disabledCompartments.includes(value);
  const onChange = (e) => {
    if (isDisabled) {
      return;
    }

    if (!checked) {
      onCompartmentsChanged(selectedCompartments.concat([value]));
    } else {
      onCompartmentsChanged(selectedCompartments.filter(c => c !== value));
    }
  };

  return <div className="form-check form-check-inline" onClick={onChange}>
    <input className="form-check-input" type="checkbox" value={value} checked={checked} readOnly disabled={isDisabled} />
    <label className="form-check-label">{label}</label>
  </div>
}

const SplitTrailersCompartments = ['front', 'back'];
const MultiTrailersCompartments = ['compartment_1', 'compartment_2', 'compartment_3', 'compartment_4'];

export function DryTruckTrailerCompartments({ disabledCompartments = [], selectedCompartments = [], onCompartmentsChanged = () => {} }) {
  const allCompartments = disabledCompartments.concat(selectedCompartments);
  const disableSplitTrailer = allCompartments.some(v => MultiTrailersCompartments.includes(v));
  const disableMultiTrailer = allCompartments.some(v => SplitTrailersCompartments.includes(v));

  return <div>
    <b>Select Trailer Compartments:</b><br/>
    <div className="d-flex flex-row px-2">
      <div>
        <b>Split Trailer</b>
        <div>
          <TrailerCompartmentCheckbox label="Front" value="front" disabledCompartments={disabledCompartments} selectedCompartments={selectedCompartments} onCompartmentsChanged={onCompartmentsChanged} disabled={disableSplitTrailer} />
          <TrailerCompartmentCheckbox label="Back" value="back" disabledCompartments={disabledCompartments} selectedCompartments={selectedCompartments} onCompartmentsChanged={onCompartmentsChanged} disabled={disableSplitTrailer} />
        </div>
      </div>
      <div className="ml-2">
        <b>Multi Compartment Trailer</b>
        <div>
          <TrailerCompartmentCheckbox label="1" value="compartment_1" disabledCompartments={disabledCompartments} selectedCompartments={selectedCompartments} onCompartmentsChanged={onCompartmentsChanged} disabled={disableMultiTrailer} />
          <TrailerCompartmentCheckbox label="2" value="compartment_2" disabledCompartments={disabledCompartments} selectedCompartments={selectedCompartments} onCompartmentsChanged={onCompartmentsChanged} disabled={disableMultiTrailer} />
          <TrailerCompartmentCheckbox label="3" value="compartment_3" disabledCompartments={disabledCompartments} selectedCompartments={selectedCompartments} onCompartmentsChanged={onCompartmentsChanged} disabled={disableMultiTrailer} />
          <TrailerCompartmentCheckbox label="4" value="compartment_4" disabledCompartments={disabledCompartments} selectedCompartments={selectedCompartments} onCompartmentsChanged={onCompartmentsChanged} disabled={disableMultiTrailer} />
        </div>
      </div>
    </div>
  </div>
}

const AllottedStorageBinType = {
  EmptiedStorageBin: "emptiedStorageBin",
  PartiallyEmptiedStorageBin: "partiallyEmptiedStorageBin",
};

function DryTruckAllottedStorageBins({ storageBinsById, storageLotsById, allottedStorageBins, setAllottedStorageBins }) {
  const nonEmptyStorageBins = map(storageLotsById, (storageLot) => {
    return storageBinsById[storageLot.storageBin['@id']];
  });
  const storageLotsByStorageBin = keyBy(storageLotsById, (storageLot) => storageLot.storageBin['@id']);
  const allottedStorageBinsByStorageBin = keyBy(allottedStorageBins, lot => storageBinIRI(lot.storageBinId));

  return <div>
    <b>Choose Storage Bins:</b><br/>
    <div className="row px-2">
      {nonEmptyStorageBins.length === 0 && <p>All storage bins are empty. Fill a storage bin to see options here.</p>}
      {nonEmptyStorageBins.map((storageBin) => {
        const storageLot = storageLotsByStorageBin[storageBinIRI(storageBin.id)];
        const allottedStorageBin = allottedStorageBinsByStorageBin[storageBinIRI(storageBin.id)];
        const isSelected = !!allottedStorageBin;

        function toggleAllottedStorageBin() {
          if (isSelected) {
            setAllottedStorageBins(allottedStorageBins.filter(lot => lot.storageBinId !== storageBin.id));
          } else {
            setAllottedStorageBins(allottedStorageBins.concat([{
              storageBinId: storageBin.id,
              type: AllottedStorageBinType.EmptiedStorageBin,
            }]));
          }
        }

        function toggleAllottedStorageBinType(e) {
          e.stopPropagation();
          const newType = allottedStorageBin.type === AllottedStorageBinType.EmptiedStorageBin
            ? AllottedStorageBinType.PartiallyEmptiedStorageBin
            : AllottedStorageBinType.EmptiedStorageBin;
          const { remainingPounds, ...allottedStorageBinWithoutRemainingPounds } = allottedStorageBin;
          const newAllottedStorageBin = newType === AllottedStorageBinType.EmptiedStorageBin
            ? { ...allottedStorageBinWithoutRemainingPounds, type: newType }
            : { ...allottedStorageBinWithoutRemainingPounds, type: newType, remainingPounds: '' };

          setAllottedStorageBins(allottedStorageBins
            .filter(lot => lot.storageBinId !== storageBin.id)
            .concat([newAllottedStorageBin]))
        }

        function setRemainingPounds(e) {
          e.stopPropagation();
          const remainingPounds = e.currentTarget.value;
          const newAllottedStorageBin = { ...allottedStorageBin, remainingPounds };
          setAllottedStorageBins(allottedStorageBins
            .filter(lot => lot.storageBinId !== storageBin.id)
            .concat([newAllottedStorageBin]))
        }

        return <div className={classNames("col-12 col-md  m-md-1", isSelected && " p-2 border border-success")} key={storageLot.id} style={{cursor: "pointer"}} onClick={toggleAllottedStorageBin}>
          <h6 className="mb-0">{storageBin.name}</h6>
          <StorageBinLot storageLot={storageLot} />
          {isSelected && <React.Fragment>
            <div className="form-check">
              <input className="form-check-input" type="checkbox" checked={allottedStorageBin.type === AllottedStorageBinType.EmptiedStorageBin} onChange={toggleAllottedStorageBinType} />
              <label className="form-check-label">Is Storage Bin Empty?</label>
            </div>
            {allottedStorageBin.type === AllottedStorageBinType.PartiallyEmptiedStorageBin && <div>
              <input type="text" className="form-control" value={allottedStorageBin.remainingPounds} placeholder="Remaining Lbs" onChange={setRemainingPounds} onClick={e => e.stopPropagation()}/>
            </div>}
          </React.Fragment>}
        </div>
      })}
    </div>
  </div>
}

const DryTruckAllottedStorageBinsContainer = connect((state) => {
  return {
    storageBinsById: getStorageBinsById(state),
    storageLotsById: getCurrentStorageLotsById(state)
  };
})(DryTruckAllottedStorageBins);

function AddDryTruckAllotmentForm({
  dryTruckAccess,
  trailerCompartments,
  setTrailerCompartments,
  isLastAllotment,
  setIsLastAllotment,
  allottedStorageBins,
  setAllottedStorageBins
}) {
  return <div>
    <hr/>
    <h5>Add Allotment</h5>
    <div className="pl-2">
      <div className="row">
        <div className="col-12 col-md">
          <DryTruckAllottedStorageBinsContainer allottedStorageBins={allottedStorageBins} setAllottedStorageBins={setAllottedStorageBins} />
        </div>
        <div className="col-12 col-md">
          <DryTruckTrailerCompartments
            disabledCompartments={dryTruckAccess.trailerCompartments()}
            selectedCompartments={trailerCompartments}
            onCompartmentsChanged={setTrailerCompartments} />
        </div>
      </div>
      <div className="form-check mt-4">
        <input className="form-check-input" type="checkbox" checked={isLastAllotment} onChange={(e) => setIsLastAllotment(e.currentTarget.checked)} />
        <label className="form-check-label">Is Last Allotment?</label>
      </div>
    </div>
  </div>
}

function DryTruckAllotmentsList({ dryTruck, growersById, varietiesById, modifyingDryTruck, removeAllotment }) {
  if (!dryTruck.lots.length) {
    return <div>No allotments have been created.</div>
  }

  const commonDataIsLoaded = size(growersById) && size(varietiesById);

  return <div className="pt-2">
    <b>Allotments:</b>
    <InlineSpinner enabled={!commonDataIsLoaded}>{() => (
      <ul>
        {dryTruck.lots.map(lot => (
          <li key={lot.id}>
            <div>Lot #{lot.id} - {lot.trailerCompartments.join(' / ')} - {lot.processed ? 'Processed' : 'Not Processed'}</div>
            <div className="mt-2 d-flex">{lot.emptiedStorageLots.map(emptiedLot => {
              const grower = growersById[emptiedLot.storageLot.grower];
              const variety = varietiesById[emptiedLot.storageLot.variety];
              return <GrowerColumnWrapper color={grower.color} className="mr-2">
                <div>
                  <b>{grower.name}</b> - {variety.name} - <Weight pounds={emptiedLot.netPercentRemoved * 70} units line Tag='span' /> removed - FO.# {(emptiedLot.fillOrderNums || []).join(',')}
                </div>
              </GrowerColumnWrapper>
            })}</div>
            <div className="mt-2"><button className="btn btn-danger btn-xs py-1" disabled={modifyingDryTruck} onClick={removeAllotment.bind(null, lot.id)}>Remove</button></div>
          </li>
        ))}
      </ul>
    )}</InlineSpinner>
  </div>
}

const DryTruckAllotmentsListContainer = connect((state) => {
  return {
    growersById: getGrowersById(state),
    varietiesById: getVarietiesById(state)
  };
})(DryTruckAllotmentsList);

function DryTruckViewPanel({ dryTruckId, dryTruck, isFetchingDryTruck, modifyingDryTruck, removeAllotment, addAllotment, removeDryTruck, goToLocation }) {
  const dryTruckAccess = dryTruck && new DryTruckAccess(dryTruck);
  const canAddAllotment = dryTruck && !dryTruck.full;
  const canRemoveDryTruck = !!dryTruck;

  const [allottedStorageBins, setAllottedStorageBins] = useState([]);
  const [trailerCompartments, setTrailerCompartments] = useState([]);
  const [isLastAllotment, setIsLastAllotment] = useState(false);
  const allotmentFormIsReady = allottedStorageBins.length && trailerCompartments.length;

  function addAllotmentClickHandler() {
    addAllotment({allottedStorageBins, trailerCompartments, isLastAllotment})
      .then(respWasSuccessful => {
        if (!respWasSuccessful) {
          return;
        }

        // reset form on success
        setAllottedStorageBins([]);
        setTrailerCompartments([]);
        setIsLastAllotment(false);
      });
  }

  return <Panel>
    <PanelHeader title={"Dry Truck #" + dryTruckId} divider>
      <InlineSpinner enabled={isFetchingDryTruck} />
    </PanelHeader>
    <PanelBody>
      {!dryTruck && <div>No Dry Truck</div>}
      {dryTruck && <React.Fragment>
        <b>{dryTruckAccess.fullTruckTitle()}</b>
        <div>Trailer Type: {dryTruckAccess.trailerTypeForDisplay()}</div>
        <DryTruckAllotmentsListContainer dryTruck={dryTruck} modifyingDryTruck={modifyingDryTruck} removeAllotment={removeAllotment} />
        {!canAddAllotment && dryTruck.full && <p>Dry Truck is Full</p>}
        {canAddAllotment && <AddDryTruckAllotmentForm
          dryTruckAccess={dryTruckAccess}
          trailerCompartments={trailerCompartments}
          setTrailerCompartments={setTrailerCompartments}
          isLastAllotment={isLastAllotment}
          setIsLastAllotment={setIsLastAllotment}
          allottedStorageBins={allottedStorageBins}
          setAllottedStorageBins={setAllottedStorageBins} />}
      </React.Fragment>}
      <div className="mt-4">
        <Link to="/dry-trucks" className="btn btn-link btn-info">Back</Link>
        {canAddAllotment && <button className="btn btn-success ml-2" disabled={!allotmentFormIsReady || modifyingDryTruck} onClick={addAllotmentClickHandler}>Add Allotment</button>}
        {canRemoveDryTruck && <button className="btn btn-danger ml-2" disabled={!canRemoveDryTruck} onClick={removeDryTruck.bind(null, goToLocation)}>Remove Dry Truck</button>}
      </div>
    </PanelBody>
  </Panel>
}

function DryTruckViewPage({ match, history, dryTruck, isFetchingDryTruck, modifyingDryTruck, initDryTruckViewPage, removeAllotment, addAllotment, removeDryTruck }) {
  useEffect(() => {
    return initDryTruckViewPage(parseInt(match.params.id));
  }, [match.params.id]);

  return <div className="row panel-row">
    <div className="col">
      <DryTruckViewPanel
        dryTruckId={match.params.id}
        dryTruck={dryTruck}
        isFetchingDryTruck={isFetchingDryTruck}
        modifyingDryTruck={modifyingDryTruck}
        removeAllotment={removeAllotment}
        addAllotment={addAllotment}
        removeDryTruck={removeDryTruck}
        goToLocation={(path) => history.push(path)} />
    </div>
  </div>
}

const DryTruckViewPageContainer = connect((state, ownProps) => {
  const dryTruck = dryTruckViewPageSelectors.dryTruck(state)
    || (unfilledDryTruckListPanelSelectors.dryTrucksById(state) || {})[dryTruckIRI(ownProps.match.params.id)];

  return {
    dryTruck,
    isFetchingDryTruck: dryTruckViewPageSelectors.isFetchingDryTruck(state),
    modifyingDryTruck: dryTruckViewPageSelectors.modifyingDryTruck(state),
  };
}, bindContainerActions('dryTruckViewPageActions'))(DryTruckViewPage);

function DryTruckPage() {
  return <Layout>
    <Switch>
      <Route path="/dry-trucks/:id" component={DryTruckViewPageContainer}/>
      <Route component={DryTruckListPage} />
    </Switch>
  </Layout>
}

export default DryTruckPage;
