import React, { Component } from 'react';
import {BrowserRouter, Route} from 'react-router-dom';
import IncomingLoads from './incoming-loads/component/IncomingLoads';
import OutgoingLoads from './outgoing-loads/component/OutgoingLoads';
import Login from './login/component/Login';
import Wetside from './wetside/component/Wetside';
import FillOrders from './fillorders/component/Fillorders';
import {connect} from 'react-redux';
import bindContainerActions from './bindContainerActions';
import {MobileContext} from './common/context';
import HomePage from "./home/component/HomePage";
import {Switch, withRouter} from "react-router";
import DryTruckPage from "./dry-trucks/component/DryTruckPage";

function App({isLoggedIn, mobileContext}) {
  const appBody = isLoggedIn ? <Switch>
    <Route exact path="/" component={HomePage}/>
    <Route path="/incoming-loads" component={IncomingLoads}/>
    <Route path="/outgoing-loads" component={OutgoingLoads}/>
    <Route path="/wetside" component={Wetside}/>
    <Route path="/fill-orders" component={FillOrders}/>
    <Route path="/dry-trucks" component={DryTruckPage}/>
  </Switch> : <Login />;

  return <MobileContext.Provider value={mobileContext}>{appBody}</MobileContext.Provider>
}

function withBrowerRouter(App) {
  return (props) => <BrowserRouter><App {...props}/></BrowserRouter>
}

export default connect((state) => {
  return {isLoggedIn: state.login.isLoggedIn, mobileContext: state.common.mobileContext};
}, bindContainerActions('login@appActions'))(withBrowerRouter(App));
