import {FlexFullPanel, FlexPanelBody, Panel, PanelBody, PanelHeader} from "../../common/component/panel";
import LoadInYard from "../../common/component/LoadInYard";
import React from "react";
import {connect} from 'react-redux';
import {getGroupedLoads, getNextWetLoad} from "../../common/selector/wetside";
import GroupedLoad, {GroupedLoadThinBody} from "./GroupedLoad";

function LoadsInYardSubSection({name, children, outerProps = {}}) {
  return <div {...outerProps}>
    <h6 className={"mb-1"}><b>{name}</b></h6>
    {children}
  </div>
}

function LoadsInYardSection({openDetailedLoads, nextWetLoad, groupedWetLoads}) {
  return <FlexFullPanel style={{cursor: "pointer"}} onClick={openDetailedLoads}>
    <PanelHeader title="Loads In Yard" divider={true}/>
    <FlexPanelBody className="overflow-auto">
      <LoadsInYardSubSection name="Next Load to Run" outerProps={{className: "pb-1"}}>
        <LoadInYard wetLoad={nextWetLoad}/>
      </LoadsInYardSubSection>
      <LoadsInYardSubSection name="Grouped Loads" outerProps={{className: "d-flex flex-column pb-1"}}>
        <div style={{overflow: "auto"}}>
          {groupedWetLoads.map((gl) => <GroupedLoad key={gl.grower.id} groupedLoad={gl} Body={GroupedLoadThinBody} />)}
        </div>
      </LoadsInYardSubSection>
    </FlexPanelBody>
  </FlexFullPanel>
}

export default connect((state) => {
  return {
    nextWetLoad: getNextWetLoad(state),
    groupedWetLoads: getGroupedLoads(state),
  };
})(LoadsInYardSection);
