import {MultiSelectFilter, SelectFilter} from "../../common/component/filter";
import React from "react";
import {FullPanel, Panel, PanelBody, PanelHeader} from "../../common/component/panel";
import bindContainerActions from "../../bindContainerActions";
import {SortKeys} from "../redux";
import {connect} from 'react-redux';
import classNames from 'classnames';
import {filterFieldFilterByGrowerIds} from '../../incoming-loads/selector/incoming-loads';

function createFilterOnChange(setFilterValues) {
  return filterName => e => {
    setFilterValues(filterName, e);
  }
}

function SelectFilterSection({filters, setFilterValues}) {
  const filterOnChange = createFilterOnChange(setFilterValues);
  const growerIds = filters.grower.selectedValues.map(x => x.value).filter(x => x);
  return <div className="row">
    <div className="col">
      <MultiSelectFilter title="Growers" options={filters.grower.options} value={filters.grower.selectedValues} onChange={filterOnChange('grower')}/>
    </div>
    <div className="col">
      <MultiSelectFilter title="Fields" options={filterFieldFilterByGrowerIds(filters.field, growerIds).options} onChange={filterOnChange('field')}/>
    </div>
    <div className="col">
      <MultiSelectFilter title="Varieties" options={filters.variety.options} onChange={filterOnChange('variety')}/>
    </div>
  </div>;
}

function SortButton({sortKey, title, sortBy, changeSort}) {
  return <button
    type="button"
    className={classNames("btn", "btn-xs", sortBy.key === sortKey ? (sortBy.asc ? "btn-success" : "btn-danger") : "btn-default")}
    onClick={changeSort.bind(null, sortKey)}>
    {title}
  </button>
}

function SortingSection({sortBy, changeSort}) {
  return <div className="d-flex justify-content-start align-items-center">
    <div style={{marginBottom: 1}} className="mr-1"><b>Sort By:</b></div>
    <SortButton sortKey={SortKeys.Receiver} title="Receivers" sortBy={sortBy} changeSort={changeSort}/>
    <SortButton sortKey={SortKeys.Grower} title="Growers" sortBy={sortBy} changeSort={changeSort}/>
    <SortButton sortKey={SortKeys.Variety} title="Varieties" sortBy={sortBy} changeSort={changeSort}/>
    <SortButton sortKey={SortKeys.Field} title="Fields" sortBy={sortBy} changeSort={changeSort}/>
    <SortButton sortKey={SortKeys.Moisture} title="Moisture" sortBy={sortBy} changeSort={changeSort}/>
    <SortButton sortKey={SortKeys.Pounds} title="Pounds" sortBy={sortBy} changeSort={changeSort}/>
    <SortButton sortKey={SortKeys.Time} title="Time" sortBy={sortBy} changeSort={changeSort}/>
    <SortButton sortKey={SortKeys.Bin} title="Bin" sortBy={sortBy} changeSort={changeSort}/>
  </div>
}

export function FiltersSection({filters, setFilterValues, sortBy, changeSort}) {
  return <FullPanel>
    <PanelHeader title="Filter & Sort Bins" divider/>
    <PanelBody>
      <SelectFilterSection filters={filters} setFilterValues={setFilterValues}/>
      <SortingSection sortBy={sortBy} changeSort={changeSort}/>
    </PanelBody>
  </FullPanel>
}

export default connect((state) => {
  return {
    sortBy: state.fillorders.sortBy,
  };
}, bindContainerActions('fillordersActions'))(FiltersSection);
