import React from "react";
import range from "lodash/fp/range";
import compose from "lodash/fp/compose";
import filter from "lodash/fp/filter";
import reduce from "lodash/fp/reduce";

export function Table({header, rows, openFormModal}) {
  return <div className="table-responsive">
    <table className="table table-bordered table-striped table-hover table-sm">
      <thead><TableRow items={header} isHead={true}/></thead>
      <tbody>{rows.map((r, i) => <TableRow openFormModal={() => openFormModal(r[0])} key={i} items={r}/>)}</tbody>
    </table>
  </div>
}

function Th({children, ...props}) {
  if(children.includes("Weight Cert")) {
    return <th {...props}><b>{children}</b></th>
  }
  return <th {...props}>{children}</th>
}
function Td({children, ...props}) {
  return <td {...props}>{children}</td>
}

export function TableRow({items, isHead = false, openFormModal}) {
  const Item = isHead ? Th : Td;
  return <tr onClick={openFormModal}>
    {items.map((item, i) => (i > 0 && <Item key={i}>{i===2 && typeof item !== "string" ? <b>{item}</b> : item}</Item>))}
  </tr>
}

export function PaginationLink({children, isNext = false, isPrevious = false, onClick, disabled = false, active = false}) {
  const eventOnClick = onClick ? (e) => { e.preventDefault(); onClick(); } : null;
  return <li className={`paginate_button page-item ${isPrevious ? "previous" : ""} ${isNext ? "next" : ""} ${disabled || active ? "disabled" : ""} ${active ? "active" : ""}`}>
    <a href="" aria-controls="table2" tabIndex="0"  className="page-link" onClick={eventOnClick}>{children}</a>
  </li>
}

export function generatePages(minPage, maxPage, curPage, numToShow) {
  const startPage = curPage - numToShow;
  const endPage = curPage + numToShow;

  return compose(
    reduce((acc, page) => {
      if (acc.lastPage !== null && page - acc.lastPage > 1) {
        acc.pages.push(null);
      }
      acc.pages.push(page);
      acc.lastPage = page;
      return acc;
    })({pages: [], lastPage: null}),
    function maybeAppendEnds(pages) {
      if (pages[0] !== minPage) {
        pages = [minPage].concat(pages);
      }
      if (pages[pages.length - 1] !== maxPage) {
        pages = pages.concat([maxPage]);
      }

      return pages
    },
    filter(p => minPage <= p && p <= maxPage),
    range(startPage)
  )(endPage + 1).pages;
}

export function PaginationFooter({total, perPage, page, goToPage, entryName = "entries"}) {
  const zeroIndexPage = page - 1; // page is not zero indexed, but starts a 1.
  const minPage = 1;
  const maxPage = Math.ceil(total / perPage);
  const minItem = (zeroIndexPage * perPage) + 1;
  const maxItem = Math.min(total, minItem + perPage - 1);

  return <div className="row mai-datatable-footer">
      <div className="col-sm-5">
        <div className="dataTables_info" role="status" aria-live="polite">
          Showing {minItem} to {maxItem} of {total} {entryName}.
        </div>
      </div>
      <div className="col-sm-7">
        <div className="dataTables_paginate paging_simple_numbers">
          <ul className="pagination">
            <PaginationLink isPrevious={true} disabled={page === minPage} onClick={page === minPage ? null : goToPage.bind(null, page - 1)}>Previous</PaginationLink>
            {generatePages(minPage, maxPage, page, 2).map((p, i) => (
              p === null
                ? <PaginationLink key={i} disabled={true}>…</PaginationLink>
                : <PaginationLink key={i} active={page === p} onClick={page === p ? null : goToPage.bind(null, p)}>{p}</PaginationLink>
            ))}
            <PaginationLink isNext={true} disabled={page === maxPage} onClick={page === maxPage ? null : goToPage.bind(null, page + 1)}>Next</PaginationLink>
          </ul>
        </div>
      </div>
    </div>
}

export function PaginationFooterWrapper({children}) {
  return <div className="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
    {children}
  </div>
}
