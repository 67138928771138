import createActionType from "../createActionType";

export const SET_COMMON_STATE_DATA = createActionType('common', 'SET_COMMON_STATE_DATA');
export const INIT = createActionType('common', 'INIT');
export const DEFER_RELOAD = createActionType('common', 'DEFER_RELOAD');
export const END_DEFER_RELOAD = createActionType('common', 'END_DEFER_RELOAD');
export const START_RELOAD = createActionType('common', 'START_RELOAD');
export const END_RELOAD = createActionType('common', 'END_RELOAD');
export const SET_FORCE_MOBILE = createActionType('common', 'SET_FORCE_MOBILE');
export const SET_GROWER_FOR_STATS = createActionType('common', 'SET_GROWER_FOR_STATS');
