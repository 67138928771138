import { produce } from 'immer';
import createActionType from "../../createActionType";

export const SUBMIT_FORM_START = createActionType('outgoing-loads/createDryTruckPanel', 'SUBMIT_FORM_START');
export const SUBMIT_FORM_FINISH = createActionType('outgoing-loads/createDryTruckPanel', 'SUBMIT_FORM_FINISH');
export const SET_FORM_API = createActionType('outgoing-loads/createDryTruckPanel', 'SET_FORM_API');

/**
 * @param {ApiClient} apiClient
 */
export function createDryTruckPanelActions(apiClient) {
  function submitFormStart() {
    return {type: SUBMIT_FORM_START};
  }
  function submitFormFinish() {
    return {type: SUBMIT_FORM_FINISH};
  }

  function setFormApi(formApi) {
    return {type: SET_FORM_API, formApi};
  }

  function submitForm(formValues) {
    return (dispatch, getState) => {
      dispatch(submitFormStart());
      return apiClient.createDryTruck({
        truckNumber: formValues.truckNumber,
        companyName: formValues.companyName || null
      }).then(() => {
        const formApi = getState().createDryTruckPanel.reactFormApi;
        formApi && formApi.resetAll();
      }, (e) => {
        alert(e);
      }).then(() => {
        dispatch(submitFormFinish());
      });
    }
  }

  return {
    setFormApi,
    submitForm,
  };
}

const initialState = {
  isSubmitting: false,
  reactFormApi: null,
};

export default function createDryTruckPanelReducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case SUBMIT_FORM_START:
        draft.isSubmitting = true;
        break;
      case SUBMIT_FORM_FINISH:
        draft.isSubmitting = false;
        break;
      case SET_FORM_API:
        draft.reactFormApi = action.formApi;
        break;
    }
  });
}
