import {createSelector} from "reselect";
import keyBy from 'lodash/keyBy';
import {storageLotIRI} from "../../common/model/iri";

export const getNewFillOrder = state => state.fillorders.newFillOrder;
export const getCurrentFillOrder = state => state.fillorders.currentFillOrder;
export const getDryStorage = state => state.fillorders.dryStorage;
export const getFillOrders = state => state.fillorders.fillOrdersList;
export const getFillOrderPage = state => state.fillorders.fillOrderPage;
export const getIsFillOrderSaving = state => state.fillorders.isSaving;

export const getCurrentStorageLotsById = createSelector(
  getDryStorage,
  (storageLotsResult) => {
    return keyBy(storageLotsResult.items, (lot) => storageLotIRI(lot.id));
  }
);

export const fillOrdersByGrower = createSelector(
  getFillOrders,
  fillOrders => {
      return fillOrders.reduce((acc, fillOrder) => {
        const growerFillOrders = acc[fillOrder.grower] || [];
        growerFillOrders.push(fillOrder);
        acc[fillOrder.grower] = growerFillOrders;
        return acc;
      }, {});
  }
);
