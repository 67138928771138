import React, { useState } from 'react';
import {FullPanel, PanelBody, PanelHeader} from "../../common/component/panel";
import compose from 'lodash/fp/compose';
import { Form, Text, withFormApi } from 'react-form';
import FormRowWrapper from "../../common/component/FormRowWrapper";
import classNames from 'classnames';
import {connect} from "react-redux";
import bindContainerActions from "../../bindContainerActions";

function validateNotEmpty(value) {
  return {
    error: !value ? 'Cannot be empty' : null,
    warning: null,
    success: null,
  }
}

function CreateDryTruckForm({isSubmitting, submitForm, setFormApi}) {
  return <Form onSubmit={submitForm}>{formApi => {
    setFormApi(formApi);
    return <form onSubmit={formApi.submitForm} name="createDryTruck">
      <FormRowWrapper label="Truck #" id="truckNumber">
        <Text field="truckNumber" id="truckNumber" validate={validateNotEmpty} className={classNames("form-control", formApi.getError('truckNumber') && "form-control-danger")} />
      </FormRowWrapper>
      <FormRowWrapper label="Company Name" id="companyName">
        <Text field="companyName" id="companyName" className="form-control" />
      </FormRowWrapper>
      <div className="text-right">
        <button type="submit" className="btn btn-space btn-primary" disabled={isSubmitting}>Create</button>
      </div>
    </form>
  }}</Form>
}

export function CreateDryTruckPanel(props) {
  return <FullPanel>
    <PanelHeader title="Add Dry Truck" divider />
    <PanelBody>
      <CreateDryTruckForm {...props} />
    </PanelBody>
  </FullPanel>
}

export default compose(
  connect((state) => {
    return {isSubmitting: state.createDryTruckPanel.isSubmitting}
  }, bindContainerActions('createDryTruckPanelActions')),
  withFormApi
)(CreateDryTruckPanel);
