import React from 'react';

class Switch extends React.Component {

  leftSliderSquare = {
    'height': 18,
    'width': 18,
    'boxShadow': '1px 1px 1px rgba(0,0,0,0.5)',
    'border': '1px solid rgba(255,255,255,1)',
    'translation': 'left 300ms cubic-bezier(0, 0, 0.11, 0.85)',
    'backgroundColor': 'white',
    'lineHeight': '15px',
    'position': 'relative',
    'top': -14,
    'left': 1
  };

  rightSliderSquare = {
    'height': 18,
    'width': 18,
    'boxShadow': '1px 1px 1px rgba(0,0,0,0.5)',
    'border': '1px solid rgba(255,255,255,1)',
    'translation': 'left 300ms cubic-bezier(0, 0, 0.11, 0.85)',
    'backgroundColor': 'white',
    'lineHeight': '15px',
    'position': 'relative',
    'top': -14,
    'left': 35
  };

  leftSliderContainer = {
    'width': 55,
    'height': 20,
    'backgroundColor': '#CCCCCC',
    'paddingRight': 3,
    'textAlign': 'right',
    'color': 'gray',
    'lineHeight': '15px',
    'cursor': 'pointer'
  };

  rightSliderContainer = {
    'width': 55,
    'height': 20,
    'backgroundColor': '#00FF35',
    'paddingRight': 3,
    'textAlign': 'left',
    'color': '#000aff',
    'lineHeight': '15px',
    'cursor': 'pointer'
  };

  rightText = {
    'position': 'relative',
    'top': 1.5,
    'left': 4
  };

  leftText = {
    'position': 'relative',
    'top': 1.5,
  };

  disabledState = {
    opacity: 0.5,
    cursor: 'default'
  };

  render() {
    const {on, onClick, disabled, style = {}} = this.props;
    const onClickHandler = disabled ? () => {} : onClick;
    const rootStyle = Object.assign(
      style,
      disabled ? this.disabledState : {},
      on ? this.rightSliderContainer : this.leftSliderContainer
    );
    return <div {...this.props} style={rootStyle} onClick={onClickHandler}>
      <span style={on ? this.rightText : this.leftText}>{on ? "yes" : "no"}</span>
      <div style={on ? this.rightSliderSquare : this.leftSliderSquare}/>
    </div>
  }
}

export default Switch;
