import {DateTime} from 'luxon';
import compose from "lodash/fp/compose";

export function isAirDoorOpen(bin) {
  return bin.airDoorState === 'open';
}

export function getBinMoisture(bin) {
  return bin.binMoisture.toFixed(1);
}

export function getHoursInBinLot(binLot) {
  const start = DateTime.fromISO(binLot.createdAt);
  const end = binLot.emptiedAt ? DateTime.fromISO(binLot.emptiedAt) : DateTime.local();

  return end.diff(start, ['hours']).hours;
}

export function formatHoursInBinLot(hours) {
  return Math.round(hours) + 'h';
}

export const getFormattedHoursInBinLot = compose(formatHoursInBinLot, getHoursInBinLot);

export function percentToPounds(percent) {
  return percent * 70;
}

export function poundsToPercent(pounds) {
  return pounds / 70;
}

export function poundsToBinUnits(pounds) {
  return Math.round(10 * (pounds / 7000)) / 10;
}
