import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import App from './App';
import {createLogger} from 'redux-logger';
import {hullerBottle, registerServices} from "./ioc";
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import rootReducer from './reducers';
import thunk from 'redux-thunk';

Sentry.init({ dsn: "https://fd2b3e2d8eb749d7b6ec512fbd3097bc@o422921.ingest.sentry.io/5418278" });

function eventEmitterMiddleware(container) {
  let count = 0;
  return store => next => action => {
    if (!count) {
      container.emitter.emit(action.type, action, store.dispatch, store.getState);
    }

    count += 1;
    return next(action);
  }
}

const loggerMiddleware = createLogger();

registerServices(hullerBottle);
const store = createStore(rootReducer, applyMiddleware(...[
  thunk,
  // eventEmitterMiddleware(hullerBottle.container),
  process.env.REACT_APP_REDUX_LOG == 1 ? loggerMiddleware : null
].filter(Boolean)));
hullerBottle.constant('store', store);

store.dispatch(hullerBottle.container.commonActions.init());

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root')
);

window.hullerStore = store;
window.hullerBottle = hullerBottle;
