import React, { useEffect } from 'react';
import FillOrderPanelSection from "./FillOrderPanelSection";
import {withRouter} from 'react-router-dom'
import compose from 'lodash/fp/compose';
import {connect} from 'react-redux';
import bindContainerActions from "../../bindContainerActions";
import {getCurrentFillOrder} from "../selector/fillorders";
import Select from 'react-select';
import {
  getFieldsById,
  getGrowersById,
  getReceivers,
  getStorageBins,
  getVarietiesById
} from "../../common/selector/common";
import GrowerColumnWrapper from "../../common/component/GrowerColumnWrapper";
import {
  GrowerHeader,
  GrowerSubHeader,
  GrowerItems,
  mapStateToFillOrderSectionProps,
  creatFillOrderSectionMouseEventHandlers
} from "./ShowFillOrderSection";
import {mapLbsReq} from "./FillOrderCollapse";
import {get} from "lodash";
import size from "lodash/size";
import {InlineSpinner} from "../../common/component/Spinner";
import {FillOrderPage} from "../redux";

export function StorageBinSelection({selectedStorageBin, storageBins, placeholder}) {
  const mapStorageBinToOption = (storageBin) => { return {value: storageBin.id, label: storageBin.name}};
  return <div>
    <div><b>Storage Bin</b></div>
    <Select classNamePrefix="react-select" options={storageBins.map(mapStorageBinToOption)} placeholder={placeholder} onChange={selectedStorageBin}/>
  </div>
}

export function ReceiverSelection({selectedReceiver, receivers, placeholder}) {
  const mapReceiverToOption = (receiver) => {return {value: receiver.id, label: receiver.name}};
  return <div>
    <div><b>Receiver</b></div>
    <Select classNamePrefix="react-select" options={receivers.map(mapReceiverToOption)} placeholder={placeholder} onChange={selectedReceiver}/>
  </div>
}

function EditFillOrderSection({history, currentFillOrder, currentFillOrderInfo, updateStorageBin, updateReceiver, receivers, storageBins, updateCurrentFillOrder, removeFillOrderItem, loadCurrentFillOrder, setGrowerForStats, initFillOrderPage, isFillOrderSaving}) {
  const {onMouseEnter, onMouseLeave} = creatFillOrderSectionMouseEventHandlers(currentFillOrderInfo, setGrowerForStats);

  // reload the fill order on render
  useEffect(() => {
    const cancelToken = loadCurrentFillOrder(currentFillOrderInfo.id)[1];
    initFillOrderPage(FillOrderPage.Edit);
    return () => cancelToken.cancel();
  }, [currentFillOrderInfo.id]);

  let lbs = mapLbsReq(get(currentFillOrder, "items"));
  const updateFillOrder = () => updateCurrentFillOrder(currentFillOrder);

  return <FillOrderPanelSection title={`Edit Fill Order #${currentFillOrderInfo.id}`} backUrl={`/fill-orders/show/${currentFillOrderInfo.id}`} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
    <InlineSpinner enabled={!currentFillOrder}>{() => (
      <React.Fragment>
        <GrowerColumnWrapper color={currentFillOrderInfo.color}>
          <GrowerHeader grower={currentFillOrderInfo.name} id={currentFillOrderInfo.id} variety={currentFillOrderInfo.variety}/>
        </GrowerColumnWrapper>
        <GrowerSubHeader fillOrderInfo={currentFillOrderInfo} lbsreq={lbs.poundsRequested} lbsrem={lbs.poundsRemaining}/>
        <div className="row mb-2">
          <div className="col col-md-6">
            <StorageBinSelection selectedStorageBin={updateStorageBin} storageBins={storageBins} placeholder={currentFillOrderInfo.storageBin}/>
          </div>
          <div className="col col-md-6">
            <ReceiverSelection selectedReceiver={updateReceiver} receivers={receivers} placeholder={currentFillOrderInfo.receiver} />
          </div>
        </div>
        <GrowerItems items={currentFillOrderInfo.items} currentFillOrder={currentFillOrder} removeFillOrderItem={removeFillOrderItem} history={history} />
        <div className="text-right">
          <button type="button" className="btn btn-success" onClick={updateFillOrder} disabled={isFillOrderSaving}>Update Fill Order</button>
        </div>
      </React.Fragment>
    )}</InlineSpinner>
  </FillOrderPanelSection>
}

export default compose(
  connect((state, props) => {
    return Object.assign(mapStateToFillOrderSectionProps(state, props), {
      storageBins: getStorageBins(state),
      receivers: getReceivers(state),
    });
  }, bindContainerActions('fillordersActions@FillOrders')),
  withRouter,
)(EditFillOrderSection);

