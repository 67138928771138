import React from 'react';
import {WetLoadAccess} from "../model/wetside";
import GrowerColumnWrapper from "./GrowerColumnWrapper";

export default function LoadInYard({wetLoad}) {
  if (!wetLoad) {
    return <div></div>;
  }

  const wl = new WetLoadAccess(wetLoad);
  return <GrowerColumnWrapper color={wl.growerColor()}>
    <div>
      <div><b>{wl.growerName()}</b></div>
      <div>{wl.trailerSetName()}</div>
      <div className={"d-flex justify-content-between"}>
        <div>{wl.varietyName()}</div>&nbsp;
        {wl.gross()} lbs
      </div>
    </div>
  </GrowerColumnWrapper>;
}