import {unfilledDryTruckListPanelActions} from "./redux/unfilledDryTruckListPanel";
import {recentlyFilledDryTruckListPanelActions} from "./redux/recentlyFilledDryTruckListPanel";
import {dryTruckViewPageActions} from "./redux/dryTruckViewPage";

export default function registerServices(bottle, initialState) {
  bottle.serviceFactory('unfilledDryTruckListPanelActions', unfilledDryTruckListPanelActions, 'apiClient', 'pusher');
  bottle.serviceFactory('recentlyFilledDryTruckListPanelActions', recentlyFilledDryTruckListPanelActions, 'apiClient', 'pusher');
  bottle.serviceFactory('dryTruckViewPageActions', dryTruckViewPageActions, 'apiClient', 'pusher');
  return bottle;
}
