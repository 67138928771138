import {FullPanel, PanelBody, PanelHeader} from "../../common/component/panel";
import React from "react";
import {connect} from 'react-redux';
import {getLoadsInYardStats} from "../../common/selector/bin";

function LoadsInYardStats({stats, tightLayout = false}) {
  return <FullPanel>
    {!tightLayout && <PanelHeader title="Stats" divider={true}/>}
    <PanelBody>
      <dl className="mb-0">
        <dt>Loads in Yard</dt>
        <dd className="mb-1">{stats.totalLoads}</dd>
        <dt>Lbs in Yard</dt>
        <dd className="mb-0">{stats.totalLbs + ' lbs'}</dd>
      </dl>
    </PanelBody>
  </FullPanel>
}

export default connect((state) => {
  return {stats: getLoadsInYardStats(state)};
})(LoadsInYardStats);
