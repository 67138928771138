import capitalize from 'lodash/capitalize';
import {DateTime} from "luxon";

export class DryTruckAccess {
  constructor(dryTruck) {
    this.dryTruck = dryTruck;
  }

  id() {
    return this.dryTruck.id;
  }

  fullTruckTitle() {
    return this.dryTruck.companyName
      ? `${this.dryTruck.truckNumber} - ${this.dryTruck.companyName}`
      : this.dryTruck.truckNumber;
  }

  trailerTypeDisplay() {
    return this.dryTruck.trailerType ? capitalize(this.dryTruck.trailerType) : 'N/A';
  }

  trailerCompartments() {
    return this.dryTruck.lots
      .map(lot => lot.trailerCompartments)
      .reduce((acc, compartments) => acc.concat(compartments), []);
  }

  truckNumber() {
    return this.dryTruck.truckNumber;
  }

  companyName() {
    return this.dryTruck.companyName;
  }

  fillStateForDisplay() {
    return capitalize(this.dryTruck.fillState)
  }

  processStateFromDisplay() {
    return capitalize(this.dryTruck.processState);
  }

  createdAt() {
    return DateTime.fromISO(this.dryTruck.createdAt).toLocal();
  }

  fillingAt() {
    return this.dryTruck.fillingAt && DateTime.fromISO(this.dryTruck.fillingAt).toLocal();
  }

  filledAt() {
    return this.dryTruck.filledAt && DateTime.fromISO(this.dryTruck.filledAt).toLocal();
  }

  trailerTypeForDisplay() {
    return capitalize(this.dryTruck.trailerType);
  }
}
