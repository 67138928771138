import {produce} from 'immer';
import createActionType from "../createActionType";
import {getBinLotsById} from "../common/selector/bin";
import {percentToPounds} from "../bin/model/bin";
import {getCurrentFillOrder, getFillOrderPage, getIsFillOrderSaving, getNewFillOrder} from "./selector/fillorders";
import {createFilterMap, createMultiFilter} from "../common/component/filter";
import {get, set, update, last} from 'lodash'
import {updateTableSelectFilterFromEntities} from '../incoming-loads/redux'
import {INIT, SET_COMMON_STATE_DATA} from "../common/actions";
import composeReducers from "../util/composerReducers";
import {listenOnceLoggedIn} from "../common/subscribers";
import {binLotIRI, receiverIRI, storageBinIRI} from "../common/model/iri";
import { getCurrentSeason } from '../home/selector/season';

export const ADD_BIN_LOT_TO_NEW_FILL_ORDER = createActionType('fillorders', 'ADD_BIN_LOT_TO_NEW_FILL_ORDER');
export const ADD_BIN_LOT_TO_CURRENT_ORDER = createActionType('fillorders', 'ADD_BIN_LOT_TO_CURRENT_ORDER');
export const REMOVE_BIN_LOT_FROM_NEW_FILL_ORDER = createActionType('fillorders', 'REMOVE_BIN_LOT_FROM_NEW_FILL_ORDER');
export const REMOVE_BIN_LOT_FROM_CURRENT_FILL_ORDER = createActionType('fillorders', 'REMOVE_BIN_LOT_FROM_CURRENT_FILL_ORDER');
export const SET_NEW_FILL_ORDER_FIELD = createActionType('fillorders', 'SET_NEW_FILL_ORDER_FIELD');
export const STEP_NEW_FILL_ORDER_ITEM_PERCENT_REQUESTED = createActionType('fillorders', 'STEP_NEW_FILL_ORDER_ITEM_PERCENT_REQUESTED');
export const STEP_UPDATE_FILL_ORDER_ITEM_PERCENT_REQUESTED = createActionType('fillorders', 'STEP_UPDATE_FILL_ORDER_ITEM_PERCENT_REQUESTED');
export const SLIDER_UPDATE_FILL_ORDER_ITEM_PERCENT_REQUESTED = createActionType('fillorders', 'SLIDER_UPDATE_FILL_ORDER_ITEM_PERCENT_REQUESTED');
export const CHANGE_SORT = createActionType('fillorders', 'CHANGE_SORT');
export const SET_STORAGE_BIN = createActionType('fillorders', 'SET_STORAGE_BIN');
export const SET_RECEIVER = createActionType('fillorders', 'SET_RECEIVER');
export const UPDATE_STORAGE_BIN = createActionType('fillorders', "UPDATE_STORAGE_BIN");
export const UPDATE_RECEIVER = createActionType('fillorders', "UPDATE_RECEIVER");
export const SET_FILL_ORDERS = createActionType('fillorders', 'SET_FILL_ORDERS');
export const SET_DRY_STORAGE = createActionType('fillorders', 'SET_DRY_STORAGE');
export const SET_FILTER_VALUES = createActionType('fillorders', 'SET_FILTER_VALUE');
export const SET_IS_LOADING = createActionType('fillOrders', 'SET_IS_LOADING');
export const SET_IS_SAVING = createActionType('fillOrders', 'SET_IS_SAVING');
export const SET_CURRENT_FILL_ORDER = createActionType('fillOrders', 'SET_CURRENT_FILL_ORDER');
export const SET_FILL_ORDER_PAGE = createActionType('fillOrders', 'SET_FILL_ORDER_PAGE');
export const RESET_NEW_FILL_ORDER = createActionType('fillOrders', 'RESET_NEW_FILL_ORDER');

export const FillOrderPage = {
  Create: 'create',
  Edit: 'edit',
  Show: 'show',
};

export const SortKeys = {
  Bin: "bin",
  Receiver: "receiver",
  Grower: "grower",
  Variety: "variety",
  Field: "field",
  Moisture: "moisture",
  Pounds: "pounds",
  Time: "time"
};

export function setIsLoading(isLoading) {
  return {type: SET_IS_LOADING, isLoading};
}

export function fillordersActions(apiClient, confirm = null) {
  confirm = confirm || window.confirm;
  function setNewFillOrderField(field, value) {
    return {type: SET_NEW_FILL_ORDER_FIELD, field: Array.isArray(field) ? field : [field], value};
  }

  function stepNewFillOrderItemPercentRequested(direction, idx) {
    return {type: STEP_NEW_FILL_ORDER_ITEM_PERCENT_REQUESTED, direction, idx};
  }

  function stepUpdateFillOrderItemPercentRequested(direction, idx) {
    return {type: STEP_UPDATE_FILL_ORDER_ITEM_PERCENT_REQUESTED, direction, idx}
  }

  function sliderUpdateFillOrderItemPercentRequested(percent, idx){
    return {type: SLIDER_UPDATE_FILL_ORDER_ITEM_PERCENT_REQUESTED, percent, idx}
  }

  function changeSort(key) {
    return {type: CHANGE_SORT, key};
  }

  function setFilterValues(filterName, selectedValues) {
    return {type: SET_FILTER_VALUES, filterName, selectedValues}
  }

  function setFillOrders(fillOrdersList) {
    return {type: SET_FILL_ORDERS, fillOrdersList}
  }

  function setDryStorage(dryStorage) {
    return {type: SET_DRY_STORAGE, dryStorage}
  }

  function setCurrentFillOrder(fillOrder){
    return {type: SET_CURRENT_FILL_ORDER, fillOrder}
  }
  const setFillOrderPage = (fillOrderPage) => ({ type: SET_FILL_ORDER_PAGE, fillOrderPage });
  const setIsSaving = (isSaving) => ({ type: SET_IS_SAVING, isSaving });

  function createNewFillOrderItem(id, item){
    return dispatch => {
      return apiClient.createFillOrderItem(id, item).then(() =>{
        return dispatch(getFillOrders());
      })
    }
  }

  function createNewFillOrder(newFillOrder, history) {
    return dispatch => {
      if (!newFillOrder.storageBin) {
        alert("Select a Storage Bin");
        return Promise.resolve(true);
      }
      if (!newFillOrder.receiver) {
        alert("Select a Receiver");
        return Promise.resolve(true);
      }

      dispatch(setIsSaving(true));
      return apiClient.createFillOrder(newFillOrder)
        .then((fillOrder) => {
          dispatch(getFillOrders());
          dispatch({ type: RESET_NEW_FILL_ORDER });
          history.push(`/fill-orders/edit/${fillOrder.id}`);
        }, alert)
        .then(() => dispatch(setIsSaving(false)));
    }
  }

  function updateCurrentFillOrder(currentFillOrder) {
    return dispatch => {
      dispatch(setIsSaving(true));
      return apiClient.updateFillOrder(currentFillOrder, currentFillOrder.id)
        .then(() => {
          return Promise.all([
            dispatch(getFillOrders()),
            dispatch(loadCurrentFillOrder(currentFillOrder.id, true))[0],
          ]);
        }, alert)
        .then(() => dispatch(setIsSaving(false)));
    }
  }

  function moveAllFillOrderItems(fillOrder){
    return dispatch => {
      dispatch(setIsSaving(true));
      return apiClient.moveAllFillOrder(fillOrder).then(() => {
        return Promise.all([
          dispatch(getDryStorage()),
          dispatch(getFillOrders()),
          dispatch(loadCurrentFillOrder(fillOrder.id, true))[0],
        ]);
      }, alert).then(() => dispatch(setIsSaving(false)));
    }
  }

  function moveItemFillOrder(fillOrder, itemId){
    return dispatch => {
      dispatch(setIsSaving(true));
      return apiClient.moveFillOrderItem(fillOrder, itemId).then(() => {
        return Promise.all([
          dispatch(getDryStorage()),
          dispatch(getFillOrders()),
          dispatch(loadCurrentFillOrder(fillOrder.id, true))[0],
        ]);
      }, alert).then(() => dispatch(setIsSaving(false)));
    }
  }

  function moveItemBackFillOrder(fillOrderId, itemId) {
    return dispatch => {
      dispatch(setIsSaving(true));
      return apiClient.moveBackFillOrderItem(fillOrderId, itemId).then(() => {
        return Promise.all([
          dispatch(getDryStorage()),
          dispatch(getFillOrders()),
          dispatch(loadCurrentFillOrder(fillOrderId, true))[0],
        ]);
      }, alert).then(() => dispatch(setIsSaving(false)));
    }
  }

  function getFillOrders(){
    return (dispatch, getState) => {
      return apiClient.getHydraFillOrders({
        season: getCurrentSeason(getState()),
      })[0].then(fillOrders => {
        dispatch(setFillOrders(fillOrders));
      });
    }
  }

  function getDryStorage(){
    return dispatch => {
      return apiClient.getHydraStorageLotsCurrent().then(dryStorage => dispatch(setDryStorage(dryStorage)));
    }
  }

  function loadCurrentFillOrder(id, forceReload = false) {
    return (dispatch, getState) => {
      if (forceReload) {
        dispatch(setCurrentFillOrder(null));
      }

      const currentFillOrder = getCurrentFillOrder(getState());
      if (currentFillOrder && currentFillOrder.id === id) {
        return [Promise.resolve(), {cancel: () => {}}];
      } else if (currentFillOrder) {
        dispatch(setCurrentFillOrder(null));
      }

      const [res, cancelToken] = apiClient.getFillOrderWithCancelToken(id);
      return [res.then(fillOrder => {
        return dispatch(setCurrentFillOrder(fillOrder));
      }, alert), cancelToken];
    }
  }

  function reloadCurrentFillOrder() {
    return (dispatch, getState) => {
      const currentFillOrder = getCurrentFillOrder(getState());
      if (!currentFillOrder) {
        return;
      }
      if (getIsFillOrderSaving(getState())) {
        return; // we don't reload current fill order, if the current user is saving.
      }

      return apiClient.getFillOrder(currentFillOrder.id).then(fillOrder => {
        return dispatch(setCurrentFillOrder(fillOrder));
      }, (errorMessage) => {
        if (errorMessage !== 'An error occurred: Not Found') {
          alert(errorMessage);
        }
      });
    }
  }

  function removeFillOrderItem(itemId, history, binLotId) {
    return (dispatch, getState) => {
      if (!window.confirm("Are you sure you want to delete this item?")){
        return Promise.resolve();
      }

      const currentFillOrder = getCurrentFillOrder(getState());
      const shouldGoBackToFillOrdersPageOnSuccess = currentFillOrder.items.length === 1;

      if (binLotId) {
        dispatch({ type: REMOVE_BIN_LOT_FROM_CURRENT_FILL_ORDER, binLotId });
        return Promise.resolve();
      }

      return apiClient.removeFillOrderItem(itemId).then(() => {
        if (shouldGoBackToFillOrdersPageOnSuccess) {
          history.push('/fill-orders');
        }
        return Promise.all([dispatch(getFillOrders()), dispatch(getDryStorage())]);
      }, alert);
    }
  }

  function setStorageBin(storageBin){
    return {type: SET_STORAGE_BIN, storageBin}
  }

  function setReceiver(receiver, currentReceiver){
    return dispatch => {
      if(currentReceiver){
        if(!window.confirm("This order aleady has a designated receiver, are you sure you want to change the receiver?")){
          return Promise.resolve();
        }
      }
      return dispatch({type: SET_RECEIVER, receiver});
    }
  }

  function updateStorageBin(storageBin) {
    return {type: UPDATE_STORAGE_BIN, storageBin}
  }

  function updateReceiver(receiver) {
    return {type: UPDATE_RECEIVER, receiver}
  }

  function addBinLotToNewFillOrder(binLotId) {
    return (dispatch, getState) => {
      const state = getState();
      const binLotsById = getBinLotsById(state);
      const binLotToAdd = binLotsById[binLotIRI(binLotId)];
      const newFillOrder = getNewFillOrder(state);

      const validationError = validateAddingBinLot(newFillOrder, binLotToAdd, binLotsById, item => item.binLotId);
      if (validationError) {
        const [message, alertType] = validationError;
        if (alertType === 'alert') {
          return alert(message);
        }
        if (alertType === 'confirm' && !confirm(message)) {
          return;
        }
      }

      dispatch({type: ADD_BIN_LOT_TO_NEW_FILL_ORDER, binLot: binLotToAdd});
    }
  }

  function validateAddingBinLot(fillOrder, binLotToAdd, binLotsById, getBinLotIdFromItem) {
    const firstBinLotId = fillOrder.items[0] && getBinLotIdFromItem(fillOrder.items[0]);
    if (fillOrder.items.some(item => getBinLotIdFromItem(item) === binLotToAdd.id)) {
      return ['This bin lot already exists in the pending fill order.', 'alert'];
    }
    if (!firstBinLotId) {
      return;
    }

    const firstBinLot = binLotsById[binLotIRI(firstBinLotId)];
    if (firstBinLot.grower !== binLotToAdd.grower || firstBinLot.variety !== binLotToAdd.variety) {
      return ['Cannot mix grower or variety', 'alert'];
    }
    if (firstBinLot.field !== binLotToAdd.field) {
      return ['Are you sure you want to mix fields for this fill order?', 'confirm'];
    }
  }

  function addBinLotToCurrentFillOrder(binLotId) {
    return (dispatch, getState) => {
      const state = getState();
      const binLotsById = getBinLotsById(state);
      const binLotToAdd = binLotsById[binLotIRI(binLotId)];
      const currentFillOrder = getCurrentFillOrder(state);

      const errorMessage = validateAddingBinLot(currentFillOrder, binLotToAdd, binLotsById, item => item.binLot.id);
      if (errorMessage) {
        alert(errorMessage);
        return;
      }

      dispatch({type: ADD_BIN_LOT_TO_CURRENT_ORDER, binLot: binLotToAdd});
    }
  }

  function removeBinLotFromNewFillOrder(binLotId) {
    return {type: REMOVE_BIN_LOT_FROM_NEW_FILL_ORDER, binLotId};
  }

  function filteredBinListClickHandler(binLotId, e) {
    return (dispatch, getState) => {
      const fillOrderPage = getFillOrderPage(getState());
      const state = getState();
      const binLotsById = getBinLotsById(state);
      const binLotToAdd = binLotsById[binLotIRI(binLotId)];
      if(!binLotToAdd.poundsNotRequested) {
        return alert(`Bin lot ${binLotToAdd.bin.split('/bins/')[1]} is fill ordered`)
      }
      if (fillOrderPage === FillOrderPage.Create) {
        dispatch(addBinLotToNewFillOrder(binLotId));
      } else if (fillOrderPage === FillOrderPage.Edit) {
        dispatch(addBinLotToCurrentFillOrder(binLotId));
      } else {
        console.log('do something else!');
      }
    }
  }

  function initFillOrderPage(page) {
    return (dispatch, getState) => {
      dispatch(setFillOrderPage(page));
    }
  }

  return {
    createNewFillOrder,
    getFillOrders,
    getDryStorage,
    loadCurrentFillOrder,
    reloadCurrentFillOrder,
    moveAllFillOrderItems,
    moveItemFillOrder,
    moveItemBackFillOrder,
    removeFillOrderItem,
    setFilterValues,
    setStorageBin,
    setReceiver,
    updateStorageBin,
    updateReceiver,
    updateCurrentFillOrder,
    setNewFillOrderField,
    stepNewFillOrderItemPercentRequested,
    stepUpdateFillOrderItemPercentRequested,
    sliderUpdateFillOrderItemPercentRequested,
    removeBinLotFromNewFillOrder,
    filteredBinListClickHandler,
    changeSort,
    initFillOrderPage
  };
}

function createFillOrderItemReq(binLotId, percentRequested = null) {
  return {binLotId, percentRequested};
}

function createFillOrderItemReqFromBinLot(binLot, includeBinLot = false) {
  const req = {
    binLotId: binLot.id,
    percentNotRequested: binLot.percentFull,
    percentRequested: binLot.percentFull,
    binId: Number(last(binLot.bin.split("/")))
  };
  if (includeBinLot) {
    req.poundsFull = binLot.poundsFull;
    req.binLot = binLot;
  }
  return req;
}

function totalPoundsForNewFillOrder(fillOrder) {
  const totalPercentRequested = fillOrder.items.reduce((acc, item) => {
    return acc + item.percentRequested;
  }, 0);
  const totalPercentNotRequested = fillOrder.items.reduce((acc, item) =>{
    return acc + item.percentNotRequested;
  }, 0);
  fillOrder.poundsRequested = percentToPounds(totalPercentRequested);
  fillOrder.poundsRemaining = percentToPounds(totalPercentNotRequested);
  return fillOrder;
}

function totalPoundsForUpdatedFillOrder(fillOrder){
  const totalPercentRequested = fillOrder.items.reduce((acc, item) => {
    return acc + item.percentRequested;
  }, 0);
  fillOrder.poundsRequested = percentToPounds(totalPercentRequested);
  return fillOrder;
}

export function fillOrdersDataReducer(state, action) {
  return produce(state, draft => {
    switch (action.type) {
      case SET_COMMON_STATE_DATA:
        switch (action.field) {
          case 'growers':
            updateTableSelectFilterFromEntities(draft, action, 'grower', 'All Growers');
            break;
          case 'fields':
            updateTableSelectFilterFromEntities(draft, action, 'field', 'All Fields');
            break;
          case 'varieties':
            updateTableSelectFilterFromEntities(draft, action, 'variety', 'All Varieties');
            break;
        }
    }
  });
}

export function fillOrderSubscribers(container, emitter) {
  listenOnceLoggedIn(container, emitter, (dispatch) => {
    const reloadChannel = container.pusher.subscribe('reload');

    dispatch(container.fillordersActions.getFillOrders());
    dispatch(container.fillordersActions.getDryStorage());

    reloadChannel.bind('fillOrder', () => {
      dispatch(container.fillordersActions.getFillOrders());
      dispatch(container.fillordersActions.reloadCurrentFillOrder());
    });
    reloadChannel.bind('storageLots', () => {
      return dispatch(container.fillordersActions.getDryStorage());
    });
  });
  return emitter;
}


const initialState = {
  fillOrderPage: null,
  fillOrdersList: [],
  dryStorage: {},
  currentFillOrder: null,
  updateCurrentFillOrder: {},
  newFillOrder: {
    storageBin: null,
    receiver: null,
    poundsRequested: 0,
    poundsRemaining: 0,
    items: []
  },
  isLoading: false,
  isSaving: false,
  tableFilters: createFilterMap([
    createMultiFilter('grower', 'Growers'),
    createMultiFilter('field', 'Fields'),
    createMultiFilter('variety', 'Varieties'),
  ]),
  sortBy: {
    key: SortKeys.Bin,
    asc: true,
  }
};

function fillOrderReducer(state = initialState, action) {
  return produce(state, draft => {
    switch(action.type) {
      case RESET_NEW_FILL_ORDER:
        draft.newFillOrder = initialState.newFillOrder;
        break;
      case ADD_BIN_LOT_TO_NEW_FILL_ORDER:
        draft.newFillOrder.items.push(createFillOrderItemReqFromBinLot(action.binLot));
        update(draft, 'newFillOrder', totalPoundsForNewFillOrder);
        if (action.binLot.designatedReceiver) {
          draft.newFillOrder.receiver = action.binLot.designatedReceiver;
        }
        break;
      case REMOVE_BIN_LOT_FROM_NEW_FILL_ORDER:
        update(draft, 'newFillOrder.items', items => items.filter(i => i.binLotId !== action.binLotId));
        update(draft, 'newFillOrder', totalPoundsForNewFillOrder);
        if(draft.newFillOrder.items.length === 0){
          draft.newFillOrder.storageBin = null;
          draft.newFillOrder.receiver = null
        }
        break;
      case ADD_BIN_LOT_TO_CURRENT_ORDER:
        draft.currentFillOrder.items.push(createFillOrderItemReqFromBinLot(action.binLot, true));
        update(draft, 'currentFillOrder', totalPoundsForUpdatedFillOrder);
        break;
      case REMOVE_BIN_LOT_FROM_CURRENT_FILL_ORDER:
        update(draft, 'currentFillOrder.items', items => items.filter(i => i.binLotId !== action.binLotId));
        update(draft, 'currentFillOrder', totalPoundsForUpdatedFillOrder);
        break;
      case SET_FILL_ORDERS:
        draft.fillOrdersList = action.fillOrdersList.items || [];
        break;
      case SET_DRY_STORAGE:
        draft.dryStorage = action.dryStorage;
        break;
      case SET_NEW_FILL_ORDER_FIELD: // newDraft is weird
      //   const newDraft = set(draft, draft.newFillOrder, action.value) //action.value??
      //   console.log("NEW " , JSON.stringify(newDraft))
      //   console.log("act field " , action.field)
      //   if(action.field[action.field.length - 1] === 'percentRequested') {
      //     update(newDraft, 'newFillOrder', totalPoundsForNewFillOrder)
      //     draft = newDraft;
      //   } else {
      //     draft = newDraft;
      //   }
      case STEP_NEW_FILL_ORDER_ITEM_PERCENT_REQUESTED:
        if(action.idx === undefined)
          return;
        let tempPercentRequested = draft.newFillOrder.items[action.idx].percentRequested;
        let tempPercentNotRequested = draft.newFillOrder.items[action.idx].percentNotRequested;
        if(action.direction === 'up') {
          tempPercentRequested += 10;
          if(tempPercentNotRequested < tempPercentRequested ) return;
          draft.newFillOrder.items[action.idx].percentRequested = Math.min(Math.max(0, tempPercentRequested), 100);
        } else if(action.direction === 'down') {
          tempPercentRequested -= 10
          draft.newFillOrder.items[action.idx].percentRequested = Math.min(Math.max(0, tempPercentRequested), 100);
        }
        update(draft, 'newFillOrder', totalPoundsForNewFillOrder);
        break;
      case STEP_UPDATE_FILL_ORDER_ITEM_PERCENT_REQUESTED:
        let currentPercentRequested = draft.currentFillOrder.items[action.idx].percentRequested;
        let currentPercentNotRequested = draft.currentFillOrder.items[action.idx].binLot.percentFull;
        if(action.direction === 'up') {
          currentPercentRequested += 10;
          if(currentPercentNotRequested < currentPercentRequested ) return;
          draft.currentFillOrder.items[action.idx].percentRequested = Math.min(Math.max(0, currentPercentRequested), 100);
          draft.currentFillOrder.items[action.idx].poundsRequested = (percentToPounds(draft.currentFillOrder.items[action.idx].percentRequested));
        } else if(action.direction === 'down') {
          currentPercentRequested -= 10
          draft.currentFillOrder.items[action.idx].percentRequested = Math.min(Math.max(0, currentPercentRequested), 100);
          draft.currentFillOrder.items[action.idx].poundsRequested = (percentToPounds(draft.currentFillOrder.items[action.idx].percentRequested));
        }
        update(draft, 'currentFillOrder', totalPoundsForUpdatedFillOrder);
        break;
      case SLIDER_UPDATE_FILL_ORDER_ITEM_PERCENT_REQUESTED:
        draft.currentFillOrder.items[action.idx].percentRequested = action.percent;
        draft.currentFillOrder.items[action.idx].poundsRequested = (percentToPounds(draft.currentFillOrder.items[action.idx].percentRequested));
        update(draft, 'currentFillOrder', totalPoundsForUpdatedFillOrder);
        break;
      case SET_CURRENT_FILL_ORDER:
        draft.currentFillOrder = action.fillOrder;
        break;
      case SET_STORAGE_BIN:
        draft.newFillOrder.storageBin = storageBinIRI(action.storageBin.value);
        break;
      case SET_RECEIVER:
        draft.newFillOrder.receiver = receiverIRI(action.receiver.value);
        break;
      case UPDATE_STORAGE_BIN:
        draft.currentFillOrder.storageBin = storageBinIRI(action.storageBin.value);
        break;
      case UPDATE_RECEIVER:
        draft.currentFillOrder.receiver = receiverIRI(action.receiver.value);
        break;
      case SET_FILTER_VALUES:
        draft.tableFilters[action.filterName].selectedValues = action.selectedValues;
        break;
      case SET_IS_LOADING:
        draft.isLoading = action.isLoading;
        break;
      case CHANGE_SORT:
        if(draft.sortBy.key === action.key ){
          update(draft, `sortBy.asc`, v => !v)
        } else {
          update(draft, `sortBy.key`, key => key = action.key)
          update(draft, `sortBy.asc`, v => v)
        }
        break;
      case SET_FILL_ORDER_PAGE:
        draft.fillOrderPage = action.fillOrderPage;
        break;
      case SET_IS_SAVING:
        draft.isSaving = action.isSaving;
        break;
    }
  })

}

export default composeReducers(fillOrderReducer, fillOrdersDataReducer);
