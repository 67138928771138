import React from 'react';
import {connect} from 'react-redux';
import bindContainerActions from "../../bindContainerActions";
import GrowerColumnWrapper from "../../common/component/GrowerColumnWrapper";
import {Collapse} from 'reactstrap';
import {BrowserRouter as Router, Link} from 'react-router-dom';
import compose from 'lodash/fp/compose';
import {withMobileSize} from "../../common/component/size";
import Weight from "../../common/component/Weight";
import {percentToPounds} from "../../bin/model/bin";

class FillOrderCollapse extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.state = {collapse: false, item: false};
  }

  toggle() {
    this.setState(state => ({collapse: !state.collapse}));
  }

  onMouseLeave(){
    this.setState({collapse: false});
  }

  render() {
    return (
      <div onClick={this.toggle}>
        <Grower grower={this.props.grower}/>
        <Collapse isOpen={this.state.collapse} onMouseLeave={this.onMouseLeave}>
          <ExpandGrower grower={this.props.grower} props={this.props}/>
        </Collapse>
      </div>
    );
  }
}

/** @param {Array} items */
export const mapLbsReq = (items) => {
  let lbs = {poundsRequested: 0, poundsRemaining: 0};
  if(items === undefined) return lbs;
  else {
    items.forEach(item => {
      lbs.poundsRequested += item.poundsRequested || percentToPounds(item.percentRequested);
      lbs.poundsRemaining += item.poundsFull;
    })
  }
  return lbs;
}

function Grower({grower}) {
  const lbs = mapLbsReq(grower.items);
  return <GrowerColumnWrapper color={grower.items.length > 0 ? grower.items[0].binLot.grower.color: '#'} style={{cursor: 'pointer'}}>
    <div>
      <div><b>{grower.items.length > 0 ? grower.items[0].binLot.grower.name:  <React.Fragment>&nbsp;</React.Fragment>}</b></div>
      <div>Lbs remaining
        <Weight pounds={lbs.poundsRemaining} units/>
      </div>
    </div>
  </GrowerColumnWrapper>
}

function ExpandGrower({grower, props}){
  const items = grower.items;
  const binList = [];
  const lbs = mapLbsReq(items);
  items.forEach(item => binList.push(item.binLot.bin.id))
  let getFillOrderHandle = () => props.getFillOrder(grower.id);
  const variety = grower.items.length ? grower.items[0].binLot.variety.name : '';
  const field = grower.items.length ? grower.items[0].binLot.field.name: '';
  return (
    <Link to={`/fill-orders/grower/${grower.id}`} onClick={getFillOrderHandle} className="text-dark" props={FillOrderCollapse.props}>
      <GrowerColumnWrapper color={items.length > 0 ? items[0].binLot.grower.color: '#'}>
        <div>
          <div style={{textAlign: "center"}}>#{grower.id}</div>
          <div className="col"><b>Variety: </b>{variety}</div>
          <div className="col"><b>Field: </b>{field}</div>
          <div className="col"><b>Storage Bin:</b>  {grower.storageBin.name}</div>
          <div className="col">
            <span><b>Lbs Requested:</b><Weight pounds={lbs.poundsRequested} units/></span>
          </div>
          <div className="col">
            <span><b>Lbs Remaining:</b><Weight pounds={lbs.poundsRemaining} units/></span>
          </div>
          <div className="col"><b>Bins: </b>
            {binList.map((bin, index) => {
              return (binList.length - 1 === index) ? `${bin}` : `${bin}, `;
            })}
          </div>
        </div>
      </GrowerColumnWrapper>
    </Link>
  )
}

export default compose(
  withMobileSize,
  connect(state => {
    return {};
  }, bindContainerActions('fillordersActions'))
)(FillOrderCollapse);
