import React, { useState, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { Modal, ModalBody } from 'reactstrap';
import {connect} from 'react-redux';
import GroupedLoad, {GroupedLoadThinBody} from "./GroupedLoad";
import {
  getDefaultScheduledWetLoads,
  getGroupedLoads,
  getPrioritizedLoadIds,
  getWetLoads,
  getWetLoadsByEntityId
} from "../../common/selector/wetside";
import GrowerColumnWrapper from "../../common/component/GrowerColumnWrapper";
import {WetLoadAccess} from "../../common/model/wetside";
import bindContainerActions from "../../bindContainerActions";
import {getPits} from "../../common/selector/common";
import Time from "../../common/component/Time";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

function DumpLoadForm({dumpLoad, isDumpingLoad, pits}) {
  return <div className="d-flex mb-2 justify-content-between">
    {pits.map(p => {
      return <button key={p.id} type="button" id={p.id} className="btn btn-success py-2 px-3" onClick={dumpLoad.bind(null, p.id)} disabled={isDumpingLoad}>
        {p.name}
      </button>
    })}
  </div>
}

function DetailedLoad({wetLoad, currentWetLoadNote, setCurrentWetLoadNote, createWetLoadNote, removeWetLoadNote, pits, isDumpingLoad, dumpIncomingLoad, editWetLoadNote}) {
  const wl = new WetLoadAccess(wetLoad);
  const hasNote = wetLoad.note
  function dumpLoad(pitId) {
    dumpIncomingLoad(wetLoad.id, pitId);{}
  }
  const handleCreateNote = () => {
    createWetLoadNote(wetLoad.id, document.getElementById(`wetLoadNote${wetLoad.id}`).value);
  }
  const handleSetCurrentWetloadNote = () => {
    if(currentWetLoadNote === wetLoad.id){
      return setCurrentWetLoadNote(null)
    }
    setCurrentWetLoadNote(wetLoad.id)
  };
  const handleRemoveNote = () => {
    removeWetLoadNote(wetLoad.note.id)
  }
  const handleEditNote = () => {
    editWetLoadNote(wetLoad.id, document.getElementById(`wetLoadNote${wetLoad.id}`).value)
  }

  return <GrowerColumnWrapper color={wl.growerColor()}>
    <div className="flex-grow-1">
      <div className="row">
        <div className="col">
          <div><b>{wl.growerName()}</b> - <span>{wl.fieldName()}</span> - <span>{wl.varietyName()}</span></div>
          <div><span style={{fontWeight: 'bold'}}>WC# {wl.weightCertNum()}</span> - <span>FB# {wl.freightBillNum()}</span> - <span>{wl.net() + ' lbs'}</span></div>
          <div><span>{wl.trailerSetName()}</span></div>
          <Time time={wetLoad.createdAt}/>
        </div>
        <div className="col">
          <DumpLoadForm pits={pits} isDumpingLoad={isDumpingLoad} dumpLoad={dumpLoad}/>
          <div className="d-flex justify-content-between">
          {hasNote ? <button disabled={isDumpingLoad} className="btn btn-danger py-2 px-3" onClick={handleRemoveNote}>Remove Note</button> :
             <button className={currentWetLoadNote === wetLoad.id ? "btn py-2 px-3 btn-danger" : "btn py-2 px-3 btn-info"} disabled={isDumpingLoad} onClick={handleSetCurrentWetloadNote}>
              {currentWetLoadNote === wetLoad.id ? "Cancel": "Add Note"}
            </button>}
            <button className="btn btn-link border border-info py-2 px-3" disabled={true}>Designate Receiver</button>
          </div>
        </div>
      </div>
      {currentWetLoadNote===wetLoad.id && !hasNote && <div className="row">
        <div className="col">
          <textarea className="form-control" placeholder="Add Notes" id={`wetLoadNote${wetLoad.id}`}>{wetLoad.note ? wetLoad.note.text :''}</textarea>
        </div>
        <div className="col-2">
          <button className="btn btn-primary" onClick={handleCreateNote}>Save</button>
        </div>
      </div>}
      {hasNote && <div className="row">
        <div className="col">
          <textarea className="form-control" id={`wetLoadNote${wetLoad.id}`} placeholder="Add Notes">{wetLoad.note ? wetLoad.note.text :''}</textarea>
        </div>
        <div className="col-2">
          <button className="btn btn-warning" onClick={handleEditNote}>Edit</button>
        </div>
      </div>}
    </div>
  </GrowerColumnWrapper>
}

function BodyColumn({children}) {
  return <div className="px-4">
    {children}
  </div>
}

function DragDropPortal({children, snapshot}) {
  if (!snapshot.isDragging) {
    return children;
  }

  const portalNode = document.getElementById('incoming-load-drag-drop-portal');
  return ReactDOM.createPortal(children, portalNode);
}

function LoadsSection({title, placeholder, droppableId, wetLoads, detailedLoadProps, ...props}) {
  return <div {...props}>
    <h6 className="text-center"><b>{title}</b></h6>
    <Droppable droppableId={droppableId}>{(provided, snapshot) => (
      <div ref={provided.innerRef} {...provided.droppableProps} style={{backgroundColor: snapshot.isDraggingOver ? 'lightgreen' : null}}>
        {wetLoads.length === 0 && <p className="text-center my-2">{placeholder}</p>}
        {wetLoads.map((wetLoad, index) => <Draggable key={wetLoad.id} draggableId={wetLoad.id} index={index}>{(provided, snapshot) => (
          <DragDropPortal snapshot={snapshot}>
            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
              <DetailedLoad wetLoad={wetLoad} {...detailedLoadProps} />
            </div>
          </DragDropPortal>
        )}</Draggable>)}
        {provided.placeholder}
      </div>
    )}</Droppable>
  </div>
}

function PrioritizedLoadsSection({title, placeholder, loadIds, wetLoadsById, droppableId, detailedLoadProps, ...props}) {
  return <LoadsSection
    title={title}
    placeholder={placeholder}
    droppableId={droppableId}
    detailedLoadProps={detailedLoadProps}
    wetLoads={loadIds.map(id => wetLoadsById[id])}
    {...props} />
}

function getDroppableStyle(provided, snapshot) {
  const borderStyle = "1px solid #ccc";

  return {
    borderTop: borderStyle,
    borderBottom: borderStyle,
    backgroundColor: snapshot.isDraggingOver ? "lightgreen" : null,
    ...provided.droppableProps.style
  }
}

function reorderList(list, startIndex, endIndex) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

function DetailedLoadListBody({groupedWetLoads, wetLoadsById, scheduledWetLoads, currentWetLoadNote, setCurrentWetLoadNote, createWetLoadNote, removeWetLoadNote, editWetLoadNote, pits, isDumpingLoad, dumpIncomingLoad, prioritizedLoadIds, prioritizeLoads}) {
  const onDragEnd = ({source, destination}) => {
    if (!destination) {
      return;
    }

    if (source.droppableId === "loads" && source.droppableId === destination.droppableId) {
      return; // do nothing, we don't allow sorting for the default priorities
    }

    // if same, just reorder
    if (source.droppableId === destination.droppableId) {
      const newList = reorderList(prioritizedLoadIds[source.droppableId], source.index, destination.index);
      prioritizeLoads({...prioritizedLoadIds, [source.droppableId]: newList});
    } else if (source.droppableId === "loads") {
      // we are moving from loads section to prioritized section
      const wetLoad = scheduledWetLoads[source.index];
      const loadIds = Array.from(prioritizedLoadIds[destination.droppableId]);
      loadIds.splice(destination.index, 0, wetLoad.id);
      prioritizeLoads({...prioritizedLoadIds, [destination.droppableId]: loadIds});
    } else if (destination.droppableId === "loads") {
      // we are moving from prioritized section to loads section
      const loadIds = Array.from(prioritizedLoadIds[source.droppableId]);
      loadIds.splice(source.index, 1);
      prioritizeLoads({...prioritizedLoadIds, [source.droppableId]: loadIds});
    } else {
      // we are moving from one prioritized section to another
      const sourceLoadIds = Array.from(prioritizedLoadIds[source.droppableId]);
      const destinationLoadIds = Array.from(prioritizedLoadIds[destination.droppableId]);
      const wetLoadId = sourceLoadIds[source.index];
      sourceLoadIds.splice(source.index, 1);
      destinationLoadIds.splice(destination.index, 0, wetLoadId);
      prioritizeLoads({...prioritizedLoadIds, [source.droppableId]: sourceLoadIds, [destination.droppableId]: destinationLoadIds});
    }
  };

  const detailedLoadProps = { pits, isDumpingLoad, dumpIncomingLoad, currentWetLoadNote, setCurrentWetLoadNote, createWetLoadNote, removeWetLoadNote, editWetLoadNote };
  return <DragDropContext onDragEnd={onDragEnd}>
    <div className="row position-relative">
      <div className="col col-md-2">
        <BodyColumn>
          <h4>Grouped Loads</h4>
          <div>
            {groupedWetLoads.map((gl) => <GroupedLoad key={gl.grower.id} groupedLoad={gl} Body={GroupedLoadThinBody} />)}
          </div>
        </BodyColumn>
      </div>
      <div className="col">
        <BodyColumn>
          <h4>Individual Loads</h4>
          <PrioritizedLoadsSection title="High Priority Loads" placeholder="Drag loads here to mark as higher priority" droppableId="highPriority" loadIds={prioritizedLoadIds.highPriority} wetLoadsById={wetLoadsById} detailedLoadProps={detailedLoadProps} />
          <LoadsSection title="Normal Priority Loads" placeholder="Drag loads here to mark as normal priority" droppableId="loads" wetLoads={scheduledWetLoads} detailedLoadProps={detailedLoadProps} />
          <PrioritizedLoadsSection title="Low Priority Loads" placeholder="Drag loads here to mark as lower priority" droppableId="lowPriority" loadIds={prioritizedLoadIds.lowPriority} wetLoadsById={wetLoadsById} detailedLoadProps={detailedLoadProps} />
        </BodyColumn>
      </div>
    </div>
  </DragDropContext>
}

const DetailedLoadListBodyContainer = connect((state) => {
  return {
    groupedWetLoads: getGroupedLoads(state),
    wetLoadsById: getWetLoadsByEntityId(state),
    scheduledWetLoads: getDefaultScheduledWetLoads(state),
    wetLoadNotes: state.wetside.wetLoadNotes,
    pits: getPits(state),
    isDumpingLoad: state.wetside.isDumpingLoad,
    currentWetLoadNote: state.wetside.currentNote,
    prioritizedLoadIds: getPrioritizedLoadIds(state),
  };
}, bindContainerActions('wetsideActions'))(DetailedLoadListBody);

export default function DetailLoadsListModal({isOpen, closeModal, width = "50%"}) {
  return <Modal isOpen={isOpen} size="lg" toggle={closeModal} className="full-width" style={{width}}>
    <div className="modal-header d-flex justify-content-between">
      <h4 className="pl-3">Detailed Loads List</h4>
      <button type="button" data-dismiss="modal" aria-hidden="true" className="close"><span className="s7-close" onClick={closeModal}/></button>
    </div>
    <ModalBody className="pt-1">
      <DetailedLoadListBodyContainer />
    </ModalBody>
  </Modal>
}
