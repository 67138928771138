import React from 'react';
import { DateTime } from 'luxon';

export default function Time({time}) {
  let currentTime = new Date();
  let currentMsTime = Date.parse(currentTime)
  let timePassed = ((((currentMsTime - Date.parse(time))/1000)/60)/60);

  return <div>
    <span>Created At: {DateTime.fromISO(time).toLocal().toLocaleString(DateTime.DATETIME_SHORT)}</span>
    <br/>
    {timePassed > 12 && <span className="text-danger">Time passed {'>'} {Math.floor(timePassed)} hours</span>}
    {(timePassed < 12 && timePassed > 3) && <span style={{color: "#d8cf31"}}>Time passed {'<'} {Math.floor(timePassed)} hours</span>}
    {timePassed < 3 && <span className="text-success">Time passed {'<'} {Math.floor(timePassed)} hours</span>}
  </div>
}

