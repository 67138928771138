import { URL } from 'whatwg-url';

const pathName = new URL(process.env.REACT_APP_API_BASE_URI).pathname || '/';

function buildIRI(prefix) {
    return (id) => pathName + prefix + id;
}

export const growerIRI = buildIRI('growers/');
export const fieldIRI = buildIRI('fields/');
export const varietyIRI = buildIRI('varieties/');
export const bankIRI = buildIRI('banks/');
export const binIRI = buildIRI('bins/');
export const binLotIRI = buildIRI('bin-lots/');
export const pitIRI = buildIRI('pits/');
export const binFillIRI = buildIRI('bin-fills/');
export const driverIRI = buildIRI('drivers/');
export const truckIRI = buildIRI('trucks/');
export const receiverIRI = buildIRI('receivers/');
export const trailerSetIRI = buildIRI('trailer-sets/');
export const storageLotIRI = buildIRI('storage-lots/');
export const storageBinIRI = buildIRI('storage-bins/');
export const fillOrderIRI = buildIRI('fill-orders/');
export const fillOrderItemIRI = buildIRI('fill-order-items/');
export const incomingLoadIRI = buildIRI('incoming-loads/');
export const noteIRI = buildIRI('notes/');
export const dryTruckIRI = buildIRI('dry-trucks/');
