import React from 'react';
import Select from 'react-select';
import FormRowWrapper from '../../common/component/FormRowWrapper';
import {connect} from 'react-redux';
import bindContainerActions from "../../bindContainerActions";
import { Modal, ModalBody } from 'reactstrap';
import { FullPanel, PanelBody, PanelHeader } from "../../common/component/panel";
import {InlineSpinner} from "../../common/component/Spinner";
import {isEmpty, keys, get, sortedUniq} from 'lodash';
import GrowerColumnWrapper from '../../common/component/GrowerColumnWrapper';
import * as outgoingLoadsSelectors from '../selector/outgoing-loads';
import {percentToPounds} from "../../bin/model/bin";
import Weight from "../../common/component/Weight";
import {numberChangeHandler, numberLengthHandler} from "../../common/component/inputOptions";

class OutgoingLoadForm extends React.Component {
  constructor(props) {
    super(props);
    this.firstInputRef = React.createRef();
  }

  selectStyles = {
    menuPortal: base => ({...base, zIndex: 9999})
  }

  componentDidMount() {
    setTimeout(() => {
      this.firstInputRef.current.focus();
    }, 800);
  }

  static selectedOption(options, selectedOptionValue) {
    let selectedOption = null;
    for (let option of options) {
      if (option.value === selectedOptionValue) {
        selectedOption = option;
        break;
      }
    }
    return selectedOption;
  }

  handleSubmit = () => {
    this.props.saveOutgoingLoad(this.props.dryLoadForm, this.props.selectedDryLoadId);
  };

  handleSelectOnChange = (name, option) => {
    const {inputOnChange, dryLoadForm } = this.props;
    if(name === 'receiver'){
      if(!window.confirm("This load already has a designated receiver, are you sure you want to change it?")){
        return Promise.resolve();
      }
    }
    inputOnChange(name, option.value);
  };

  handleInputOnChange = (event) => {
    this.props.inputOnChange(event.target.id, event.target.value);
  };

  render() {
    const {
      clearOutgoingLoad,
      tableFilters,
      selectedDryLoad,
      dryLoadForm,
      wetLoads,
      storageBins,
      dryStorage,
      addPartToOutgoingLoad,
      removePartFromDryLoadForm,
      clearReceiver,
      stepPartPercent,
      partPercentEmpty,
      setPartPoundsRemaining,
    } = this.props;

    const weightCert = dryLoadForm.weightCert;
    const manifest = dryLoadForm.manifest;
    const carrier = dryLoadForm.carrier;
    const driver = dryLoadForm.driver;
    const truck = dryLoadForm.truck;
    const trailers = dryLoadForm.trailers;
    const receiver = dryLoadForm.receiver !== '' ? dryLoadForm.receiver: dryLoadForm.parts[0] ?  dryLoadForm.parts[0].storageLot.designatedReceiver.id : '';
    const gross = dryLoadForm.gross;
    const tare = dryLoadForm.tare;
    const net = gross - tare;
    const parts = dryLoadForm.parts;

    const multiInputEvents = (e) => {
      numberChangeHandler(e);
      this.handleInputOnChange(e);
    }

    const inputLimitEvents = (e) => {
      numberChangeHandler(e);
      numberLengthHandler(e);
      this.handleInputOnChange(e);
    }

    const manifestLimitEvents = (e) => {
      numberChangeHandler(e);
      this.handleInputOnChange(e);
    }

    return <form>
      {selectedDryLoad ? (
        <div>
          <SelectedOutgoingLoadInfo grower={selectedDryLoad.grower} field={selectedDryLoad.field} variety={selectedDryLoad.variety} />
          <div className="row">
            <FormRowWrapper label='WeightCert' outerClass="col" id="weightCert">
              <input onChange={inputLimitEvents} maxLength={5} value={weightCert} id="weightCert" ref={this.firstInputRef} className="form-control" type="text" placeholder={weightCert}/>
            </FormRowWrapper>
            <FormRowWrapper label='Manifest (optional)' outerClass="col" id="manifest">
              <input onChange={manifestLimitEvents} value={manifest} id="manifest" className="form-control" type="text" placeholder={manifest}/>
            </FormRowWrapper>
            <FormRowWrapper label='Carrier (optional)' outerClass="col" id="carrier">
              <input onChange={this.handleInputOnChange} value={carrier} className="form-control" id="carrier" type="text" placeholder='Please Enter Carrier'/>
            </FormRowWrapper>
          </div>
        </div>
        ) : (
        <div>
          <div style={{maxHeight: 200}}>
            <OutgoingLoadParts wetLoads={wetLoads} storageBins={storageBins}
            dryStorage={dryStorage} dryLoadForm={dryLoadForm} addPartToOutgoingLoad={addPartToOutgoingLoad} inputChange={this.props.inputOnChange} />
          </div>
          <div className="row">
            <OutgoingLoadsCurrentParts parts={parts} removePartFromDryLoadForm={removePartFromDryLoadForm} clearReceiver={clearReceiver}
            stepPartPercent={stepPartPercent} partPercentEmpty={partPercentEmpty} setPartPoundsRemaining={setPartPoundsRemaining} />
          </div>
          <div className="row">
            <FormRowWrapper label='WeightCert' outerClass="col" id="weightCert">
              <input onChange={inputLimitEvents} maxLength={5} value={weightCert} id="weightCert" ref={this.firstInputRef} className="form-control" type="text" placeholder="Please Enter WeightCert"/>
            </FormRowWrapper>
            <FormRowWrapper label='Manifest (optional)' outerClass="col" id="manifest">
              <input onChange={manifestLimitEvents} value={manifest} id="manifest" className="form-control" type="text" placeholder="Please Enter Manifest"/>
            </FormRowWrapper>
            <FormRowWrapper label='Carrier (optional)' outerClass="col" id="carrier">
              <input onChange={this.handleInputOnChange} value={carrier} className="form-control" id="carrier" type="text" placeholder='Please Enter Carrier'/>
            </FormRowWrapper>
          </div>
        </div>
        )
      }
      <div className="row">
        <FormRowWrapper label='Driver (optional)' outerClass="col" id="driver">
          <input onChange={this.handleInputOnChange} value={driver} className="form-control" id="driver" type="text" placeholder='Please Enter Driver'/>
        </FormRowWrapper>
        <FormRowWrapper label='Truck (optional)' outerClass="col" id="truck">
          <input onChange={this.handleInputOnChange} value={truck} className="form-control" id="truck" type="text" placeholder='Please Enter Truck'/>
        </FormRowWrapper>
        <FormRowWrapper label='Trailers (optional)' outerClass="col" id="trailer">
          <input onChange={this.handleInputOnChange} value={trailers} className="form-control" id="trailers" type="text" placeholder='Please Enter Trailers'/>
        </FormRowWrapper>
      </div>
      <div className="row">
        <FormRowWrapper label='Receiver' outerClass="col col-lg-4" id="receiver">
          <Select classNamePrefix="react-select" id="receiver" options={tableFilters.receiver.options.slice(1)} onChange={(option) => (this.handleSelectOnChange('receiver', option))}
                  value={OutgoingLoadForm.selectedOption(tableFilters.receiver.options, receiver)} menuPortalTarget={document.body} styles={this.selectStyles}/>
        </FormRowWrapper>
      </div>
      <div className="row">
        <FormRowWrapper label='Gross (lbs)' outerClass="col" id="gross">
          <input onChange={multiInputEvents} value={gross} className="form-control" id="gross" type="text" placeholder='Please Enter Gross'/>
        </FormRowWrapper>
        <FormRowWrapper label='Tare (lbs)' outerClass="col" id="tare">
          <input onChange={multiInputEvents} value={tare} className="form-control" id="tare" type="text" placeholder='Please Enter Tare'/>
        </FormRowWrapper>
        <FormRowWrapper label='Net (lbs)' outerClass="col" id="net">
          <Weight pounds={net} line onChange={multiInputEvents} className="form-control" id="net" type="text" disabled/>
        </FormRowWrapper>
      </div>
      <div className="form-group text-right">
        <button type="button" onClick={this.handleSubmit} className="btn btn-space btn-primary" disabled={this.props.isLoading}>Save</button>
        &nbsp;
        {!this.props.selectedDryLoadId && <button type="button" onClick={clearOutgoingLoad} className="btn btn-space btn-danger" disabled={this.props.isLoading}>Clear</button>}
      </div>
    </form>;
  }
}

function SelectedOutgoingLoadInfo({grower, field, variety}) {
  return <GrowerColumnWrapper color={grower.color}>
    <div className={"flex-grow-1"}>
      <div className={"row mb-2"}>
        <span className="col">{grower.name}</span>
        <span className={"col"}>{field.name}</span>
        <span className={"col"}>{variety.name}</span>
      </div>
    </div>
  </GrowerColumnWrapper>
}

//Simlar to common/component/StorageBins with added form functionality
function OutgoingLoadParts({storageBins, dryStorage, addPartToOutgoingLoad, inputChange}) {
  return <FullPanel>
    <PanelHeader title="Overhead Bins" divider={true}/>
    <PanelBody>
      <InlineSpinner enabled={isEmpty(storageBins)}>
        <div className="row">
          {storageBins.map(sb => {
            sb.item = {};
            if(!dryStorage.items){
              return <div className="col" key={sb.id}><StorageBin name={sb.name}/></div>
            } else{
              dryStorage.items.forEach(dryStorageItem => {
                if(dryStorageItem.storageBin.id === sb.id){
                  sb.item = dryStorageItem;
                }
              })
              return (
                <div className="col" key={sb.id}>
                  <StorageBin name={sb.name} dryStorageItem={sb.item}
                  addPartToOutgoingLoad={addPartToOutgoingLoad} inputChange={inputChange}/>
                </div>
              )
            }
          })}
        </div>
      </InlineSpinner>
    </PanelBody>
  </FullPanel>;
}

function StorageBin({name, dryStorageItem, addPartToOutgoingLoad, inputChange}) {
  return <div>
    <h6 className={"mb-1"}>{name}</h6>
    <DryStorageBin dryStorageItem={dryStorageItem} addPartToOutgoingLoad={addPartToOutgoingLoad} inputChange={inputChange}/>
  </div>
}

function DryStorageBin({dryStorageItem, addPartToOutgoingLoad, inputChange}) {
  const onClickHandle = () => {
    addPartToOutgoingLoad(dryStorageItem.id);
    inputChange('receiver', dryStorageItem.designatedReceiver.id)
  }

  if(keys(dryStorageItem).length < 1){
    return <React.Fragment>&nbsp;</React.Fragment>
  } else {
    let fillOrderNumbers = [];
    (dryStorageItem.storageFills || []).forEach(fill => {
      fillOrderNumbers.push(fill.fillOrderNum)
    })
    return (
      <GrowerColumnWrapper color={dryStorageItem.grower.color} onClick={onClickHandle}>
        <div>
        <div><b>{dryStorageItem.grower.name}</b></div>
        <div>{dryStorageItem.field.name}</div>
        <div>{dryStorageItem.variety.name}</div>
        <div>FO.# {sortedUniq(fillOrderNumbers).join(", ")}</div>
        <Weight pounds={percentToPounds(dryStorageItem.netPercent)} units line/>
      </div>
      </GrowerColumnWrapper>
    )
  }
}

function OutgoingLoadsCurrentParts({parts, removePartFromDryLoadForm, stepPartPercent, setPartPoundsRemaining, partPercentEmpty, clearReceiver}) {
  if(isEmpty(parts)) {
    return <p className="mt-2">Select a bin to add an outgoing load part.</p>
  }
  else {
    return <div className="d-flex justify-content-between">
      {parts.map((part, idx) => {
        const removePart = () => {
          removePartFromDryLoadForm(idx);
            if(parts.length === 1) clearReceiver();
        };
        const stepPartPercentAction = (e) => {
          if (e.keyCode === 40) stepPartPercent('up', idx, part.storageLot.storageFills.length);
          else if (e.keyCode === 38) stepPartPercent('down', idx, part.storageLot.storageFills.length);
        };
        const conversePercent = (e) => {
          if(part.isStorageEmpty){
            partPercentEmpty(true, idx, part.storageLot.storageFills.length);
          } else if(!part.isStorageEmpty){
            partPercentEmpty(false, idx, part.storageLot.storageFills.length);
          }
        };
        const poundsRemainingChangeHandler = (e) => setPartPoundsRemaining(idx, parseInt(e.currentTarget.value));
        let fillOrderNumbers = [];
        (part.storageLot.storageFills || []).forEach(fill => {
          fillOrderNumbers.push(fill.fillOrderNum)
        });
        return (
          <div className="col container border border-secondary" key={idx}>
            <GrowerColumnWrapper color={part.storageLot.grower.color}>
              <div><b>{part.storageLot.grower.name}</b></div>
            </GrowerColumnWrapper>
            <div>{part.storageLot.field.name}</div>
            <div>{part.storageLot.variety.name}</div>
            <div>FO.# {sortedUniq(fillOrderNumbers).join(", ")}</div>
            Pounds Remaining: <input type="text" tabIndex={1} className="form-control p-1 text-center" value={part.poundsRemaining} placeholder={part.netPercent * 70} onKeyUp={stepPartPercentAction} onChange={poundsRemainingChangeHandler} />
            <div className="mt-1 mb-1 d-flex justify-content-around">
              <div className="form-check mr-3 mt-1" onClick={conversePercent}>
                <input className="form-check-input" type="checkbox" checked={part.isStorageEmpty} onChange={conversePercent} value="" />
                <label className="form-check-label">Is Storage Empty</label>
              </div>
              <button className="btn btn-danger p-2"  onClick={removePart}>Remove</button>
            </div>
          </div>
        )
      })}
    </div>
  }
}

const OutgoingLoadFormContainer = connect((state) => {
  const dryLoadForm = outgoingLoadsSelectors.dryLoad(state);
  return {
    isLoading: state.outgoingLoads.isLoading,
    dryLoadForm: dryLoadForm,
    tableFilters: state.outgoingLoads.tableFilters,
    selectedDryLoadId: state.outgoingLoads.selectedDryLoadId,
    selectedDryLoad: outgoingLoadsSelectors.selectedDryLoad(state),
    dryStorage: state.fillorders.dryStorage,
    storageBins: state.common.storageBins,
  };
}, bindContainerActions("outgoingLoadsActions"))(OutgoingLoadForm);

export default function OutgoingLoadFormModal({isOpen, closeAddDryLoadModal, selectedDryLoadId}) {
  return <Modal isOpen={isOpen} size="lg" toggle={closeAddDryLoadModal} className={"full-width"}>
    <div className="modal-header d-flex justify-content-between">
      { selectedDryLoadId ? <h3 className="pl-3">Update Outgoing Load: </h3> : <h3 className="pl-3">Add Outgoing Load: </h3> }
      <button type="button" data-dismiss="modal" aria-hidden="true" className="close"><span className="s7-close" onClick={closeAddDryLoadModal}/></button>
    </div>
    <ModalBody className="pt-1">
      <OutgoingLoadFormContainer/>
    </ModalBody>
  </Modal>
}
