import {createSelector} from "reselect";
import {growerIRI} from '../../common/model/iri';

export const getFieldFilterByGrower = createSelector(
  state => state.incomingLoads.tableFilters.field,
  state => {
    const selectedValue = state.incomingLoads.tableFilters.grower.selectedValue;
    return selectedValue ? [selectedValue.value] : []
  },
  filterFieldFilterByGrowerIds
);


export function filterFieldFilterByGrowerIds(fieldFilter, growerIds) {
  const filteredGrowerIds = growerIds.filter(x => x);
  if (!filteredGrowerIds.length) {
    return fieldFilter;
  }

  const growerIRIs = filteredGrowerIds.map(growerIRI);
  return {
    ...fieldFilter,
    options: fieldFilter.options.filter((opt) => {
      return !opt.meta || growerIRIs.includes(opt.meta.entity.grower);
    })
  }
}
