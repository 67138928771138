import React from 'react';
import classNames from 'classnames';
import {isAirDoorOpen} from "../model/bin";

function createAirDoorClickHandler(bin, closeAirDoor, openAirDoor) {
  const airDoorClickHandler = bin.airDoorOpen ? closeAirDoor : openAirDoor;
  return (e) => {
    e.stopPropagation();
    airDoorClickHandler(bin.id);
  };
}

export function AirDoorButton({bin, isOpen, className, onAirDoorClick, ...props}) {
  return <button type="button" className={classNames('bin', 'btn ' + ((isOpen && bin.binMoisture <= 8) ? "btn-danger" : (isOpen && bin.binMoisture >= 8)  ? "btn-success" : "text-light bg-secondary"), className)} {...props} onClick={onAirDoorClick}>{isOpen ? 'Close Air Door' : 'Open Air Door'}</button>
}

AirDoorButton.FromBin = ({bin, closeAirDoor, openAirDoor, ...props}) => <AirDoorButton bin={bin} isOpen={isAirDoorOpen(bin)} onAirDoorClick={createAirDoorClickHandler(bin, closeAirDoor, openAirDoor)} {...props}/>