import React from "react";
import {Panel, PanelBody, PanelHeader} from "../../common/component/panel";
import {connect} from 'react-redux';
import { DateTime } from 'luxon';
import {
    getAirDoorEventsByBinLot,
    getBinLotsByBin,
    getBinsById,
    getSelectedBinId
} from "../../common/selector/bin";
import {percentToPounds} from "../../bin/model/bin";
import {binIRI, binLotIRI} from "../../common/model/iri";

function dateConversion(date) {
  return DateTime.fromISO(date).toLocal().toLocaleString(DateTime.DATETIME_SHORT);
}

function BinEventsTable({bin, binLot, airDoorEvents}) {
  const binFillList = binLot ? binLot.binFills : [];
  const airEventsList = airDoorEvents ? airDoorEvents : null;
  return(
    <div style={{overflowY:'auto', height: '70px'}}>
      { bin ? <div >
        {binFillList.map((binFill, index) => {
          const binFillTime = dateConversion(binFill.createdAt);
          const binPounds = percentToPounds(binFill.percentFull);
          return (
            <div style={{display: "flex", justifyContent: 'space-between'}} key={index}>
              <p>Fill# {binFill.id}</p>
              <p>{binPounds} lbs</p>
              <p>{binFillTime}</p>
              <p><b>WC# {binFill.weightCert}</b></p>
            </div>
          );
        })}
        <div >
          {airEventsList && airDoorEvents.map((event, idx) => {
            let eventTime = dateConversion(event.createdAt);
            return (
              <div style={{display: "flex", justifyContent: 'space-between'}} key={idx}>
                <p>Airdoor event</p>
                <p>{event.state} </p>
                <p>{eventTime}</p>
                <p>Moisture: {event.binMoisture.toFixed(2)}%</p>
              </div>
            )
          })}
        </div>
      </div> :
      <div>
        Select/hover over a bin to view bin events.
      </div>}
    </div>
  )
}

const BinEventsContainer = connect((state) => {
  const binId = getSelectedBinId(state);
  if (!binId) {
    return {};
  }
  const binLot = getBinLotsByBin(state)[binIRI(binId)];
  return {
    binLot,
    bin: getBinsById(state)[binIRI(binId)],
    airDoorEvents: (binLot ? getAirDoorEventsByBinLot(state)[binLotIRI(binLot.id)] : null) || [],
  }
})(BinEventsTable);

function BinEvents() {
  return (
    <Panel style={{marginBottom: "0.5rem"}}>
      <PanelHeader title="Bin Events" divider={true}/>
      <PanelBody>
        <BinEventsContainer />
      </PanelBody>
    </Panel>
  );
}

export default BinEvents;
