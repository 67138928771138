import React from 'react';
import Select from 'react-select';
import TrailerChoiceSection from './TrailerChoiceSection';
import FormRowWrapper from '../../common/component/FormRowWrapper';
import {connect} from 'react-redux';
import bindContainerActions from "../../bindContainerActions";
import { Modal, ModalBody } from 'reactstrap';
import {numberChangeHandler, numberLengthHandler} from "../../common/component/inputOptions";
import {filterFieldFilterByGrowerIds} from '../selector/incoming-loads';

class IncomingLoadForm extends React.Component {
  constructor(props) {
    super(props);
    this.firstInputRef = React.createRef();
  }

  componentDidMount() {
    setTimeout(() => {
      this.firstInputRef.current.focus();
    }, 800);
  }

  static selectedOption(options, selectedOptionValue) {
    let selectedOption = null;
    for (let option of options) {
      if (option.value === selectedOptionValue) {
        selectedOption = option;
        break;
      }
    }
    return selectedOption;
  }

  selectStyles = {
    menuPortal: base => ({...base, zIndex: 9999})
  }

  handleSubmit = () => {
    this.props.saveIncomingLoad(this.props.wetLoadForm, this.props.selectedWetLoadId);
  };

  handleSelectOnChange = (name, option) => {
    this.props.inputOnChange(name, option.value);
  };

  handleInputOnChange = (event) => {
    this.props.inputOnChange(event.target.id, event.target.value);
  };

  handleRadioOnChange = (event) => {
    this.props.inputOnChange(event.target.name, event.target.value);
  };

  printFiltersCheck(value, filters) {
    for(let i = 0; i < filters.length; i++){
      if(filters[i].value === value){
        return filters[i].label;
      }
    }
    return ''
  }

  render() {
    const {clearIncomingLoad, tableFilters, checkPrint} = this.props;
    console.log("over here", checkPrint)
    const selectedWetLoad = this.props.wetLoadForm;

    const weightCert = selectedWetLoad.weightCert;
    const freightBill = selectedWetLoad.freightBill;
    const grower = selectedWetLoad.grower;
    const field = selectedWetLoad.field;
    const variety = selectedWetLoad.variety;
    const gross = selectedWetLoad.gross;
    const driver = selectedWetLoad.driver;
    const tare = selectedWetLoad.tare;
    const truck = selectedWetLoad.truck;
    const trailerSet = selectedWetLoad.trailerSet;
    const designatedReceiver = selectedWetLoad.designatedReceiver;
    const splitTrailerType = selectedWetLoad.splitTrailerType;
    const net = gross - tare;

    const scalesTime = new Date(selectedWetLoad.scaleTime || new Date()).toLocaleString()
    const printParams = {
      weightCert : weightCert,
      scalesTime: scalesTime,
      net: net,
      grower: grower ? this.printFiltersCheck(Number(grower), tableFilters.grower.options) : '',
      field: field ? this.printFiltersCheck(Number(field), tableFilters.field.options) : '',
      variety: variety ? this.printFiltersCheck(Number(variety), tableFilters.variety.options) : ''
    };

    const queryParams = `weightcert=${printParams.weightCert}&`+
      `scalestime=${printParams.scalesTime}&`+
      `net=${printParams.net}&`+
      `grower=${printParams.grower}&`+
      `field=${printParams.field}&`+
      `variety=${printParams.variety}`
    ;
    const printLink = process.env.REACT_APP_API_BASE_URI+`/print-incoming?${queryParams}`

    const multiInputEvents = (e) => {
      numberChangeHandler(e);
      this.handleInputOnChange(e);
    }

    const inputLimitEvents = (e) => {
      numberChangeHandler(e);
      numberLengthHandler(e);
      this.handleInputOnChange(e);
    }

    const saveAndPrint = () => {
      this.handleSubmit();
      checkPrint(printLink);
    }

    console.log(selectedWetLoad);
    return <form>
      {this.props.selectedWetLoadId ? (
        <div className="row">
          <FormRowWrapper label="WeightCert" id="weightCert" outerClass="col">
            <div id="weightCert" ref={this.firstInputRef} className="form-control" type="text">{weightCert}</div>
          </FormRowWrapper>
          <FormRowWrapper label="Freightbill" id="freightBill" outerClass="col">
            <div className="form-control" id="freightBill" type="text">{freightBill}</div>
          </FormRowWrapper>
        </div>
        ) : (
          <div className="row">
            <FormRowWrapper label="WeightCert" id="weightCert" outerClass="col">
              <input onChange={inputLimitEvents} maxLength={5} value={weightCert} id="weightCert" ref={this.firstInputRef} className="form-control" type="text"
                    placeholder="Please Enter WeightCert"/>
            </FormRowWrapper>
            <FormRowWrapper label="Freightbill (optional)" id="freightBill" outerClass="col">
              <input onChange={inputLimitEvents} maxLength={5} value={freightBill} className="form-control" id="freightBill" type="text" placeholder="Please Enter Freightbill"/>
            </FormRowWrapper>
          </div>
        )
     }
      <div className="row">
        <FormRowWrapper label="Grower" id="grower" outerClass="col">
          <Select classNamePrefix="react-select" id="grower" options={tableFilters.grower.options.slice(1)} onChange={(option) => (this.handleSelectOnChange('grower', option))}
                  value={IncomingLoadForm.selectedOption(tableFilters.grower.options, grower)} menuPortalTarget={document.body} styles={this.selectStyles}/>
        </FormRowWrapper>
        <FormRowWrapper label="Field" id="field" outerClass="col">
          <Select classNamePrefix="react-select" id="field" options={filterFieldFilterByGrowerIds(tableFilters.field, grower ? [grower] : []).options.slice(1)} onChange={(option) => (this.handleSelectOnChange('field', option))}
                  value={IncomingLoadForm.selectedOption(tableFilters.field.options, field)} menuPortalTarget={document.body} styles={this.selectStyles}/>
        </FormRowWrapper>
        <FormRowWrapper label="Variety" id="variety" outerClass="col">
          <Select classNamePrefix="react-select" id="variety" options={tableFilters.variety.options.slice(1)} onChange={(option) => (this.handleSelectOnChange('variety', option))}
                  value={IncomingLoadForm.selectedOption(tableFilters.variety.options, variety)} menuPortalTarget={document.body} styles={this.selectStyles}/>
        </FormRowWrapper>
      </div>
      <div className="row">
        <FormRowWrapper label="Driver" id="driver" outerClass="col">
          <Select classNamePrefix="react-select" id="driver" options={tableFilters.driver.options.slice(1)} onChange={(option) => (this.handleSelectOnChange('driver', option))}
                  value={IncomingLoadForm.selectedOption(tableFilters.driver.options, driver)} menuPortalTarget={document.body} styles={this.selectStyles}/>
        </FormRowWrapper>
        <FormRowWrapper label="Truck" id="truck" outerClass="col">
          <Select classNamePrefix="react-select" id="truck" options={tableFilters.truck.options.slice(1)} onChange={(option) => (this.handleSelectOnChange('truck', option))}
                  value={IncomingLoadForm.selectedOption(tableFilters.truck.options, truck)} menuPortalTarget={document.body} styles={this.selectStyles}/>
        </FormRowWrapper>
        <FormRowWrapper label="Trailers" id="trailerSet" outerClass="col">
          <Select classNamePrefix="react-select" id="trailerSet" options={tableFilters.trailerSet.options.slice(1)} onChange={(option) => (this.handleSelectOnChange('trailerSet', option))}
                  value={IncomingLoadForm.selectedOption(tableFilters.trailerSet.options, trailerSet)} menuPortalTarget={document.body} styles={this.selectStyles}/>
        </FormRowWrapper>
      </div>
      <div className="row">
        <FormRowWrapper label="Receivers (optional)" id="receiver" outerClass="col">
          <Select classNamePrefix="react-select" id="receiver" options={tableFilters.receiver.options.slice(1)} onChange={(option) => (this.handleSelectOnChange('designatedReceiver', option))}
                  value={designatedReceiver ? IncomingLoadForm.selectedOption(tableFilters.receiver.options, designatedReceiver) : null} menuPortalTarget={document.body} styles={this.selectStyles}/>
        </FormRowWrapper>
        <TrailerChoiceSection onChange={this.handleRadioOnChange} splitTrailerType={splitTrailerType}/>
      </div>

      <div className="row">
        <FormRowWrapper label="Gross (lbs)" id="gross" outerClass="col">
          <input onChange={multiInputEvents} value={gross} className="form-control" id="gross" type="text" placeholder="Please Enter Gross"/>
        </FormRowWrapper>
        <FormRowWrapper label="Tare (lbs)" id="tare" outerClass="col">
          <input onChange={multiInputEvents} value={tare} className="form-control" id="tare" type="text" placeholder="Please Enter Tare"/>
        </FormRowWrapper>
        <FormRowWrapper label='Net (lbs)' outerClass="col" id="net">
          <input onChange={multiInputEvents} value={net} className="form-control" id="net" type="text" disabled/>
        </FormRowWrapper>
      </div>

      <div className="form-group text-right">
        <button type="button" onClick={saveAndPrint} className="btn btn-space btn-primary" disabled={this.props.isLoading}>Save & Print</button>
        &nbsp;
        {!this.props.selectedWetLoadId && <button type="button" onClick={clearIncomingLoad} className="btn btn-space btn-danger" disabled={this.props.isLoading}>Clear</button>}
        &nbsp;
        <button type="button" onClick={this.handleSubmit} className="btn btn-space btn-info" disabled={this.props.isLoading}>Save</button>
      </div>
    </form>;
  }
}

const IncomingLoadFormContainer = connect((state) => {
  return {
    isLoading: state.incomingLoads.isLoading,
    wetLoadForm: state.incomingLoads.wetLoadForm,
    tableFilters: state.incomingLoads.tableFilters,
    selectedWetLoadId:  state.incomingLoads.selectedWetLoadId
  };
}, bindContainerActions("incomingLoadsActions"))(IncomingLoadForm);

export default function IncomingLoadFormModal({isOpen, closeAddWetLoadModal, selectedWetLoadId}) {
  return <Modal isOpen={isOpen} size="lg" toggle={closeAddWetLoadModal} className={"full-width"}>
    <div className="modal-header d-flex justify-content-between">
      <h3 className="pl-3">{!selectedWetLoadId ?  'Add Incoming Load: ' : 'Update Incoming Load: ' }</h3>
      <button type="button" data-dismiss="modal" aria-hidden="true" className="close"><span className="s7-close" onClick={closeAddWetLoadModal}/></button>
    </div>
    <ModalBody className="pt-1">
      <IncomingLoadFormContainer/>
    </ModalBody>
  </Modal>
}
