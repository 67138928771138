import React from 'react';
import {connect} from 'react-redux';
import {PaginationFooter, PaginationFooterWrapper, Table} from "../../common/component/table";
import bindContainerActions from "../../bindContainerActions";
import Spinner from "../../common/component/Spinner";
import GrowerColumnWrapper from "../../common/component/GrowerColumnWrapper";
import {DateTime} from 'luxon';
import Weight from "../../common/component/Weight";
import {numberChangeHandler} from "../../common/component/inputOptions";

function dryLoadItemToRow({removeDryLoad, setReceiverNet}, dryLoad, index, items) {
  const removeDryLoadHandler = (e) =>{
    e.stopPropagation();
    removeDryLoad(dryLoad.id);
  }

  const setReceiverNetHandler = (e) => {
    if (e.target.value === '' || null) return;
    setReceiverNet(parseInt(e.target.value), dryLoad.id);
  }

  const keyUpHandle = (e) => {
    if(e.keyCode === 38){
      if(e.target.tabIndex > 1) {
        document.querySelector(`.dry-load-receiver-net-input[data-index="${e.target.tabIndex - 1}"]`).focus();
      }
    }
    if(e.keyCode === 40){
      if(e.target.tabIndex < items.length) {
        document.querySelector(`.dry-load-receiver-net-input[data-index="${e.target.tabIndex + 1}"]`).focus();
      }
    }
  }

  const onClickHandler = (e) => {
    e.stopPropagation();
  }

  let gross = dryLoad.gross || 0;
  let tare = dryLoad.tare || 0;
  let net = gross-tare || 0;
  let receiverNet = dryLoad.receiverNet;
  let difference = receiverNet - net;

  return [
    dryLoad,
    DateTime.fromISO(dryLoad.createdAt).toFormat('yy-MM-dd HH:mm'),
    dryLoad.weightCert.num,
    dryLoad.manifest ? dryLoad.manifest.num: '',
    <GrowerColumnWrapper color={dryLoad.grower.color}>{dryLoad.grower.name}</GrowerColumnWrapper>,
    dryLoad.field.name,
    dryLoad.variety.name,
    dryLoad.driver,
    dryLoad.truck,
    dryLoad.trailers,
    dryLoad.carrier,
    dryLoad.receiver.name,
    gross,
    tare,
    <Weight pounds={net} units={false}/>,
    <input key={dryLoad.id} data-index={index+1} tabIndex={index+1} className="input-group text-center dry-load-receiver-net-input" placeholder={receiverNet} type="text" style={{border:"none"}}
    onBlur={setReceiverNetHandler} onClick={onClickHandler} onKeyUp={keyUpHandle} onChange={numberChangeHandler}/>,
    <Weight pounds={difference} units={false} className="text-danger"/>,
    <div className="text-center"><button type="button" className="btn px-3 btn-danger" onClick={removeDryLoadHandler}>X</button></div>
  ];
}

function DryLoadList({isLoading, dryLoadResults, changePage, removeDryLoad, openAddDryLoadModal, setReceiverNet}) {
  let index = 0;
  return <div>
    {isLoading && <Spinner enabled={isLoading} className="my-2"/>}
    <Table openFormModal={openAddDryLoadModal}
      header={["id", "Date", "Weight Cert", "Manifest", "Grower", "Field", "Variety", "Driver", "Truck", "Trailers", "Carrier", "Receiver", "Gross (lbs)", "Tare (lbs)", "Net (lbs)", "Receiver Net (lbs)", "Difference (lbs)", ""]}
      rows={(dryLoadResults.items || []).map(dryLoadItemToRow.bind(null, {removeDryLoad, setReceiverNet}), index, dryLoadResults) || []}/>
    <PaginationFooterWrapper>
      {dryLoadResults.totalItems > 0 && <PaginationFooter total={dryLoadResults.totalItems} page={dryLoadResults.currentPage} perPage={dryLoadResults.perPage} goToPage={changePage}/>}
    </PaginationFooterWrapper>
  </div>
}

export default connect((state) => {
  return {
    dryLoadResults: state.outgoingLoads.dryLoadResults,
    isLoading: state.outgoingLoads.isLoading,
  };
}, bindContainerActions('outgoingLoadsActions'))(DryLoadList);
