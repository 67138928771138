import {createSelector} from "reselect";
import {DateTime} from 'luxon';
import {getHoursInBinLot} from "../../bin/model/bin";
import {set} from "lodash";
import {bankIRI, binIRI, binLotIRI} from "../model/iri";

export function createWetLoadsStats(wetLoads) {
  return wetLoads.reduce((acc, wetLoad) => {
    acc.totalLoads += 1;
    acc.totalLbs += wetLoad.net;
    return acc;
  }, {totalLoads: 0, totalLbs: 0});
}

export const getBins = state => state.wetside.bins || [];
export const getBinMoistures = state => state.wetside.binMoistures || [];
export const getAirDoorEvents = state => state.wetside.airDoorEvents || [];
export const getBinLots = state => (state.wetside.binLots || []).map(bl => set(bl, 'hoursInBin', getHoursInBinLot(bl)));
export const getBinsAreLoaded = createSelector(
  (state) => state.wetside.bins,
  (state) => state.wetside.binLots,
  (bins, binLots) => bins !== null && binLots !== null
);

export const getBanksById = createSelector(
  state => state.wetside.banks || [],
  banks => banks.reduce((acc, bank) => {
    acc[bankIRI(bank.id)] = bank;
    return acc;
  }, new Map())
);
export const getSelectedBinId = createSelector(
  state => state.wetside.lockedBinId,
  state => state.wetside.selectedBinId,
  (lockedBinId, selectedBinId) => selectedBinId || lockedBinId
);
export const getBinsByBank = createSelector(
  getBins,
  bins => bins.reduce((acc, bin) => {
    const bankId = bin.bank;
    if(bankId in acc){
      acc[bankId].push(bin);
    } else{
      acc[bankId] = [bin];
    }
    return acc;
  }, {})
);
export const getBinsById = createSelector(
  getBins,
  bins => bins.reduce((acc, bin) => {
    acc[binIRI(bin.id)] = bin;
    return acc;
  }, {})
);
export const getBinLotsById = createSelector(
  getBinLots,
  binLots => binLots.reduce((acc, bl) =>{
    acc[binLotIRI(bl.id)] = bl;
    return acc;
  }, {})
);

export const getBinLotsByBin = createSelector(
  getBinLots,
  binLots => binLots.reduce((acc, binLot) => {
    acc[binLot.bin] = binLot;
    return acc;
  }, {})
);
export const getBinMoisturesByBin = createSelector(
  getBinMoistures,
  binMoistures => binMoistures.reduce((acc, binMoisture) => {
    const binId = binMoisture.bin;
    if(binId in acc){
      acc[binId].push(binMoisture);
    } else {
      acc[binId] = [binMoisture];
    }
    return acc;
  }, {})
);
export const getAirDoorEventsByBinLot = createSelector(
  getAirDoorEvents,
  airDoorEvents => airDoorEvents.reduce((acc, airDoorEvent) => {
    const binLotId = airDoorEvent.binLot;
    if(binLotId in acc){
      acc[binLotId].push(airDoorEvent);
    } else {
      acc[binLotId] = [airDoorEvent];
    }
    return acc;
  }, {})
);

export const getLoadsInYardStats = createSelector(
  state => state.wetside.wetLoads || [],
  createWetLoadsStats
);
