import React from 'react';
import compose from 'lodash/fp/compose';
import {connect} from 'react-redux';
import bindContainerActions from "../../bindContainerActions";
import FillOrderPanelSection from "./FillOrderPanelSection";
import {Modal, Button, ModalBody, ModalHeader, ModalFooter} from 'reactstrap';
import {withRouter, Link} from 'react-router-dom'
import {getCurrentFillOrder} from "../selector/fillorders";
import {getReceivers, getStorageBins} from "../../common/selector/common";
import GrowerColumnWrapper from "../../common/component/GrowerColumnWrapper";
import {GrowerHeader, GrowerSubHeader, GrowerItems} from "./ShowFillOrderSection";
import {mapLbsReq} from "./FillOrderCollapse";
import {get} from 'lodash';
import {StorageBinSelection, ReceiverSelection} from './EditFillOrderSection';

const LinkButton = withRouter(({history, to, onClick, children, ...props}) => {
  return <Button color="dark" onClick={() => history.push(to)} {...props}>{children}</Button>
});

class FillOrderUpdateModal extends React.Component {
  constructor(props) {
    super(props);
		this.toggle = this.toggle.bind(this);
		this.state={modal: true};
  }

  toggle() {
    this.setState(state => ({modal: !state.modal}))
  }

  render() {
    return (
			<div>
      	<Modal isOpen={this.state.modal}>
					<ModalHeader className="modal-header d-flex justify-content-between px-4">Grower Info</ModalHeader>
        	<GrowerModalBody props={this.props}/>
					<ModalFooter>
						<LinkButton to={"/fill-orders"}>Close</LinkButton>
					</ModalFooter>
      	</Modal>
			</div>
    )
  }
}

function GrowerModalBody({props}){
	let currentFillOrderInfo = {
    id: props.match.params.fillOrderId,
    name: get(props.currentFillOrder, "items[0].binLot.grower.name"),
    color: get(props.currentFillOrder, "items[0].binLot.grower.color"),
    variety: get(props.currentFillOrder, "items[0].binLot.variety.name"),
    field: get(props.currentFillOrder, "items[0].binLot.field.name"),
    storageBin: get(props.currentFillOrder, "storageBin.name"),
    receiver: get(props.currentFillOrder, "receiver.name"),
    items: get(props.currentFillOrder, "items")
  }
  let lbs = mapLbsReq(get(props.currentFillOrder, "items"));
  const updateFillOrder = () => props.updateCurrentFillOrder(props.currentFillOrder);
  return (
    <ModalBody>
      {props.currentFillOrder ?
      <FillOrderPanelSection title="Edit Fill Order" backUrl={`/fill-orders/show/${currentFillOrderInfo.id}`} style={{height:"500px"}}>
			  <GrowerColumnWrapper color={currentFillOrderInfo.color}>
      	  <GrowerHeader grower={currentFillOrderInfo.name} id={currentFillOrderInfo.id} variety={currentFillOrderInfo.variety}/>
    	  </GrowerColumnWrapper>
        <GrowerSubHeader grower={currentFillOrderInfo} lbsreq={lbs.poundsRequested} lbsrem={lbs.poundsRemaining}/>
        <div className="row mb-2">
          <div className="col col-md-6">
            <StorageBinSelection selectedStorageBin={props.updateStorageBin} storageBins={props.storageBins} placeholder={currentFillOrderInfo.storageBin}/>
          </div>
          <div className="col col-md-6">
            <ReceiverSelection selectedReceiver={props.updateReceiver} receivers={props.receivers} placeholder={currentFillOrderInfo.receiver} />
          </div>
        </div>
        <GrowerItems items={currentFillOrderInfo.items} currentFillOrder={props.currentFillOrder} removeFillOrderItem={props.removeFillOrderItem} removeFillOrder={props.removeFillOrder}/>
        <div className="text-right">
          <button type="button" className="btn btn-success" onClick={updateFillOrder}>Update Fill Order</button>
        </div>
      </FillOrderPanelSection>:
      <FillOrderPanelSection title="Edit Fill Order" backUrl="/fill-orders">
        <p>This fill order does not exist</p>
      </FillOrderPanelSection>
     }
		</ModalBody>
  )
}

export default compose(
  withRouter,
  connect((state) => {
    const storageBins = getStorageBins(state);
    const receivers = getReceivers(state);
    return {
      storageBins: storageBins,
      receivers: receivers,
      currentFillOrder: getCurrentFillOrder(state)
    }
}, bindContainerActions('fillordersActions'))
)(FillOrderUpdateModal);
