import React from "react";
import Layout from "../../layout/component/Layout";
import { connect } from 'react-redux';
import deploymentInfo from '../../deployment.json';
import Select from "react-select";
import bindContainerActions from "../../bindContainerActions";

function IncomingLoadsWidget({ stats }) {
  return <div className="widget widget-fullwidth mb-0">
    <div className="widget-head">
      <span className="title">Incoming Loads</span>
    </div>
    <div className="row widget-info">
      <div className="col-6 counter-block">
        <span data-toggle="counter" data-end="735" className="counter">{stats.loads}</span>
        <span className="title">Loads Received</span>
      </div>
      <div className="col-6 counter-block">
        <span data-toggle="counter" data-end="73" data-suffix="%" className="counter">{stats.net}</span>
        <span className="title">Tons Received</span>
      </div>
    </div>
  </div>
}

function OutgoingLoadsWidget() {
  return <div className="widget widget-fullwidth mb-0">
    <div className="widget-head">
      <span className="title">Outgoing Loads</span>
    </div>
    <div className="row widget-info">
      <div className="col counter-block">
        <span data-toggle="counter" data-end="735" className="counter">7</span>
        <span className="title">Loads Today</span>
      </div>
      <div className="col counter-block">
        <span data-toggle="counter" data-end="73" data-suffix="%" className="counter">96</span>
        <span className="title">Loads This Year</span>
      </div>
      <div className="col counter-block">
        <span data-toggle="counter" data-end="73" data-suffix="%" className="counter">10.5h</span>
        <span className="title">Avg. Process Time</span>
      </div>
    </div>
  </div>
}

function WebsocketStatusWidget({ connectionStatus, refreshedAt }) {
  return <div className="widget widget-fullwidth mb-0">
    <div className="widget-head">
      <span className="title">Websocket Status</span>
    </div>
    <div className="row widget-info">
      <div className="col counter-block">
        <span data-toggle="counter" data-end="735" className="counter">{connectionStatus || 'N/A'}</span>
        <span className="title">Connection Status</span>
      </div>
      <div className="col counter-block">
        <span data-toggle="counter" data-end="73" data-suffix="%" className="counter">{refreshedAt || 'N/A'}</span>
        <span className="title">Refreshed At</span>
      </div>
    </div>
  </div>
}

function AppDeploymentInfoWidget() {
  return <div className="widget widget-fullwidth mb-0">
    <div className="widget-head">
      <span className="title">App Deployment Info</span>
    </div>
    <div className="row widget-info">
      <div className="col counter-block">
        <span data-toggle="counter" data-end="73" data-suffix="%" className="counter">
          <a target="_blank" href={"https://gitlab.bighead.net/ghmf/huller/huller/-/commit/"+deploymentInfo.ref}>{deploymentInfo.ref}</a>
        </span>
        <span className="title">Ref</span>
      </div>
      <div className="col counter-block">
        <span data-toggle="counter" data-end="73" data-suffix="%" className="counter">{deploymentInfo.date}</span>
        <span className="title">Released</span>
      </div>
    </div>
  </div>
}

function optionFromValue(season) {
  return {value: season, label: season};
}
function CurrentSeasonWidget({ currentSeason, allSeasons, setCurrentSeason }) {
  function handleOnChange(selected) {
    setCurrentSeason(selected.value);
  }

  return <div className="widget widget-fullwidth mb-0">
    <div className="widget-head">
      <span className="title">Season</span>
    </div>
    <div className="row widget-info">
      <div className="col counter-block">
        <span data-toggle="counter" data-end="73" data-suffix="%" className="counter">
          {currentSeason}
        </span>
        <span className="title">Current Season</span>
      </div>
      <div className="col counter-block">
        <div className="px-4">
          <Select classNamePrefix="react-select" options={allSeasons.map(optionFromValue)} value={optionFromValue(currentSeason)} onChange={handleOnChange}/>
        </div>
        <span className="title">Change Season</span>
      </div>
    </div>
  </div>
}

function HomePage({ homePage, incomingLoads, setCurrentSeason }) {
  return <Layout>
    <div>
      <div className="row">
        <div className="col-lg-6 mb-2"><IncomingLoadsWidget {...incomingLoads} /></div>
        <div className="col-lg-6 mb-2"><WebsocketStatusWidget {...homePage.websocketStatus } /></div>
        <div className="col-lg-6 mb-2"><AppDeploymentInfoWidget/></div>
        <div className="col-lg-6 mb-2"><CurrentSeasonWidget {...homePage.season} setCurrentSeason={setCurrentSeason} /></div>
      </div>
    </div>
  </Layout>
}

export default connect(state => {
  return {
    homePage: state.homePage,
    incomingLoads: {
      stats: state.incomingLoads.stats
    }
  };
}, bindContainerActions('homePageActions'))(HomePage);
