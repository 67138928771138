import createActionType from '../../createActionType';
import {produce} from 'immer';
import {listenOnceLoggedIn} from "../../common/subscribers";
import {canReloadPrioritizedLoadIds} from "../../common/selector/wetside";
import { getCurrentSeason } from '../../home/selector/season';

export const SET_BANKS = createActionType('wetside', 'SET_BANKS');
export const SET_BINS = createActionType('wetside', 'SET_BINS');
export const SET_BIN_LOTS = createActionType('wetside', 'SET_BIN_LOTS');
export const SET_BIN_MOISTURES = createActionType('wetside', 'SET_BIN_MOISTURES');
export const SET_AIR_DOOR_EVENTS = createActionType('wetside', 'SET_AIR_DOOR_EVENTS');
export const SET_DUMPED_LOADS = createActionType('wetside', 'SET_DUMPED_LOADS');
export const SET_WET_LOADS = createActionType('wetside', 'SET_WET_LOADS');
export const OPEN_DETAILED_LOADS_MODAL = createActionType('wetside', 'OPEN_DETAILED_LOADS_MODAL');
export const CLOSE_DETAILED_LOADS_MODAL = createActionType('wetside', 'CLOSE_DETAILED_LOADS_MODAL');
export const OPEN_AIR_DOOR = createActionType('wetside', 'OPEN_AIR_DOOR');
export const CLOSE_AIR_DOOR = createActionType('wetside', 'CLOSE_AIR_DOOR');
export const LOCK_BIN = createActionType('wetside', 'LOCK_BIN');
export const SELECT_BIN = createActionType('wetside', 'SELECT_BIN');
export const REMOVE_SELECTED_BIN = createActionType('wetside', 'REMOVE_SELECTED_BIN');
export const CURRENT_WETLOAD_NOTE = createActionType('wetside', 'CURRENT_WETLOAD_NOTE');
export const SET_IS_DUMPING_LOAD = createActionType('wetside', 'SET_IS_DUMPING_LOAD');
export const SET_PRIORITIZED_LOAD_IDS = createActionType('wetside', 'SET_PRIORITIZED_LOAD_IDS');
export const SET_RELOAD_PRIORITIZED_LOAD_CANCEL_TIMEOUT_ID = createActionType('wetside', 'SET_RELOAD_PRIORITIZED_LOAD_CANCEL_TIMEOUT_ID');

  // reloadPrioritizedLoadCancelTimeoutId

/** @param {ApiClient} apiClient */
export function wetsideActions(apiClient, commonActions, wetsideCommonActions, dryerBinModalActions) {
  function setBins(bins) {
    return {type: SET_BINS, bins};
  }

  function setBanks(banks) {
    return {type: SET_BANKS, banks};
  }

  function setBinMoistures(binMoistures) {
    return {type: SET_BIN_MOISTURES, binMoistures};
  }

  function setAirDoorEvents(airDoorEvents) {
    return {type: SET_AIR_DOOR_EVENTS, airDoorEvents};
  }

  function setDumpedLoads(dumpedLoads) {
    return {type: SET_DUMPED_LOADS, dumpedLoads};
  }
  function setWetLoads(wetLoads) {
    return {type: SET_WET_LOADS, wetLoads};
  }
  function setIsDumpingLoad(isDumpingLoad) {
    return {type: SET_IS_DUMPING_LOAD, isDumpingLoad};
  }

  function setPrioritizedLoadIds(prioritizedLoadIds) {
    return {type: SET_PRIORITIZED_LOAD_IDS, prioritizedLoadIds};
  }

  function setReloadPrioritizedLoadCancelTimeoutId(reloadPrioritizedLoadCancelTimeoutId) {
    return {type: SET_RELOAD_PRIORITIZED_LOAD_CANCEL_TIMEOUT_ID, reloadPrioritizedLoadCancelTimeoutId};
  }

  function prioritizeLoads(prioritizedLoadIds) {
    return (dispatch, getState) => {
      dispatch(setPrioritizedLoadIds(prioritizedLoadIds));
      const {highPriority, lowPriority} = prioritizedLoadIds;

      dispatch(setReloadPrioritizedLoadCancelTimeoutId( 0)); // disable reloads
      return dispatch(commonActions.reloadData('prioritize-loads', () => {
        return apiClient.prioritizeLoads('detailed-loads-list', highPriority, lowPriority)
      })).then(resp => {
        const reloadTimeoutId = getState().wetside.reloadPrioritizedLoadCancelTimeoutId;
        if (reloadTimeoutId) {
          clearTimeout(reloadTimeoutId);
        }
        const timeoutId = setTimeout(() => {
          dispatch(setReloadPrioritizedLoadCancelTimeoutId(null));
        }, 7000);
        dispatch(setReloadPrioritizedLoadCancelTimeoutId(timeoutId));
      }, () => {
        dispatch(setReloadPrioritizedLoadCancelTimeoutId( null)); // enable reloads
      });
    }
  }

  function loadPrioritizedLoads() {
    return (dispatch, getState) => {
      return dispatch(commonActions.reloadData('prioritized-loads', () => apiClient.getPrioritizedLoads('detailed-loads-list')))
        .then(({highPriorityLoadIds: highPriority, lowPriorityLoadIds: lowPriority}) => {
          dispatch(setPrioritizedLoadIds({highPriority, lowPriority}));
        });
    }
  }

  function reloadPrioritizedLoads() {
    return (dispatch, getState) => {
      if (!canReloadPrioritizedLoadIds(getState())) {
        return;
      }

      return dispatch(loadPrioritizedLoads());
    }
  }

  function loadBanks() {
    return (dispatch, getState) => {
      return apiClient.getBanks().then(resp => dispatch(setBanks(resp)));
    }
  }
  function loadBins() {
    return (dispatch, getState) => {
      return dispatch(commonActions.reloadData("bins", () => apiClient.getBins())).then(resp => {
        resp && dispatch(setBins(resp));
      });
    }
  }
  function loadBinMoistures() {
    return (dispatch, getState) => {
      return apiClient.getCurrentBinMoistures().then(resp => dispatch(setBinMoistures(resp)), () => {});
    }
  }
  function loadAirDoorEvents() {
    return (dispatch, getState) => {
      return apiClient.getCurrentAirDoorEvents().then(resp => dispatch(setAirDoorEvents(resp)));
    }
  }

  function loadDumpedLoads() {
    return (dispatch, getState) => {
      return dispatch(commonActions.reloadData('incoming-load-dumps', () => {
        return apiClient.getIncomingLoadDumps(null, {
          pagination: true,
          page: 1,
          perPage: 10,
          'order[createdAt]': 'DESC',
          season: getCurrentSeason(getState()),
        });
      })).then((resp) => {
        resp && dispatch(setDumpedLoads(resp));
      });
    }
  }

  function loadWetLoads() {
    return (dispatch, getState) => {
      const params = {state: "created", season: getCurrentSeason(getState())};
      return dispatch(commonActions.reloadData("incoming-loads", () => apiClient.getIncomingLoads(params))).then(resp => {
        resp && dispatch(setWetLoads(resp));
      });
    }
  }

  function fetchAndUpdateLoadsAndDumps(dispatch) {
    return Promise.all([dispatch(loadWetLoads()), dispatch(loadDumpedLoads())]);
  }

  function dumpIncomingLoad(incomingLoadId, pitId) {
    return (dispatch, getState) => {
      dispatch(setIsDumpingLoad(true));
      return apiClient.dumpIncomingLoad(incomingLoadId, pitId)
        .then(() => fetchAndUpdateLoadsAndDumps(dispatch))
        .then(null, alert) // alert any error message
        .then(() => dispatch(setIsDumpingLoad(false)));
    }
  }

  function removeDumpedLoad(incomingLoadDumpId) {
    return (dispatch, getState) => {
      dispatch(setIsDumpingLoad(true));
      return apiClient.removeDumpedLoad(incomingLoadDumpId)
        .then(() => fetchAndUpdateLoadsAndDumps(dispatch))
        .then(null, alert)
        .then(() => dispatch(setIsDumpingLoad(false)));
    }
  }

  function openDetailedLoadsModal() {
    return {type: OPEN_DETAILED_LOADS_MODAL};
  }

  function closeDetailedLoadsModal() {
    return {type: CLOSE_DETAILED_LOADS_MODAL};
  }

  function reloadBins() {
    return (dispatch, getState) => {
      return Promise.all([
        dispatch(loadBins()),
        dispatch(wetsideCommonActions.loadBinLots()),
      ]);
    }
  }

  function reloadIncomingLoads() {
    return (dispatch, getState) => {
      return Promise.all([
        dispatch(loadWetLoads()),
        dispatch(loadDumpedLoads()),
      ]);
    }
  }

  function reloadBinMoistures() {
    return (dispatch, getState) => {
      return Promise.all([
        dispatch(loadBinMoistures()),
        dispatch(loadAirDoorEvents()),
      ]);
    };
  }

  function openAirDoor(binId) {
    return (dispatch, getState) => {
      dispatch(commonActions.deferReload("bins", () => {
        dispatch({type: OPEN_AIR_DOOR, binId});
        return apiClient.openAirDoor(binId);
      }));
    };
  }

  function closeAirDoor(binId) {
    return (dispatch, getState) => {
      dispatch(commonActions.deferReload("bins", () => {
        dispatch({type: CLOSE_AIR_DOOR, binId});
        return apiClient.closeAirDoor(binId);
      }));
    };
  }

  function lockBin(binId, isMobile) {
    return (dispatch, getState) => {
      if(window.location.href.includes("wetside")){
        dispatch({type: LOCK_BIN, binId});
      }
      if (!isMobile) {
        return;
      }

      if (getState().wetside.lockedBinId) {
        dispatch(dryerBinModalActions.openModal(binId));
      } else {
        dispatch(dryerBinModalActions.closeModal(binId));
      }
    }
  }

  function selectBin(binId) {
    return {type: SELECT_BIN, binId};
  }
  function removeSelectedBin() {
    return {type: REMOVE_SELECTED_BIN};
  }

  function setCurrentWetLoadNote(wetLoadId) {
    return {type: CURRENT_WETLOAD_NOTE, wetLoadId}
  }

  function createWetLoadNote(wetLoadId, text){
    return dispatch => {
      return apiClient.createIncomingLoadNote(wetLoadId, text)
        .then(() => dispatch(loadWetLoads()))
    }
  }

  function removeWetLoadNote(wetLoadNoteId) {
    return dispatch => {
      return apiClient.removeIncomingLoadNote(wetLoadNoteId)
        .then(() => dispatch(loadWetLoads()))
    }
  }

  function editWetLoadNote(wetLoadNoteId, editText){
    return dispatch => {
      return apiClient.updateIncomingLoadNote(wetLoadNoteId, editText)
        .then(() => dispatch(loadWetLoads()))
    }
  }

  return {
    loadBanks,
    loadBins,
    loadBinLots: wetsideCommonActions.loadBinLots,
    loadBinMoistures,
    loadAirDoorEvents,
    loadDumpedLoads,
    loadWetLoads,
    reloadBins,
    reloadBinMoistures,
    reloadIncomingLoads,
    openDetailedLoadsModal,
    closeDetailedLoadsModal,
    openAirDoor,
    closeAirDoor,
    lockBin,
    selectBin,
    removeSelectedBin,
    dumpIncomingLoad,
    removeDumpedLoad,
    createWetLoadNote,
    setCurrentWetLoadNote,
    removeWetLoadNote,
    editWetLoadNote,
    setBins,
    setBanks,
    setWetLoads,
    setDumpedLoads,
    setPrioritizedLoadIds,
    prioritizeLoads,
    loadPrioritizedLoads,
    reloadPrioritizedLoads,
    setBinLots: wetsideCommonActions.setBinLots,
  };
}

export function wetsideSubscribers(container, emitter) {
  listenOnceLoggedIn(container, emitter, (dispatch) => {
    const reloadIncomingLoads = () => dispatch(container.wetsideActions.reloadIncomingLoads());
    const reloadBins = () => {
      dispatch(container.wetsideActions.reloadBins());
      dispatch(container.wetsideActions.reloadBinMoistures());
    };
    const reloadPrioritizedLoads = () => dispatch(container.wetsideActions.reloadPrioritizedLoads());
    const reloadBanks = () => dispatch(container.wetsideActions.loadBanks());

    reloadIncomingLoads();
    reloadBins();
    reloadPrioritizedLoads();
    reloadBanks();

    const reloadChannel = container.pusher.subscribe('reload');

    reloadChannel.bind('incomingLoads', reloadIncomingLoads);
    reloadChannel.bind('bins', reloadBins);
    reloadChannel.bind('fillOrder', reloadBins);
    reloadChannel.bind('banks', reloadBanks);
    reloadChannel.bind('prioritizedLoads', reloadPrioritizedLoads);
  });
  return emitter;
}

const initialState = {
  bins: null,
  binLots: null,
  binMoistures: null,
  airDoorEvents: null,
  banks: null,
  wetLoads: null,
  dumpedLoads: null,
  showDetailedLoads: false,
  lockedBinId: null,
  selectedBinId: null,
  isDumpingLoad: false,
  currentNote: null,
  prioritizedLoadIds: {
    highPriority: [],
    lowPriority: [],
  },
  reloadPrioritizedLoadCancelTimeoutId: null,
};

export default function wetsideReducer(state = initialState, action) {
  return produce(state, draft => {
    switch(action.type) {
      case SET_BANKS:
        draft.banks = action.banks;
        break;
      case SET_BINS:
        draft.bins = action.bins;
        break;
      case SET_BIN_LOTS:
        draft.binLots = action.binLots;
        break;
      case SET_AIR_DOOR_EVENTS:
        draft.airDoorEvents = action.airDoorEvents;
        break;
      case SET_BIN_MOISTURES:
        draft.binMoistures = action.binMoistures;
        break;
      case OPEN_DETAILED_LOADS_MODAL:
        draft.showDetailedLoads = true;
        break;
      case CLOSE_DETAILED_LOADS_MODAL:
        draft.showDetailedLoads = false;
        break;
      case OPEN_AIR_DOOR:
        draft.bins.map(b => {
          if(b.id == action.binId){
            b.airDoorClosed = false;
            b.airDoorOpen = true;
            b.airDoorState = 'open';
          } else {
            return b;
          }
        });
        break;
      case CLOSE_AIR_DOOR:
        draft.bins.map(b => {
          if(b.id == action.binId){
            b.airDoorClosed = true;
            b.airDoorOpen = false;
            b.airDoorState = 'closed';
          } else {
            return b;
          }
        });
        break;
      case LOCK_BIN:
        draft.lockedBinId === action.binId ? draft.lockedBinId = null : draft.lockedBinId = action.binId;
        break;
      case SELECT_BIN:
        draft.selectedBinId = action.binId;
        break;
      case REMOVE_SELECTED_BIN:
        draft.selectedBinId = null;
        break;
      case SET_DUMPED_LOADS:
        draft.dumpedLoads = action.dumpedLoads;
        break;
      case SET_WET_LOADS:
        draft.wetLoads = action.wetLoads;
        break;
      case CURRENT_WETLOAD_NOTE:
        draft.currentNote = action.wetLoadId;
        break;
      case SET_IS_DUMPING_LOAD:
        draft.isDumpingLoad = action.isDumpingLoad;
        break;
      case SET_PRIORITIZED_LOAD_IDS:
        draft.prioritizedLoadIds = action.prioritizedLoadIds;
        break;
      case SET_RELOAD_PRIORITIZED_LOAD_CANCEL_TIMEOUT_ID:
        draft.reloadPrioritizedLoadCancelTimeoutId = action.reloadPrioritizedLoadCancelTimeoutId;
        break;
    }
  });
}
