import GrowerColorColumn from "../../common/component/GrowerColorColumn";
import React from "react";
import Weight from "../../common/component/Weight";
import GrowerColumnWrapper from "../../common/component/GrowerColumnWrapper";

function formatTotalLoads(groupedLoad) {
  const totalLoads = groupedLoad.stats.totalLoads;
  return totalLoads === 1 ? "1 load" : `${totalLoads} loads`;
}

export function GroupedLoadWideBody({groupedLoad}) {
  return <div className={"d-flex justify-content-between"}>
    <span className="col">{formatTotalLoads(groupedLoad)}</span>
    <p>{groupedLoad.stats.totalLbs} lbs</p>
  </div>
}

export function GroupedLoadThinBody({groupedLoad}) {
  return <div>
    <span className="col">{formatTotalLoads(groupedLoad)}</span>
    <p>{groupedLoad.stats.totalLbs} lbs</p>
  </div>
}

export default function GroupedLoad({groupedLoad, Body = GroupedLoadWideBody}) {
  return <GrowerColumnWrapper color={groupedLoad.grower.color} grow >
    <b>{groupedLoad.grower.name}</b><br/>
    <Body groupedLoad={groupedLoad}/>
  </GrowerColumnWrapper>
}
